@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component-slider {
    .images-ctn {
        position: relative;
        display: flex;
        gap: 10px;
        left: calc(var(--gutter-width) * -2);
        padding-left: calc(var(--gutter-width) * 2);
        padding-right: calc(var(--gutter-width) * 2);
        padding-bottom: 10px;
        width: 100%;
        max-width: calc(100vw - (var(--gutter-width) * 4));
        aspect-ratio: 1054/744;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;

        &.single {
            img {
                min-width: 100%;
            }
        }

        img {
            object-fit: cover;
            min-width: calc(100vw - (var(--gutter-width) * 4) - 20px);
            height: 100%;
            scroll-snap-align: center;

            &:first-child {
                border-radius: 0 0 0 50px;
            }
        }
    }
}
