@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.promotions-scrolling-list {
  margin: 150px 0;
  overflow: hidden;

  &.homepage {
    margin-top: 0;
  }

  &.menu-list {
    margin-block: 80px;

    .wrapper {
      .scrolling-list {
        .list {
          .tile {
            border: none;
          }
        }

        .controls {
          margin-top: 40px;
        }
      }
    }
    .title-separator {
      margin-bottom: 60px;
      .title {
        font-size: rem(50);
        line-height: rem(54);
        @include shentox-bold();
        + .lines {
          margin-top: 0px;
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      .wrapper {
        .scrolling-list {
          .list {
            .tile {
              width: var(--tileSizeMenu);
            }
          }
        }
      }
    }

    @media only screen and (max-width: $xs-max) {
      .wrapper {
        .scrolling-list {
          .list {
            gap: 10px;
            .tile {
              &.list-type {
                width: 100%;
                min-width: min(
                  335px,
                  calc(100vw - var(--gutter-width) * 2 - 35px)
                );
                max-width: min(
                  335px,
                  calc(100vw - var(--gutter-width) * 2 - 35px)
                );
              }
            }
          }
        }
      }
    }
  }

  .wrapper {
    @include col(14);
    @include col-start(2);

    .scrolling-list {
      margin-top: 40px;
      position: relative;

      .list {
        width: max-content;
        display: flex;
        align-items: stretch;
        gap: 45px;

        .tile {
          width: var(--tileSize);
          min-width: 300px;
          max-width: 500px;
          float: left;
          border: 1px solid map-get($colors-scrolling-container, border);

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              0deg,
              rgb(0 0 0 / 50%) 0%,
              rgba(0, 0, 0, 0) 50%
            );

            @media only screen and (max-width: $xs-max) {
              background: linear-gradient(
                0deg,
                rgb(0 0 0 / 50%) 0%,
                rgba(0, 0, 0, 0) 100%
              );
            }
          }

          @media only screen and (min-width: $sm-min) {
            &::before {
              padding-bottom: 102%; /* Minimum aspect ratio */
              content: "";
              float: left;
            }
            &::after {
              display: table;
              clear: both;
            }
          }
        }
      }

      .controls {
        margin-top: 75px;
        width: 100%;
        display: inline-flex;
        gap: 10px;
        justify-content: flex-end;
      }
    }
  }

  @media only screen and (max-width: $sm-max) {
    margin: 100px 0;

    &.menu-list,
    &.homepage {
      .title-separator,
      .like-h3 {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 8px * 4);

        .title {
          font-size: rem(34);
          line-height: rem(37.4);
        }
      }
      .centered-content {
        width: 100%;
      }
    }

    .wrapper {
      @include col(16);
      @include col-start(1);

      .scrolling-list {
        margin-top: 30px;
        padding-bottom: 8px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        .list {
          gap: 10px;
          padding-left: 16px;
          padding-right: 16px;
          touch-action: auto !important;
          .tile {
            scroll-snap-align: center;
          }
        }

        .controls {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: $xs-max) {
    &.menu-list,
    &.homepage {
      margin-block: 60px;

      .title-separator {
        margin-bottom: 30px;
      }
    }
  }
}
