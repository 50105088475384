//
// BREAKPOINTS
// For extra breakpoints, use xxs (< xs), xl (> lg) and xxl (> xl)
// -----------------------------------------------------------------------------

$lg-max: 1800px;
$lg-min: 1320px;
$md-max: $lg-min - 1;
$md-min: 1024px;
$sm-max: $md-min - 1;
$sm-min: 768px;
$xs-max: $sm-min - 1;

$gutter-width-lg: 40px;
$gutter-width-md: 20px;
$gutter-width-sm: 8px;
$gutter-width-xs: 8px;

//
// CONTAINER SIZES
// -----------------------------------------------------------------------------

$container-lg: calc(100% - #{$gutter-width-lg} *2);
$container-md: calc(100% - #{$gutter-width-md} *4);
$container-sm: calc(100% - #{$gutter-width-sm} *4);
$container-xs: calc(100% - #{$gutter-width-xs} *4);
