@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.giftcard-confirmation{
    padding: 96px 0 150px;

    .go-back{
        top: 96px;
    }

    .wrapper{
        @include grid(16, var(--gutter-width));
        padding: 80px 0 0;

        .heading{
            @include col-start(2);
            @include col(5);
        }

        .content{
            @include col-start(8);
            @include col(8);

            @media only screen and (max-width: $xs-max) {
                @include col-start(1);
                @include col(16);
            }

            @include text-m-menu();

            p{
                margin: 0 0 10px;
            }

            .name{
                @include text-l-semibold();
                margin:0 0 5px;
            }

            .actions{
                display:flex;
                align-items: center;
                gap: 20px;
                margin-top: 30px;

                @media only screen and (max-width: 1200px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding: 125px 0 50px;

        .go-back{
            top: 125px;
        }

        .wrapper {
            padding: 70px 0;

            .heading {
                @include col-start(1);
                @include col(16);
                max-width: 450px;
            }

            .content {
                @include col-start(1);
                @include col(16);
            }
        }
    }
}
