@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-banner-image {
        --column-list: var(--column-amount);
        overflow: hidden;

        .centered-content{
            margin-bottom: -110px;
        }

        .title-separator {
            margin-bottom: 70px;
        }

        .text-wrapper {
            position: relative;
            top: -90px;
            @include col-start(2);
            @include col(15);
            @include grid(15, var(--gutter-width));

            .text-content {
                min-height: 440px;
                position: relative;
                @include col-start(1);
                width: calc(100% + var(--gutter-width));
                box-sizing: border-box;
                padding: 60px;
                background: map-get($color-banner-image, background);
                @include col(15);
                border-radius: 0 0 0 60px;

                h3{
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    max-width: 60%;
                }

                h2{
                    max-width: 60%;
                }

                p {
                    max-width: 50%;
                }

                span:has(.tertiary-cta){
                    margin-top: 80px;
                }

                .icon-field-lines{
                    position: absolute;
                    top:0;
                    right:0;
                }
            }
        }

        .image-content {
            position: relative;
            left: calc(-1 * var(--gutter-width));
            display: flex;
            align-items: center;
            z-index: -1;
            max-height: 820px;
            overflow: hidden;
            width: calc(100% + 2 * var(--gutter-width));

            img {
                width: 100%;
                aspect-ratio: 1440/820;
                object-fit: cover;
            }
        }

        @media only screen and (max-width: $md-max) {
            .centered-content {
                .text-wrapper {
                    .text-content {
                        min-height: 400px;
                        width: calc(100% + var(--gutter-width) * 2);

                        .icon-field-lines{
                           width: 540px;
                        }
                    }
                }

                .image-content {
                    left: calc(-2 * var(--gutter-width));
                    width: calc(100% + 4 * var(--gutter-width));
                }
            }
        }

        @media only screen and (max-width: $sm-max) {
            .centered-content {
                .text-wrapper {
                    top: -70px;
                    @include col-start(2);
                    @include col(15);
                    @include grid(15, var(--gutter-width));

                    .text-content {
                        width: calc(100% + var(--gutter-width) * 2);
                        padding: 40px;
                        border-radius: 0 0 0 40px;

                        *{
                            position: relative;
                            z-index:1;
                        }

                        h3{
                            max-width: 80%;
                        }

                        h2{
                            max-width: 80%;
                        }

                        p {
                            max-width: 80%;
                        }

                        .icon-field-lines{
                            position: absolute;
                            z-index:0;
                            opacity: 0.5;
                        }
                    }
                }

                .image-content {
                    left: calc(-2 * var(--gutter-width));
                    width: calc(100% + 4 * var(--gutter-width));
                }
            }
        }

        @media only screen and (max-width: $xs-max) {
            .centered-content {
                margin-bottom: -70px;

                .text-wrapper {
                    @include col(16);
                    @include col-start(1);

                    .text-content {
                        min-height: 0;
                        @include col(16);
                        @include col-start(1);
                        padding: 30px 30px 35px;

                        h3{
                            max-width: 100%;
                        }

                        h2{
                            max-width: 100%;
                        }

                        p {
                            max-width: 100%;
                        }

                        a{
                            margin-top: 2px;
                        }

                        .icon-field-lines{
                            display:none;
                        }
                    }
                }

                .image-content {
                    left: calc(-2 * var(--gutter-width));
                    width: calc(100% + 4 * var(--gutter-width));
                }
            }
        }
    }
}
