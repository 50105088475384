@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

header.ecommerce {
    padding:0;
    height: 96px;
    display:flex;
    align-items: center;
    background-color: $white;

    &:after{
        content: '';
        position: absolute;
        bottom:0;
        right: 0;
        width: calc(100% + var(--gutter-width) * 3);
        background: map-get($colors-restaurant-menu, border-light);
        height: 1px;
        left: calc(-2 * var(--gutter-width));

        @media only screen and (max-width: $sm-max){
            width: calc(100% + var(--gutter-width) * 2);
        }
    }

    .centered-content{
        .secondary-nav{
            display:flex;

            li:not(:first-child){
                opacity:1;
                pointer-events: auto;
            }
        }
    }

    .logo-wrapper{
        display:flex;
        flex-wrap: nowrap;
        gap: 18px;
        align-items: center;

        .like-h4{
            margin:0;
        }

        .logo{
            margin:0;
        }
    }

    .secondary-cta{
        position: relative;

        &.with-item{
            padding-right: 48px;
        }

        span{
            position: absolute;
            top:5px;
            right:6px;
            line-height: 32px;

            background: map-get($colors-ui-buttons, primary-bg);
            color: map-get($colors-ui-buttons, primary-color);

            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    @media only screen and (max-width: $xs-max) {
        height: 125px;
        .centered-content{
            >div{
                align-items: flex-start;
                position: relative;
            }
        }

        .secondary-nav{
            position: absolute;
            right:0;
            top:0;
        }

        .logo-wrapper{
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media only screen and (max-width: 400px) {
        height: 145px;
    }

}