@import "../../../../config";
@import "../../../../mixins";
@import "../../../../functions";

.hero.standard{
    position: relative;
    color: map-get($colors-base, light-text);

    .main-image {
        position: absolute;
        top:0;
        height: 100vh;
        width: 0vw;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.4) 100%),
                linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
            z-index: 1;
        }

        img {
            aspect-ratio: 1440/720;
            min-height: 100%;
            min-width: 100vw;
            object-fit:cover;

            &.placeholder{
                object-position: bottom;
            }
        }
    }

    &.blog-list-hero{
        .centered-content{
            display: grid;
            justify-content: unset;
            flex-direction: unset;
            .titles{
                margin: 0;
                @include col-start(2);
                @include col(14);
                align-self: end;
            }
        }

        .main-image{
            &:before{
                background: rgba(0,0,0,0.4);
            }
        }
        @media only screen and (max-width: $sm-max) {
            .centered-content {
                padding-bottom: 25px;
                .titles{
                    @include col-start(1);
                    @include col(var(--column-amount));
                }
            }
        }
    }

    .centered-content{
        height: 100vh;
        display:flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 100px;
        box-sizing: border-box;
        z-index: 2;

        .titles{
            display: flex;
            flex-direction: column;
            margin: 0 var(--gutter-width);
        }

        h1 {
            max-width: 1150px;
            opacity:0;

            div {
                top: 50px;
                clip-path: inset(0px 0px 100% 0px);
            }
        }

        h5{
            position: relative;
            opacity:0;
            clip-path: inset(0px 0px 100% 0px);
            top: 40px;
            margin-left: 6px;
        }

        .image{
            position: absolute;
            top: 100vh;
            right: 40px;
            z-index: 1;

            img{
                position: relative;
                width: calc(100vw / (1440/500) );
                aspect-ratio: 500/600;
                object-fit: cover;
            }
        }

        .buttons-wrapper{
            display: flex;
            margin: 0 var(--gutter-width);

            .buttons{
                a, button{
                    margin-right: 10px;
                }
            }
        }

    }

    .content-after{
        position: relative;
        width: $container-lg;
        margin: 0 auto;

        @include grid(var(--column-amount), var(--gutter-width));

        padding-top: 100px;

        div{
            @include col(7);
            @include col-start(2);

            p:first-child{
                margin-top:0;
            }
        }
    }


    .section.no-bg{
        position: absolute;
        top: 0%;
        height: 45%;
        width: 100%;
        opacity:0;
        pointer-events: none;
    }

    .section.dark, .section.light{
        position: absolute;
        top: 60%;
        height: 40%;
        width: 100%;
        opacity:0;
        pointer-events: none;
    }

    @media only screen and (max-width: $sm-max) {
        .content-after{
            width: $container-sm;
        }
    }

    @media only screen and (max-width: $xs-max) {
        &.no-transition-image{
            .content-after {
                padding-top: 70px;
            }
        }

        .main-image {
            height: 450px;
        }
        .centered-content{
            height: 450px;
            padding-bottom: 52px;

            .image{
                position: absolute;
                top: 420px;
                right: 16px;
                left: 16px;

                img{
                    width: calc(100vw - 32px);
                    max-height: 365px;
                }
            }

            h1{
                @include col-start(1);
            }

            h5{
                margin-bottom: 10px;
                margin-left: 2px;
                @include col-start(1);
            }

            .buttons{
                display:none;
            }
        }

        .content-after{
            width: $container-xs;

            &.with-image{
                padding-top: 350px;
            }

            div{
                @include col(16);
                @include col-start(1);
            }
        }
    }
}
