@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.section.pagebuilder-component-wysiwyg{
  margin-block: 100px 50px;

  .centered-content{
    .sub-grid{
      @include grid(var(--column-amount), var(--gutter-width));
      .share-content{
        @include col-start(2);
        @include col(4);

        position: sticky;
        top: 30vh;

        @media only screen and (max-width: $sm-max){
          position: unset;
          top: unset;
          @include col-start(1);
          @include col(var(--column-amount));
        }
      }

      .wysiwyg-container {
        @include col-start(6);
        @include col(10);

        @media only screen and (max-width: $sm-max){
          @include col-start(1);
          @include col(var(--column-amount));
        }
        h4 {
          margin-bottom: 20px;
        }

        .text-content {

          > *:first-child {
            margin-top: 0;
          }

          > *:last-child {
            margin-bottom: 0;
          }

          figure.table {
            overflow-x: auto;
          }

          table {
            width: 100%;
          }

          table, th, td {
            border: 1px solid map-get($color-tables, border);
            border-collapse: collapse;
          }

          //if we need to reproduce the design presented
          //th{
          //    display:none;
          //
          //    &:first-child{
          //        display:block;
          //    }
          //}
          //
          //tr {
          //    display: flex;
          //    flex-direction: column;
          //}

          th {
            vertical-align: top;
            padding: 20px;
            background: map-get($color-tables, heading);
            color: map-get($color-tables, heading-text);
            @include text-m-semibold();
          }

          td {
            vertical-align: top;
            padding: 20px;
            @include text-m();

            strong {
              @include text-m-semibold();
            }

            &:first-child {
              min-width: 160px;
            }
          }

          h2, h3, h4, h5, h6 {
            margin-top: 60px;
          }

          ul, ol {
            list-style: none; /* Remove default bullets */
            margin-left: 20px;

            li::before {
              content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
              color: map-get($colors-legal, links);
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }

          figure {
            margin: 0 0 60px;
          }

          a {
            color: map-get($colors-legal, links);
            text-decoration: underline;
            @include text-m-semibold();

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      @media only screen and (max-width: $sm-max) {
        .wysiwyg-container {


          .text-content {


            ul, ol {
              margin-left: 15px;
            }

            td:first-child {
              min-width: auto;
            }
          }
        }
      }
    }
  }

}

