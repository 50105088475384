@import "../../config";
@import "../../mixins";
@import "../../functions";

.tile {
  &.sports-schedule-tile {
    width: 100%;
    height: 400px;

    @media only screen and (max-width: $sm-max) {
      height: 260px;
    }

    &.bg {
      &-red {
        background-color: map-get($colors-tiles, red-bg);

        .tile-inner {
          div.sport-logo {
            .svg {
              background-color: map-get($colors-ui-buttons, tertiary-color-alt);
            }
          }
        }
      }

      &-gray {
        background-color: map-get($colors-tiles, gray-bg);

        .tile-inner {
          div.sport-logo {
            .svg {
              background-color: map-get($colors-tiles, gray-icon-hover);
            }
          }
        }
      }

      &-blue {
        background-color: map-get($colors-tiles, blue-bg);

        .tile-inner {
          div.sport-logo {
            .svg {
              background-color: map-get($colors-tiles, blue-icon-hover);
            }
          }
        }
      }
    }

    &.col- {
      &1,
      &2,
      &3,
      &4 {
        .tile-inner {
          .texts {
            .other-details {
              .tertiary-cta {
                margin-left: auto;
              }
            }
          }
        }
      }

      &1 {
        .tile-inner {
          .title {
            font-size: rem(70);
            line-height: rem(70);
            @media only screen and (max-width: $sm-max) {
              font-size: rem(28);
              line-height: rem(28);
            }
          }
        }
      }

      &2 {
        .tile-inner {
          .title {
            font-size: rem(50);
            line-height: rem(54);
            @media only screen and (max-width: $sm-max) {
              font-size: rem(28);
              line-height: rem(28);
            }
          }
        }
      }

      &3 {
        .tile-inner {
          padding: 30px;
          @media only screen and (max-width: $sm-max) {
            padding: 23px;
          }

          .title-wrapper {
            margin-top: -50px;
            @media only screen and (max-width: $sm-max) {
              margin-top: 0;
            }
          }

          .title {
            font-size: rem(28);
            line-height: rem(28);
            @media only screen and (max-width: $sm-max) {
              font-size: rem(28);
              line-height: rem(28);
            }
          }

          .texts {
            .other-details {
              .limitations {
                max-width: 60%;
              }
            }
          }
        }
      }

      &4 {
        .tile-inner {
          padding: 25px 20px;

          @media only screen and (max-width: $sm-max) {
            padding: 23px;
          }

          .title-wrapper {
            margin-top: -50px;
            @media only screen and (max-width: $sm-max) {
              margin-top: 0;
            }
          }

          .title {
            font-size: rem(28);
            line-height: rem(28);
            @media only screen and (max-width: $sm-max) {
              font-size: rem(28);
              line-height: rem(28);
            }
          }

          .date-wrapper {
            .date {
              font-size: rem(18);
            }
          }

          .texts {
            .other-details {
              align-items: center;

              .limitations {
                max-width: 60%;
                font-size: rem(10);
              }
            }
          }
        }
      }
    }

    .tile-inner {
      padding: 40px;

      @media only screen and (max-width: $sm-max) {
        padding: 23px;
      }

      &.with-border {
        border: 1px solid map-get($colors-ui, border-dark);

        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          inset: 0;
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 77.8%
          );
        }
      }

      .title-wrapper {
        .title {
          @include shentox-bold();
          text-transform: uppercase;
          margin: 0 0 2px;
          text-wrap: balance;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          overflow: hidden;
        }
      }

      .date-wrapper {
        .date {
          @include text-l-semibold();
          line-height: 1;
        }

        .datetime {
          @include text-m();
          line-height: 1.7;
        }
      }

      .texts {
        display: grid;
        height: 100%;
        z-index: 1;
        row-gap: 20px;

        .other-details {
          display: flex;
          justify-content: space-between;
          align-self: flex-end;

          .limitations {
            margin: 0;
            line-height: 1;
            @include text-xs();
          }
        }
      }

      svg {
        top: 30px;
        right: 30px;

        @media only screen and (max-width: $sm-max) {
          top: 23px;
          right: 23px;
          width: auto;
          height: 26px;
          transform: none;
        }
      }

      img {
        &.image-photo,
        &.image-hover-desktop {
          object-position: center;
        }
      }

      img,
      div {
        background-color: unset;
        z-index: -1;

        &.sport-logo {
          position: absolute;
          width: unset;
          right: 4%;
          margin-left: auto;
          //left: 35%;
          //top: 15%;
          top: calc(25% + 25px);
          object-position: top left;
          opacity: 0;
          //filter: brightness(0) invert(1) opacity(0.2);
          transition: all 0.2s ease-in-out;
          transform: scale(1.4) translate(-50px, 50px);
          @media only screen and (max-width: $sm-max) {
            top: calc(20% - 15px);
            transform: scale(1.2) translate(-50px, 50px);
            right: -3%;
          }

          @media only screen and (max-width: $xs-max) {
            top: calc(20% - 35px);
            transform: scale(0.8) translate(-50px, 50px);
            right: -2%;
          }
        }
      }
    }

    &:hover {
      span.tertiary-cta {
        &:before {
          background-color: map-get($colors-ui-buttons, tertiary-hover-color);
          animation: grow-line 0.3s 0.26s ease-out forwards;
        }

        &:after {
          background-color: map-get($colors-ui-buttons, tertiary-hover-color);
          animation: shrink-line 0.3s 0s ease-in forwards;
        }
      }

      .tile-inner {
        img,
        div {
          &.sport-logo {
            opacity: 1;
            transform: scale(1.4) translate(0, 0);
            transition: all 0.2s ease-in-out;

            @media only screen and (max-width: $sm-max) {
              transform: scale(1.2) translate(0, 0);
            }

            @media only screen and (max-width: $xs-max) {
              transform: scale(0.8) translate(0, 0);
            }
          }
        }
      }
    }
  }
}
