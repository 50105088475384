@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.giftcard-promotion {
    margin: 0 0 80px;

    @media only screen and (max-width: $xs-max) {
        margin-top: -20px;
    }

    .wrapper{
        @include grid(16, var(--gutter-width));

        .promotion{
            @include col(14);
            @include col-start(2);
            @include grid(14, var(--gutter-width));

            position: relative;

            background: map-get($colors-giftcard-promotion, background);
            padding: 50px;
            border-bottom-right-radius: 60px;

            .content{
                @include col(8);
                @include col-start(1);

                max-width: 690px;

                .suptitle{
                    margin-top:0;
                    @include text-l-semibold();
                    text-transform: uppercase;
                }

                p{
                    margin-bottom: 0;
                    @include text-m-menu();
                }
            }

            .actions{
                position: absolute;
                top: 50%;
                right: 50px;
                transform: translateY(-50%);
                padding-bottom: 35px;

                .primary-cta{
                    &:hover{
                        color: map-get($colors-ui-buttons, secondary-bg)!important;
                        background-color: map-get($colors-ui-buttons, secondary-hover-bg);
                    }
                }

                .tertiary-cta{
                    position: absolute;
                    left:50%;
                    bottom: 0px;
                    transform: translateX(-50%);
                    white-space: nowrap;
                }
            }

            @media only screen and (max-width: $sm-max) {
                padding: 50px 25px;
                
                .content{
                    @include col(16);
                    .suptitle{
                        margin-bottom: 10px;
                    }
                }

                .actions{
                    margin-top: 15px;
                    position: relative;
                    top: auto;
                    right: auto;
                    transform: none;
                    padding-bottom:0;
                    gap: 20px;
                    display:flex;
                    flex-wrap: wrap;

                    a{
                        align-self: center;
                    }

                    .tertiary-cta {
                        position: relative;
                        bottom: auto;
                        left: auto;
                        transform: none;
                    }
                }
            }
        }
    }
}