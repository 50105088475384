@import "../../config";
@import "../../mixins";
@import "../../functions";

.overlay.mobile-menu {
  opacity: 0;

  .wrapper {
    background: map-get($colors-mobile-menu, background);
    @include grid(var(--column-amount), var(--gutter-width));
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    //min-height: -webkit-fill-available;

    .logo {
      position: fixed;
      top: 8px;
      left: 16px;
      width: 50px;
      height: 50px;
      z-index: 2;

      svg {
        * {
          fill: $white;
        }

        path {
          display: none;

          &:nth-child(1),
          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

    .right-ctn {
      left: auto;
      top: 15px;
      right: 15px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      width: 100%;
      column-gap: 5px;
      z-index: 4;
      @media only screen and (max-width: $xs-max) {
        left: auto;
        right: 5px;
        top: 5px;
      }
      .close {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        padding: 15px 15px 15px 0;
      }
      a {
        @include shentox-bold();
        display: inline-block;
        font-size: rem(14);
        line-height: 1;
        margin: 0;
        color: $white;
        margin-top: -5px;
        padding: 10px;
      }
    }

    .content {
      padding: 100px 16px 16px;

      .links {
        @include col(12);
        li {
          .like-h2 {
            font-size: rem(34);
            line-height: rem(37.4);
          }

          a {
            display: inline-block;
            margin-bottom: 20px;
            color: $white;
          }

          .boutique {
            position: relative;
            span.icon-external {
              position: absolute;
              right: -40px;
              top: 1px;
              width: 30px;
            }
          }
        }
      }

      ul {
        list-style: none;
      }

      .secondary-links {
        margin-top: 30px;
        li {
          display: inline-block;

          a {
            display: inline-block;
            font-size: rem(14);
            margin: 0 15px 17px 0;
            color: $white;
          }
        }
      }

      .buttons {
        padding-bottom: 20px;
        border-bottom: 1px solid map-get($colors-mobile-menu, border);

        li {
          a {
            margin: 10px 0;
            display: block;
            text-align: center;
            // must add touch callout to avoid haptic touch transparency on iOS devices
            -webkit-touch-callout: none;
            &:not(&:hover) {
              color: map-get($colors-mobile-menu, light);
              border-color: map-get($colors-mobile-menu, light);
            }
          }
        }
      }

      .extra {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-link {
          font-size: rem(14);
          color: map-get($colors-mobile-menu, light);
          @include shentox-bold();
        }
      }

      .socials {
        margin-top: 10px;
        margin-left: -10px;

        a {
          padding: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: $sm-max) {
    transition: none;
    top: 0px;
    right: -70vw;

    .wrapper {
    }
  }

  @media only screen and (max-width: $xs-max) {
    transition: none;
    top: -70px;
    right: unset;

    .wrapper {
      .content {
      }
    }
  }
}
