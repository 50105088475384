@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.side-by-side-form.cart {
    .wrapper{

        form{

            .advice{
                p.label{
                    font-size: rem(18);
                    margin-bottom: 2px;
                }
                p{
                    font-size: rem(18);
                    margin-block: 0 25px;
                }
            }

            @media only screen and (max-width: $sm-max) {
                @include col-start(1);
                @include col(8);
            }

            @media only screen and (max-width: $xs-max) {
                order:2;
                @include col-start(1);
                @include col(16);

                h1{
                    opacity:0;
                    width:0;
                    height:0;
                }



                .flex-wrapper{
                    &:not(.mobile-flex-wrapper){
                        flex-wrap: wrap;
                        gap: unset;
                        > *{
                            width: 100%;
                        }
                    }


                }

                .advice{
                    p.label{
                        font-size: rem(16);
                    }
                    p{
                        margin-top: 0;
                        margin-bottom: 30px;
                    }
                }
            }

            @media only screen and (max-width: 420px){
                .flex-wrapper{
                    &.mobile-flex-wrapper{
                        flex-wrap: wrap;
                        gap: unset;
                        > *{
                            width: 100%;
                        }
                    }
                }
            }

            // GLOBAL PAYMENT FIELDS

            &#payment-form{

                .input-wrapper, .select-wrapper{
                    margin-bottom: 50px;
                    @media only screen and (max-width: $xs-max) {
                        margin-bottom: 50px;
                    }
                    &:has(#card-number, #card-holder-name, #card-cvv, #card-expiration){
                        margin-bottom: 15px;
                        @media only screen and (max-width: $xs-max) {
                            margin-bottom: -10px;
                        }
                    }

                    &:has(input[type=checkbox]){
                        margin-bottom: 30px;
                    }

                    input:not(.has-value) + label{
                        font-size: 17px !important;
                    }


                }

                &.light-form .select-wrapper [me\:skin\:type=select] [me\:skin\:selection]{
                    font-size: 17px !important;
                }

                #card-number{
                    .icon-wrapper{
                        position: absolute;
                        top: 20px;
                        right: 0;

                        span{
                            display: none;
                            svg{
                                width: 35px;
                                height: auto;
                            }
                        }
                    }

                    &.mastercard{
                        .icon-wrapper{
                            .mastercard-icon{
                                display: block;
                            }
                        }
                    }
                    &.visa{
                        .icon-wrapper{
                            .visa-icon{
                                display: block;
                            }
                        }
                    }
                }

                #submit-button{
                    &.disabled{
                        pointer-events: none;
                        opacity: 0.7;
                        filter: grayscale(1);
                        user-select: none;
                    }
                }
            }

        }

        aside{
            @include col(5);

            @media only screen and (max-width: $sm-max) {
                @include col-start(10);
                @include col(7);
            }

            @media only screen and (max-width: $xs-max) {
                order:1;
                @include col-start(1);
                @include col(16);

                .mobile-only{
                    display: block!important;
                }
            }

            .mobile-only{
                display: none;
            }

            .card-preview{
                margin-bottom: 20px;
                padding: 20px;
                background: map-get($colors-cart, card-bg);

                .infos-wrapper{
                    display:flex;
                    align-items: center;
                    gap: 16px;

                    .card-design{
                        border-radius: 4px;
                        overflow: hidden;
                        width: 112px;
                        height: 74px;
                        border: 1px solid map-get($colors-giftcard-creation, border);


                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .infos{
                        display:flex;
                        flex-direction: column;

                        >span{
                            @include text-s();
                            @include ciutadella-semibold();
                            display:flex;
                            align-items: center;
                            gap: 5px;
                            margin-bottom: 4px;

                            &.value{
                                span:first-child{
                                    margin-top: -4px;
                                }
                            }

                            span:first-child{
                                width: 20px;
                                margin-top: -2px;
                            }
                        }

                        .name{
                            @include text-m-semibold();
                        }

                    }
                }

                p{
                    @include text-s();
                    margin-bottom: 0px;
                }

                .author{
                    @include text-s();
                    @include ciutadella-regular();
                }

                .buttons{
                    display:flex;
                    justify-content: flex-end;
                    gap: 20px;
                    margin-top: 10px;
                }
            }

            .total-wrapper{
                display:flex;
                gap: 30px;
                flex-direction: column;

                a{
                    display: inline-block;
                    position: relative;
                    margin-left: 30px;
                    margin-right: auto;

                    .icon-plus{
                        position: absolute;
                        left: -30px;
                        top:calc(50% - 2px);
                        transform: translateY(-50%);
                        width: 15px;

                        svg{
                            *{
                                stroke: black;
                            }
                        }
                    }
                }

                .total{
                    margin-bottom: 10px;
                    @include text-xl-semibold();
                }

                .amount-line{
                    display:flex;
                    justify-content: space-between;

                    &.bonus{

                        color: map-get($colors-giftcard-payment, promo-text);

                        >span:first-child{
                            position: relative;
                            padding-right: 25px;

                            //icon
                            span{
                                position: absolute;
                                right:0;
                                top:4px;
                                width: 18px;
                                height: 19px;

                                svg{

                                    *{
                                        stroke: map-get($colors-giftcard-payment, promo-text);
                                    }
                                }
                            }
                        }


                    }
                }
            }
        }
    }
}