@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;


.light-form{
  .select-wrapper{
    [me\:skin\:type="select"] {
      height: 100%;

      [me\:skin=select] {

        &.has-value {
          & ~ [me\:skin\:theme="skinMe-select"] [me\:skin\:selection] {
            color: map_get($colors-light-form, label-active);
          }

          &.has-value ~ label {
            color: map_get($colors-light-form, label-active);
          }
        }
      }

      span.icon-arrow {
        *{
          stroke: map_get($colors-light-form, label-active);
        }
      }

      &:hover, &.is-open, &.is-opening {
        [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {

          &:after {
            background-color: map_get($colors-light-form, border-active);
          }

          span.icon-arrow {
            *{
              stroke: map_get($colors-light-form, label-active);
            }
          }
        }
      }

      &.is-open, &.is-opening, &.is-closing {
        [me\:skin=select] {
          &.error {
            & ~ [me\:skin\:theme="skinMe-select"]:after {
              background-color: map-get($colors-light-form, border-active);
            }
          }
        }

        [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
          [me\:skin\:selection] {
            color: map_get($colors-light-form, label-active);
          }
        }

        label {
          color: map_get($colors-light-form, label-active);
        }
      }

      &.is-open, &.is-opening, &.is-closing, &.is-closing-after-selected {
        [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
          [me\:skin\:choices] {
            border: 1px solid map-get($colors-light-form, border-active) !important;
            border-top: none !important;
          }
        }
      }

      [me\:skin\:selection] {
        z-index: 99;
        @include ciutadella-regular();
        font-size: rem(18);
        line-height: rem(30.6);
        color: map_get($colors-form, label);
        min-height: 30.6px;
        transition: all 200ms $ease-in-out;
        opacity: 0;
      }

      span.icon-arrow {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        width: 13px !important;
        transition: transform 200ms $ease-in-out-quad;
      }

      [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
        height: 100%;
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: map_get($colors-form, border);
          position: absolute;
          bottom: 0;
          z-index: 1;
          transition: all 200ms $ease-in-out;
          @media screen and (max-width: 450px){
            bottom: 2px;
          }
        }

        [me\:skin\:choices] {
          width: auto;
          right: 0;
          left: 0;
          transition: height 0.5s ease-in-out;
          max-height: 180px;
          background: map_get($colors-form, background);

          .choice {
            padding: 5px 16px 3px;
            min-height: 40px;
            background: map_get($colors-form, background);
            color: map-get($colors-form, label-active);
            transition: all 200ms ease-in-out;
            box-sizing: border-box;

            &:not(:last-child) {
              border-bottom: 1px solid map-get($colors-form, border-active);
            }

            &:hover, &:active, &:focus {
              background: map-get($colors-form, background-hover);
              color: map_get($colors-form, label-hover);
            }
          }
        }
      }
    }
  }
}
// Select base theme
.select-wrapper {
  label {
    cursor: pointer;
  }

  [me\:skin\:type="select"] {
    display: block;
    width: 100%;
    cursor: pointer;
    z-index: 2;

    [me\:skin=select] {
      background: none;
      border: none;

      &.error {
        & ~ [me\:skin\:theme="skinMe-select"]:after {
          background-color: map-get($colors-form, error);
        }

        & ~ .error-msg {
          display: block;
          color: map-get($colors-form, error);
        }
      }

      &.has-value {
        & ~ [me\:skin\:theme="skinMe-select"] [me\:skin\:selection] {
          opacity: 1;
          @include ciutadella-semibold();
          color: map_get($colors-form, label-active);
        }

        &.has-value ~ label {
          top: -10px;
          font-size: rem(14);
          line-height: rem(16.8);
          color: map_get($colors-form, label-active);
        }
      }
    }

    &:hover:not(.is-closing, .is-open, .is-opening) {
      [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
        [me\:skin\:choices] {
          //border: none;
        }
      }
    }

    &:hover, &.is-open, &.is-opening {
      [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {

        &:after {
          background-color: map_get($colors-form, border-active);
        }

        span.icon-arrow {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }

    &.is-open, &.is-opening, &.is-closing {
      [me\:skin=select] {
        &.error {
          & ~ [me\:skin\:theme="skinMe-select"]:after {
            background-color: map-get($colors-form, border-active);
          }
        }
      }

      [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
        [me\:skin\:selection] {
          @include ciutadella-semibold();
          color: map_get($colors-form, label-active);
        }
      }

      label {
        color: map_get($colors-form, label-active);
      }
    }

    &.is-open, &.is-opening, &.is-closing, &.is-closing-after-selected {
      [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
        [me\:skin\:choices] {
          border: 1px solid map-get($colors-form, border-active) !important;
          border-top: none !important;
        }
      }
    }

    [me\:skin\:selection] {
      z-index: 99;
      @include ciutadella-regular();
      font-size: rem(18);
      line-height: rem(30.6);
      color: map_get($colors-form, label);
      min-height: 30.6px;
      transition: all 200ms $ease-in-out;
      opacity: 0;
    }

    span.icon-arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: 13px !important;
      transition: transform 200ms $ease-in-out-quad;
    }

    [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: map_get($colors-form, border);
        position: absolute;
        bottom: 0;
        z-index: 1;
        transition: all 200ms $ease-in-out;
      }

      [me\:skin\:choices] {
        width: auto;
        right: 0;
        left: 0;
        transition: height 0.5s ease-in-out;
        max-height: 180px;
        background: map_get($colors-form, background);

        .choice {
          padding: 5px 16px 3px;
          min-height: 40px;
          background: map_get($colors-form, background);
          color: map-get($colors-form, label-active);
          transition: all 200ms ease-in-out;
          box-sizing: border-box;

          &:not(:last-child) {
            border-bottom: 1px solid map-get($colors-form, border-active);
          }

          &:hover, &:active, &:focus {
            background: map-get($colors-form, background-hover);
            color: map_get($colors-form, label-hover);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $sm-max) {

}

@media only screen and (max-width: $xs-max) {
  .select-wrapper {
    [me\:skin\:type="select"] {
      [me\:skin=select] {
        &.has-value {
          &.has-value ~ label {
            top: -10px;
            line-height: rem(16);
          }
        }
      }

      [me\:skin\:selection] {
        font-size: rem(16);
        line-height: rem(25.6);
        min-height: 30.6px;
      }

      [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
        [me\:skin\:choices] {
          .choice {
            padding: 5px 16px 3px;
            min-height: 40px;
          }
        }
      }
    }
  }
}
