@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.reservation-content{
    position: relative;
    height: calc(100vh + 50px);
    display:flex;
    align-items: center;
    overflow: hidden;

    .wrapper{
        @include col(9);
        @include col-start(2);
        margin-bottom: 100px;
        margin-bottom: 100px;

        button{
            margin-top: 15px;
        }
    }

    span.icon-membership{
        transform: rotateY(180deg);
        width: 1270px;
        max-width: 90%;
        position: absolute;
        bottom:-120px;
        right:0;
    }

    @media only screen and (max-width: $sm-max) {
        .wrapper{
            @include col(8);
            @include col-start(2);
        }

        span.icon-membership {
            width: 696px;
            max-width: none;
            bottom:-30px;
        }
    }
    @media only screen and (max-width: $xs-max) {
        .wrapper{
            @include col(14);
            @include col-start(2);
        }

        span.icon-membership {
            width: 100%;
            bottom: 70px;
        }
    }
}
