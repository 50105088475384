@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.restaurant-schedule {

    .buttons{
        display:none;
    }

    .description-wrapper {
        @include col(6);
        @include col-start(2);
        margin-top: 100px;

        .content-after {
            p {
                &:first-of-type {
                    line-height: 1.6;
                    font-size: rem(26);
                    margin-block: 0 50px;
                }

                &:nth-of-type(2) {
                    font-size: rem(18);
                    margin: 0;
                }

                strong {
                    a {
                        color:  map-get($colors-ui-buttons, primary-color);
                        border-bottom: 1px solid map-get($colors-ui-buttons, border);
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .schedule-wrapper {
        position: relative;
        margin-top: -70px;
        margin-bottom: 300px;
        @include col(8);
        @include col-start(9);
        left: calc(var(--gutter-width) * 1);
        display: flex;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 80px;
        z-index: 5;

        .content {
            padding: 100px 130px;
            max-width: 600px;
            width: 100%;

            .title {
                h3 {
                    &.like-h5 {
                        font-size: rem(30);
                        margin-bottom: 15px;
                        text-transform: unset;
                    }
                }

                p {
                    margin-block: 0 20px;
                }
                > div {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    padding-left: 2px;

                    span {
                        &:last-child {
                            @include ciutadella-semibold();
                        }
                    }
                }
            }
            .load-more{
                display:flex;
                justify-content: flex-end;
                right: 20px;
                margin-top: 20px;
                position: relative;
                span{
                    top: 33%;
                    svg{
                        path{
                            stroke-width: 1.3px;
                            stroke: map-get($colors-base, dark-text);
                        }
                    }
                }
            }

            .contact {
                h3 {
                    &.like-h5 {
                        font-size: rem(30);
                        margin-bottom: 15px;
                        text-transform: unset;
                    }
                }

                p {
                    margin: 0;
                    font-size: rem(21);
                }

                a {
                    @include ciutadella-semibold();
                    font-size: 1rem;
                }

                span:not(.icon-external) {
                    margin-top: 10px;
                    margin-bottom: 15px;
                }

                svg {
                    position: relative;
                    top: -1px;

                    path {
                        stroke: map-get($colors-ui-buttons, tertiary-color-alt);
                        stroke-width: 1.2px;
                    }
                }
            }

            .staff-wrapper {
                margin-top: 40px;

                p {
                    margin: 0;
                    font-size: rem(21);

                    span {
                        @include ciutadella-semibold();
                        font-size: rem(21);
                    }
                }
            }

            nav {
                ul {
                    position: relative;
                    display: flex;
                    list-style: none;
                    margin-bottom: 25px;

                    &:after{
                        content: '';
                        position: absolute;
                        bottom: 1px;
                        width: 100%;
                        height: 1px;
                        background: map-get($colors-restaurant, border);
                    }

                    li {
                        padding: 0 15px;

                        &:first-child {
                            padding-left: 0;
                        }

                        a {
                            position: relative;
                            padding: 12px 0;
                            line-height: 1.1rem;
                            display: block;
                            text-decoration: none;
                            color: map-get($colors-restaurant, text);

                            &.active {
                                @include ciutadella-semibold();
                            }

                        }
                    }

                    .border-bottom{
                        height: 2px;
                        background: map-get($colors-restaurant, border-active);
                        width:0;
                        position: absolute;
                        bottom: 0;
                        left:0;
                        transition: all 200ms ease-in-out;
                        z-index: 1;
                    }
                }
            }

            .tab-content {
                position: relative;
                min-height: 240px;
                margin-bottom: 55px;

                .tab-pane {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    overflow: hidden;
                    opacity: 0;
                    transition: opacity .1s linear;

                    &#section1, &#section2, &#section3 {
                        div {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 5px;

                            span {
                                font-size: rem(21);
                                &:last-child {
                                    @include ciutadella-semibold();
                                }
                            }
                        }
                    }

                    h2 {
                        margin: 0;
                    }

                    &.active {
                        opacity: 1;
                        transition: opacity ease-out 0.2s 0.1s;
                    }
                }
            }

        }
    }

    @media only screen and (max-width: $md-max) {
        .schedule-wrapper {
            left: calc(var(--gutter-width) * 2);
            .content {
                padding-inline: 45px;
            }
        }
    }
    @media only screen and (max-width: $sm-max) {
        overflow: hidden;
        margin-top: 40px;

        .buttons{
            display:flex;
            flex-direction: column;
            gap: 10px;
        }

        .description-wrapper{
            margin-top: 60px;
            @include col(16);
            @include col-start(1);
        }

        .schedule-wrapper {
            position: relative;
            @include col(16);
            @include col-start(1);
            border-bottom-left-radius: 0;
            justify-content: flex-start;
            margin-top: 60px;
            margin-bottom: 0;

            &:before{
                position: absolute;
                content: ' ';
                background-color: $white;
                right: 100%;
                width: 50vw;
                height: 100%;
                z-index:1;
            }

            .content {
                position: relative;
                z-index:2;
                padding-block: 60px;
                padding-inline: 0;
                left: calc(-1 * var(--gutter-width) * 2);

                .tab-content {
                    min-height: 220px;
                }
            }
        }
    }
}
