@use "config" as *;
@use "mixins" as *;
@use "functions" as *;

@use 'ui.buttons' as *;

ul{
    padding-left:0;
    list-style-position: inside;
}

.tag{
    background-color: map-get($colors-ui, tag-background);
    border-radius: 100px;
    padding: 7px 15px;
    @include text-s();

    &.large{
        @include shentox-bold();
        padding: 12px 20px;
        font-size: rem(14);
        line-height: rem(16.8);
    }

    @media only screen and (max-width: $xs-max) {
        @include text-xs();
    }
}
