@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-timeline{
    min-height: 700px;
    overflow: hidden;

    .mobile-only{
        display: none;
    }


    .images{
        position: absolute;
        z-index:0;
        width: 60vw;
        left: -80px;
        aspect-ratio: 800/700;
        height: 700px;
        overflow: hidden;
        border-bottom-right-radius: 70px;

        &:before{
            position: absolute;
            content: ' ';
            height: 100%;
            width: 60%;
            right: -15%;
            z-index:2;
            background: linear-gradient(270deg, rgba(0,0,0,0.5032387955182073) 0%, rgba(0,0,0,0) 100%);
        }

        &:after{
            position: absolute;
            content: ' ';
            height: 100%;
            width: 60%;
            left: -15%;
            z-index:2;
            background: linear-gradient(90deg, rgba(0,0,0,0.5032387955182073) 0%, rgba(0,0,0,0) 100%);
        }

        img{
            position: absolute;
            top:0;
            left:-20px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            opacity:0;
            transform: scale(1.07);

            transition: all 0.64s ease;

            &.active{
                opacity: 1;
                left:0;
                transform: scale(1);
            }
        }
    }

    .content{
        position: relative;
        z-index:2;
        margin-top: 105px;
        @include grid(16, var(--gutter-width));
        @include col(16);
    }

    .dates-wrapper{
        @include col-start(8);
        @include col(9);
        position: relative;
        height: 120px;

        .dates{
            position: absolute;
            left:0;
            bottom:0;
            width: max-content;
            display:flex;
            align-items: flex-end;
            gap: 40px;

            .date{
                position: relative;
                float: left;
                transition: all 0.6s ease-out, font-size 0.45s ease, line-height 0.45s ease, top 0.45s ease, opacity 0.5s ease-in-out;
                color: $white;

                &.like-h4{
                    top: 0px;
                }

                &.like-h1{
                    top: 12px;
                }

                &.hidden{
                    opacity:0;
                    pointer-events: none;
                }
            }
        }
    }

    .title-separator{
        margin-bottom: 80px;
    }

    .details{
        @include col-start(11);
        @include col(5);
        min-height: 250px;
        position: relative;
        margin-bottom: 45px;

        .detail{
            position: absolute;
            top:0;
            left:0;
            opacity:0;
            transition: all 0.3s ease;
            pointer-events: none;

            &.active{
                pointer-events: auto;
            }

            .title{
                @include text-l-semibold();
                margin:0 0 10px;
            }

            .description{
                margin: 0;
            }
        }
    }

    .controls{
        @include col-start(11);
        @include col(5);
        display:flex;
        gap: 10px;
    }

    @media only screen and (max-width: $sm-max) {
        min-height: 0;

        .mobile-only{
            display: block;
            position: absolute;
            z-index: 3;
            top: 30px;
            width: 100%;
        }

        .images{
            width: 90vw;
            left: -40px;
            height: 400px;
            min-height: 400px;
            position: relative;

            &:before{
                width: 100%;
                height: 60%;
                top: -15%;
                left:0;
                right:0;
                background: linear-gradient(180deg, rgba(0,0,0,0.5032387955182073) 0%, rgba(0,0,0,0) 100%);
            }

            &:after{
                width: 100%;
                height: 60%;
                bottom: -15%;
                left:0;
                right:0;
                background: linear-gradient(360deg, rgba(0,0,0,0.5032387955182073) 0%, rgba(0,0,0,0) 100%);
            }
        }

        .content{
            margin-top: 0px;
            @include grid(16, var(--gutter-width));
            @include col(16);

            .title-separator{
                display: none;
            }
        }

        .dates-wrapper{
            @include col-start(1);
            @include col(16);
            height: 120px;
            top: -73px;
            position: absolute;

            .dates{
                position: absolute;
                gap: 35px;

                .date{

                    &.like-h4{
                        font-size: rem(38);
                        line-height: rem(38);
                    }

                    &.like-h1{
                        top:8px;
                        font-size: rem(82);
                        line-height: rem(82);
                    }
                }
            }
        }

        .details{
            margin-top: 80px;
            @include col-start(1);
            @include col(16);
            min-height:0;

            .detail{
                .title{
                    @include text-xl-semibold();
                }
            }
        }

        .controls{
            @include col-start(12);
            @include col(5);
            display:flex;
            justify-content: flex-end;
            gap: 10px;
        }
    }

    @media only screen and (max-width: $xs-max) {
        .images {
            height: 260px;
            min-height: 260px;
        }

        .controls{
            @include col-start(10);
            @include col(7);
        }
    }
}

