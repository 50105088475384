@use "../config" as *;
@use "../functions" as *;
@use "../_mixins/fonts";

@mixin text-xs(){
    @include fonts.ciutadella-regular();
    font-size: rem(12);
    line-height: rem(15.6);
}

@mixin text-s(){
    @include fonts.ciutadella-regular();
    font-size: rem(14);
    line-height: rem(16.8);
}
@mixin text-s-semibold(){
    @include fonts.ciutadella-semibold();
    font-size: rem(14);
    line-height: rem(16.8);
}

@mixin text-m-menu(){
    @include fonts.ciutadella-regular();
    font-size: rem(18);
    line-height: rem(21.6);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(16);
        line-height: rem(19.2);
    }
}

@mixin text-m(){
    @include fonts.ciutadella-regular();
    font-size: rem(18);
    line-height: rem(30.6);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(16);
        line-height: rem(25.8);
    }
}

@mixin text-m-semibold(){
    @include fonts.ciutadella-semibold();
    font-size: rem(18);
    line-height: rem(25.8);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(16);
        line-height: rem(25.8);
    }
}

@mixin text-l(){
    @include fonts.ciutadella-regular();
    font-size: rem(22);
    line-height: rem(33);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(20);
        line-height: rem(22);
    }
}

@mixin text-l-semibold(){
    @include fonts.ciutadella-semibold();
    font-size: rem(22);
    line-height: rem(33);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(20);
        line-height: rem(22);
    }
}

@mixin text-xl(){
    @include fonts.ciutadella-regular();
    font-size: rem(26);
    line-height: rem(31.2);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(24);
        line-height: rem(24);
    }
}

@mixin text-xl-semibold(){
    @include fonts.ciutadella-semibold();
    font-size: rem(26);
    line-height: rem(31.2);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(24);
        line-height: rem(24);
    }
}
