@use "../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

.lg-container{
    .lg-download{
        display:none;
    }

    .lg-close{
        &:after{
            font-size: 30px;
            color: map-get($colors-base, dark-text);
        }
    }

    .lg-counter{
        @include text-m-semibold();
        color: map-get($colors-base, dark-text);
    }

    .lg-backdrop{
        background-color: map-get($colors-base, background);

        &.in{
            opacity: 0.95;
        }
    }

    .lg-next, .lg-prev{
        background: none;
        &:before, &:after {
            content: '';
        }
        
        @media only screen and (max-width: $xs-max) {
                display: none;
            }
    }


    .lg-outer{

        .lg-object{
            object-fit:cover;
            object-position: center;
        }
        .lg-inner{
            bottom: 47px;
        }
    }
}
