@import "../../../config";
@import "../../../mixins";
@import "../../../functions";

section.sports-schedule-listing {
  margin-bottom: 160px;

  @media only screen and (max-width: $sm-max) {
    margin-bottom: 60px;
  }

  .centered-content {
    .search-content {

      @include col-start(2);
      @include col(14);
      @media only screen and (max-width: $sm-max) {
        @include col-start(1);
        @include col(var(--column-amount));
      }
    }

    #queries {
      @include col-start(2);
      @include col(14);
      @media only screen and (max-width: $sm-max) {
        @include col-start(1);
        @include col(var(--column-amount));
      }

      .query-results-container {
        position: relative;
        padding-block: 20px 25px;
        display: flex;
        column-gap: 25px;
        align-items: center;
        @media only screen and (max-width: $xs-max) {
          padding-top: 0;
          flex-direction: column;
          align-items: flex-start;
        }

        .sport-separator {
          height: 0;
          border-bottom: 1px solid map_get($colors-sports-selector, border);
          width: 150vw;
          position: absolute;
          left: -25vw;
          bottom: 0;
        }

        .elems {
          @include text-m-semibold();
          line-height: 1;
        }
      }

      .message-container {
        margin-top: 30px;
      }
    }

    .filters-content {
      #filters {
        position: relative;

        .wrapper-details {
          position: relative;
          display: flex;
          width: calc(100% + 20px);
          height: calc(100% + 20px);
          left: -10px;
          @media only screen and (max-width: $sm-max) {
            width: 100%;
            left: unset;
          }
          @media only screen and (max-width: $xs-max) {
            width: calc(100% + 40px);
            left: -16px;
          }


          ul {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            column-gap: 5px;

            margin: 0;
            padding: 0 1px;


            overflow-x: auto;

            overflow-y: hidden;

            -webkit-overflow-scrolling: auto;
            flex-wrap: nowrap;


            @media only screen and (min-width: $sm-min) {

              padding-block: 2px;

              //scrollbar-width: thin;


              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px $cinder-bg;
                border-radius: 10px;
                background-color: transparent;
              }

              &::-webkit-scrollbar {
                height: 5px;
                background-color: transparent;
                border-radius: 10px;

              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px $cinder-bg;
                background-color: $white;
              }

            }

            @media only screen and (max-width: $sm-max) {

              &::-webkit-scrollbar {
                display: none;
              }

              scrollbar-width: none;
              scrollbar-color: transparent transparent;
              -ms-overflow-style: none;
            }

            border-radius: 100px;


            li {
              list-style: none;
            }

            @media only screen and (max-width: $xs-max) {
              padding-inline: 16px 16px;
              border-radius: unset;
            }
          }

          .clip-path {
            position: absolute;
            top: -10px;
            width: calc(100% + 20px);
            left: -10px;
            background-color: rgb(40, 44, 47);
            border-radius: 100px;
            height: calc(100% + 20px);
            z-index: -1;

            @media only screen and (max-width: $xs-max) {
              display: none;
            }

          }

        }

        @media only screen and (max-width: $xs-max) {
          box-sizing: border-box;

          .wrapper-details {
            overflow-x: auto;
            justify-content: flex-start;
            -webkit-overflow-scrolling: auto;
            flex-wrap: nowrap;


            &::-webkit-scrollbar {
              display: none;
            }

            scrollbar-width: none;
            scrollbar-color: transparent transparent;
            -ms-overflow-style: none;


            button {
              display: none;
            }
          }

          .categories {
            scroll-snap-type: x mandatory;
            padding-inline: calc(8px * 2);

            .category {
              //scroll-snap-align: end;
            }

          }

          ul {
            white-space: nowrap;
          }
        }
        @media only screen and (max-width: 400px) {
          .wrapper-details {
            .categories {
              column-gap: 3px;

              .tag {
                padding-inline: 13px;
              }
            }
          }
        }
      }
    }


    #results.htmx-request {
      filter: blur(3px);
      transition: all ease 250ms;
    }

    #results {

      transition: all ease 250ms;
    }

    .results {

      @media only screen and (max-width: $xs-max) {
        .sport {
          .formatted-date {
            margin-bottom: 10px;
          }
        }
      }
    }

    #load-more {
      @include col-start(2);
      @include col(14);
      display: flex;
      justify-content: flex-end;

      position: relative;
      left: -20px;

      button.tertiary-cta {
        margin-top: 50px;
      }

      @media only screen and (max-width: $sm-max) {
        @include col-start(1);
        @include col(var(--column-amount));
      }
    }
  }
}