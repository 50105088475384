@import "../../config";
@import "../../mixins";
@import "../../functions";

.go-back{
    position: sticky;
    top: 96px;
    background: map-get($colors-base, background);
    z-index: 5;

    .return-cta{
        display: inline-flex;
        align-items: center;
        height: 90px;
        padding-bottom: 0;
        cursor: pointer;

        &:hover{
            svg{
                path{
                    stroke: map-get($colors-ui-buttons, primary-hover-bg);
                    transition: stroke ease 250ms;
                }
            }
        }
        svg{
            position: relative;
            top: 0;
            margin-right: 12px;
            path{
                stroke: map-get($colors-ui-buttons, primary-bg);
            }
        }
        &::before,
        &::after {
            display: none;
        }

        button, a {
            padding-bottom: 0;
        }
    }

    &:after{
        content: '';
        position: absolute;
        bottom:0;
        right: 0;
        width: calc(100% + var(--gutter-width) * 3);
        background: map-get($colors-restaurant-menu, border-light);
        height: 1px;
        left: calc(-2 * var(--gutter-width));

        @media only screen and (max-width: $sm-max){
            width: calc(100% + var(--gutter-width) * 2);
        }
    }

    @media only screen and (max-width: 1200px){
        top: 70px;
        margin: 0 calc(var(--gutter-width) * -2);
        padding-left: calc(var(--gutter-width) * 2);
        padding-right: calc(var(--gutter-width) * 2);

        .return-cta {
            height: 60px;
        }
    }
}
