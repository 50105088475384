@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.promotions-list{
    margin: 250px 0 150px;

    .wrapper{
        position:relative;
        @include grid(14, var(--gutter-width));
        @include col(14);
        @include col-start(2);

        .membership{
            @include col(5);
            @include col-start(10);
            position: absolute;
            top: -350px;
            background:map-get($colors-promo-list, membership-background);
            border-radius: 0 0 30px 0;
            padding: 40px;
            z-index: 5;

            .title{
                margin-top:0;
                @include text-xl-semibold();

                br{
                    display:none;
                }
            }

            .description{
                margin-bottom: 25px;
            }

            .buttons{
                display:flex;
                gap: 10px;
                a{
                    max-height: 44px;
                    height: auto;
                    max-width:50%;
                }
            }
        }

        h2{
            @include col(8);
        }

        p.description{
            @include col(5);
            @include col-start(10);
            margin:0;
        }

        .tiles-wrapper{
            margin-top: 60px;
            position: relative;
            .list{
                width: 100%;
                display: inline-grid;
                gap: 45px;
                grid-template-columns: repeat(3, 1fr);

                .tile{
                    position: relative;
                    top:0;
                    border: 1px solid map-get($colors-scrolling-container, border);
                    opacity:1;
                    transition: all 0.4s ease-out;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(0deg, rgb(0 0 0 / 50%) 0%, rgba(0, 0, 0, 0) 50%);
                    }

                    @media only screen and (min-width: $sm-min) {
                        &::before {
                            padding-bottom: 102%; /* Minimum aspect ratio */
                            content: '';
                            float: left;
                        }
                        &::after {
                            display: table;
                            clear: both;
                        }
                    }

                    &.revealing{
                        opacity:0;
                        top:50px;
                    }
                }
            }
        }

        .btn-reveal{
            float:right;
            margin-right:20px;
            margin-top:40px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin: 150px 0 100px;

        .wrapper {
            @include col(16);
            @include col-start(1);

            .membership {
                @include col(5);
                @include col-start(9);
                top: -270px;

            }

            .tiles-wrapper {
                .list {
                    gap: 20px;
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        margin: 350px 0 100px;

        .wrapper {
            @include col(16);
            @include col-start(1);
            @include grid(16, var(--gutter-width));

            .membership {
                top: -470px;
                right:0;
                border-radius: 0 0 0 30px;
                padding: 50px 30px;
                margin-right: -16px;
                @include col(15);
                @include col-start(2);
            }


            h2{
                @include col(16);
            }

            p.description{
                @include col(16);
                @include col-start(1);
            }

            .tiles-wrapper {
                display:flex;
                flex-direction: column;

                .list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 10px;
                }
            }

            .btn-reveal{
                float: none;
                margin-left:auto;
                margin-right: auto;
            }
        }
    }
}
