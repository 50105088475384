@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.blog-listing {
  .centered-content {
    padding-bottom: 50px;
    @media only screen and (max-width: $sm-max)  {
      padding-bottom: 25px;
    }

    .filters-content{
      @include col-start(2);
      @include col(14);

      @media only screen and (max-width: $sm-max) {
        @include col-start(1);
        @include col(var(--column-amount));
      }
      #filters {
        padding-block: 18px;
        position: relative;

        .wrapper-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: -25%;
          width: calc(100% + 50%);
          background-color: map-get($colors-ui, border-dark);
          height: 1px;
        }

        ul {
          display: flex;
          column-gap: 5px;
          padding: 0;
          margin-block: 7px;

          li {
            list-style: none;
          }
        }

        @media only screen and (max-width: $sm-max) {
          width: calc(100% + 8px * 4);
          margin-left: calc(-8px * 2);
          box-sizing: border-box;

          .wrapper-details {
            overflow-x: auto;
            justify-content: flex-start;
            -webkit-overflow-scrolling: auto;
            flex-wrap: nowrap;

            &::-webkit-scrollbar {
              display: none;
            }

            scrollbar-width: none;
            scrollbar-color: transparent transparent;
            -ms-overflow-style: none;

            //touch-action: auto !important;

            scroll-snap-align: end;
          }

          .categories {
            scroll-snap-type: x mandatory;
            padding-inline: calc(8px * 2) calc(8px * 4);
          }

          .category {
            scroll-snap-align: end;

            .tag.large {
              @include text-s();
              padding: 7px 17px 5px 15px;
            }

          }

          ul {
            white-space: nowrap;
          }
        }
      }

    }

    .main-content {
      @include col-start(2);
      @include col(14);

      transition: all $defaultTransitionSpeed ease-in-out;

      @media only screen and (max-width: $sm-max) {
        @include col-start(1);
        @include col(var(--column-amount));
      }


      .results {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px 45px;
        padding-block: 50px;
        transition: all $defaultTransitionSpeed ease-in-out;

        button{
          position: absolute;
          top: 40px;

          right: 0;
        }
        @media only screen and (max-width: $sm-max) {
          grid-template-columns: repeat(2, 1fr);
          row-gap: 40px;
          button {
            display: none;
          }
        }
        @media only screen and (max-width: $xs-max) {
          grid-template-columns: repeat(1, 1fr);

          button {
            display: none;
          }

        }
      }
    }

    #pagination {
      padding-block: 25px 75px;
      border-top: 1px solid map-get($colors-ui, border-dark);
      display: flex;
      justify-content: flex-end;
      align-items: center;


      @media only screen and (max-width: $sm-max) {
        @include col-start(1);
        @include col(var(--column-amount));
        padding-bottom: 50px;
      }

      .pagination {
        display: flex;
        align-items: center;
        column-gap: 40px;

        @media only screen and (max-width: $sm-max){
          width: 100%;
          justify-content: space-between;
          column-gap: 30px;

        }

        .numbers {
          display: flex;
          column-gap: 32px;
          @media only screen and (max-width: $sm-max) {
          column-gap: 30px;
          }

            > * {
            @include text-l-semibold();
            line-height: rem(26);
          }

          span {
            @media only screen and (max-width: $sm-max) {

              &.pagination-ellipsis {
                margin-inline: -20px;
              }
            }
            &.active {
              position: relative;

              &:after {
                content: '';
                position: absolute;
                top: calc(50% - 2px);
                left: 50%;
                transform: translate(-50%, -50%);
                width: 40px;
                height: 40px;

                background-color: map-get($colors-ui, tag-background);
                z-index: -1;
                border-radius: 50%;
              }
            }
          }
        }

        button {
          -webkit-font-smoothing: antialiased;

          &:disabled {
            color: map-get($colors-ui-buttons, arrows-disabled);

            &:before, &:after {
              background-color: map-get($colors-ui-buttons, arrows-disabled);
            }
          }
        }
      }
    }
  }
}