@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.partners {
    margin: 150px 0 -1px;
    border-radius: 0 0 30px 30px;

    .wrapper {
        padding-block: 150px 200px;
        @include col(14);
        @include col-start(2);
        @include grid(14, var(--gutter-width));

        .title-content{
            @include col(7);
            @include col-start(1);
            margin-bottom: 45px;
        }

        .partners-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            .partner {
                position: relative;
                width: 180px;
                padding: 30px;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #E4E4E4;
                box-sizing: border-box;

                &.partner-hover {
                    img {
                        transform: scale(1);
                        transition: all 0.3s ease;
                    }

                    &:hover {
                        img {
                            transform: scale(0.95);
                            transition: all 0.3s ease;
                        }
                    }
                }

                > a {
                    position: absolute;
                    inset: 0;
                    z-index: 1;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin: 100px 0 -1px;
        .wrapper{
            @include col-start(1);
            @include col(16);
            padding-block: 100px 100px;

            .partners-wrapper {
                .partner {
                    padding: 20px;
                }
            }

            .title-content{
                @include col(12)
            }
        }
    }
    @media only screen and (max-width: 787px) {
        .wrapper{
            .partners-wrapper{
                .partner{
                    width: calc(33% - var(--gutter-width));
                }
            }
        }
    }

    @media only screen and (max-width: 595px) {
        .wrapper{
            .partners-wrapper{
                .partner{
                    width: calc(50% - var(--gutter-width));
                }
            }
        }
    }



}
