@import "../../config";
@import "../../mixins";
@import "../../functions";


#overlay-sport, #overlay-sport-sprig{
  .sticky-title{
    width: 100%;
    position: sticky;
    padding-inline: 0;
  }
}

#overlay-sport-sprig{
  .load-more{
    display:flex;
    justify-content: flex-end;
    margin-top: 20px;
    button{
      left: -20px;
      span.icon-plus{
        top: calc(50% - 1px);
        svg{
          path{
            stroke: map-get($colors-ui-buttons, secondary-color-alt);
          }
        }
      }
    }
  }
}

.sports-selector-wrapper {
  @include grid(16, var(--gutter-width));

  &.in-popup {
    @include grid(6, var(--gutter-width));

    &.htmx-request{
      filter: blur(3px);
    }

    .limitations {
      margin-top: -20px;
      @include col(7);
      @media only screen and (max-width: $xs-max) {
        margin-top: -15px;
        line-height: 1.2;
      }
    }

    .sport-event-details {
      position: relative;
      padding: 20px;
      border-radius: 10px;
      background-color: map-get($colors-overlay, hightlighted-background);
      @include col(7);


      span {
        display: block;
      }

      .event-title {
        @include text-xl-semibold();
        margin-bottom: 0;
      }

      .sport-title {
        line-height: 1.7;
      }

      .date {
        margin-top: 20px;
      }
    }

    .sports-selector {
      @include grid(6, var(--gutter-width));
      @include col(7);
      @include col-start(1);
      position: relative;

      .form-submit-loader {
        position: absolute;
        width: 100%;
        top: 0;
      }

      .sports-list {
        @include col(7);

        .sport, .sport-event {
          @include grid(6, var(--gutter-width));
          flex-direction: column;
          padding-bottom: 35px;
          padding-top: 30px;
          position: relative;
          cursor: pointer;

          .sport-overlay {
            position: absolute;
            inset: 0;
            opacity: 1;
            z-index: 2;
            pointer-events: auto;
          }

          .limitations {
            margin-top: -20px;
          }

          &:hover {
            .tertiary-cta {
              &:before {
                background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                animation: grow-line 0.3s 0.26s ease-out forwards;
              }

              &:after {
                background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                animation: shrink-line 0.3s 0s ease-in forwards;
              }
            }

            .icon-arrow {
              position: relative;
              margin-left: 5px;
              transition: margin-left 250ms ease;
            }
          }


          > *:nth-child(1) {
            @include col-start(1);
            @include col(1);

            .icon-arrow {
              width: 100%;
              transition: margin-left 250ms ease;
              max-width: 52px;

              svg {
                path {
                  stroke: currentColor;
                }
              }

              @media only screen and (max-width: $xs-max){
                max-width: 38px;
              }
            }
          }

          > *:nth-child(2) {
            @include col(5);
            @include col-start(2);

            display: flex;
            flex-direction: column;

            .sport-title {
              margin-block: 0;
              @include text-xl-semibold();
            }

            span {
              margin-bottom: 15px;
            }

            .tertiary-cta {
              width: fit-content;
            }

          }

          > *:nth-child(3) {
          }

          .sport-separator {
            height: 0;
            border-bottom: 1px solid map_get($colors-overlay, backdrop);
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: border-bottom ease 250ms;
          }
        }
      }

      .load-more {
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;

        .btn-reveal {
          left: -20px;

          span {
            top: 33%;
          }

          svg {
            * {
              stroke: map_get($colors-restaurant-selector, border);
            }
          }
        }
      }
    }

    .load-more{
      @include col(7);
    }
  }
}