@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.menu-type-legal {
    padding: 0px 0 150px;

    .legal-wrapper{
        @include grid(16, var(--gutter-width));

        .legal{
            margin-top: 70px;
            @include col(14);
            @include col-start(2);

            p{
                margin: 0;
                font-size: rem(16);
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        padding: 0 0 100px;
        .legal-wrapper{

            .legal{
                @include col(16);
                @include col-start(1);
            }
        }
    }
}
