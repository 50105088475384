@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

span[class^='icon-'] {
    display: inline-block;

    svg {
        box-sizing: content-box;
        display: block;
        width: 100%;
        margin: 0;
    }

    &.icon-plus {
        width: 12px;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translate(100%, -50%);
    }

    &.icon-arrow {
        width: 26px;
    }

    &.icon-footer-lines {
        width: 1148px;
    }

    &.icon-facebook {
        width: 23px;
    }

    &.icon-instagram {
        width: 23px;
    }

    &.icon-linkedin {
        width: 23px;
    }

    &.icon-twitter {
        width: 23px;
    }
    &.icon-tiktok {
        width: 23px;
    }

    &.icon-youtube {
        width: 23px;
    }

    &.icon-external {
        width: 17px;
    }

    &.icon-logo {
        width: 232px;
    }

    &.icon-logo-grandio {
        width: 180px;
        max-width: 100%;

        @media only screen and (max-width: $sm-max) {
            width: 150px;
        }
    }

    &.icon-logo-aniversary {
        width: 503px;
    }

    &.icon-logo-crest {
        width: 153px;
    }

    &.icon-burger {
        width: 24px;
    }

    &.icon-app-store-fr {
        height: 100%;
    }

    &.icon-app-store-en {
        height: 100%;
    }

    &.icon-google-store {
        height: 100%;
    }

    &.icon-membership {
        width: 696px;
    }
    &.icon-la-cage-chez-vous {
        width: 177px;
    }
    &.icon-search {
        width: 14px;
    }
    &.icon-available {
        width: 14px;
    }
    &.icon-unavailable {
        width: 13px;
    }
    &.icon-popular {
        width: 14px;
    }
    &.icon-play {
        width: 148px;
        @media only screen and (max-width: $xs-max) {
            width: 80px;
        }
    }
    &.icon-burger-mobile {
        width: 21px;
    }
    &.icon-field-lines {
        width: 609px;
    }
    &.icon-mail {
        width: 17px;
    }
    &.icon-gift {
        width: 21px;
    }
    &.icon-promo {
        width: 18px;
    }
    &.icon-circle-with-number {
        position: relative;
        width: 69px;

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.icon-big-plus {
        width: 52px;

        svg {
            display: block;
            width: 100%;
            margin: 0;
        }

        @media only screen and (max-width: $xs-max) {
            width: 32px;
        }
    }

    &.icon-blank {
        fill: transparent;
        width: 17px;

        svg {
            path {
                stroke: map-get($colors-ui-buttons, tertiary-color);
            }
        }

        @media only screen and (max-width: $xs-max) {
            width: 15px;
        }
    }
}
