@import "../../config";
@import "../../mixins";
@import "../../functions";

.overlay.restaurant {
    background: transparent!important;

    .wrapper{
        overflow-x: hidden;
    }

    .close{
        svg{
            *{
                stroke: $cinder;
            }
        }
    }
}
