@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

section.pagebuilder-component-blog{
  .centered-content{
    .blog-section{
      @include col-start(2);
      @include col(14);

      .like-h3{
        margin-bottom: 50px;
      }

      .blog-wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 45px;
        row-gap: 50px;
      }

      .load-more{
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }

      @media only screen and (max-width: $sm-max){
        @include col-start(1);
        @include col(var(--column-amount));

        .blog-wrapper{
          grid-template-columns: repeat(2, 1fr);
          row-gap: 40px;
        }
      }
      @media only screen and (max-width: $xs-max){
        .blog-wrapper{
          grid-template-columns: repeat(1, 1fr)
        }
      }
    }
  }
}

