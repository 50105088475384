@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

.nutrition-values{
    >p{
        margin-bottom: 10px!important;
    }

    table{
        width: 100%;

        th{
            border-top: 1px dashed map-get($colors-nutrition-values, border);
            border-bottom: 1px dashed map-get($colors-nutrition-values, border);
            @include text-s-semibold();
            padding: 10px 0 8px;

            &:first-child{
                text-align: left;
            }

            &:last-child{
                text-align: right;
            }
        }

        tr{

            &.subvalues{
                td{
                    border-bottom: none;

                    &:first-child{
                        padding-left: 30px;
                    }
                }
            }

            td{
                padding: 4px 0;
                border-bottom: 1px dashed map-get($colors-nutrition-values, border);

                &:first-child{
                }
                &:last-child{
                    text-align: right;
                }
            }
        }
    }
}
