@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.contact-form {
  margin: 130px 0 225px;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(14, minmax(auto, 1fr));
    grid-column-gap: var(--gutter-width);
    align-items: start;

    @include col(14);
    @include col-start(2);
  }

  .title, .information {
    @include col(5);
    @include col-start(1);
  }

  .title {
    margin: 0 0 100px;

    span {
      display: block;
      @include ciutadella-regular();
      font-size: rem(14);
      line-height: rem(16.8);
      margin: 10px 0 0;
      text-transform: none;
    }
  }

  .information {
    h3 {
      @include ciutadella-semibold();
      font-size: rem(30);
      line-height: rem(38);
      text-transform: none;
      margin: 0 0 10px;
    }

    .head-office {
      margin: 0 0 50px;
    }

    .media {
      a {
        @include ciutadella-semibold();
        color: map_get($colors-base, text);
      }
    }

    p {
      margin: 0;

      &.address {
        margin-bottom: 27px;
      }

      span {
        @include ciutadella-semibold();
      }
    }
  }

  form {
    @include col(8);
    @include col-start(7);
    grid-row: 1 / 4;
  }

  @media only screen and (max-width: $sm-max) {
    .wrapper {
      grid-template-columns: repeat(16, minmax(auto, 1fr));
      grid-column-gap: var(--gutter-width);
      @include col(16);
      @include col-start(1);
    }

    form {
      @include col(10);
    }
  }

  @media only screen and (max-width: $xs-max) {
    margin: 5px 0 159px;

    .title, .information, form {
      @include col(16);
      @include col-start(1);
    }

    .title {
      margin: 0 0 50px;
    }

    .information {
      p {
        &.address {
          margin-bottom: 20px;
        }
      }
    }

    form {
      grid-row: none;
      margin: 0 0 60px;
    }
  }
}