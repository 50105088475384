@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.side-by-side-form{
    padding: 96px 0 150px;

    .go-back{
        top: 96px;
    }

    .wrapper{
        @include grid(16, var(--gutter-width));
        padding: 80px 0 0;

        .heading{
            @include col-start(2);
            @include col(8);
            @media only screen and (max-width: $xs-max) {
                @include col-start(1);
                @include col(16);
            }
        }

        form{
            @include col-start(2);
            @include col(8);

            @media only screen and (max-width: $xs-max) {
                @include col-start(1);
                @include col(16);
            }

            h1{
                margin-bottom: 100px;

                @media only screen and (max-width: $sm-max) {
                    margin-bottom: 60px;
                }
            }

            .label{
                margin-top: 40px;
                @include text-m-semibold();
            }

            .input-wrapper, .select-wrapper{
                margin-bottom: 30px;

                input.has-value + label{
                    color: map-get($colors-form, label-hover);
                }
            }

            .select-wrapper{

            }

            input[type="radio"]{
                display: none;
            }

            .title-separator{
                margin-bottom: 40px;
                margin-top: 100px;

                @media only screen and (max-width: $sm-max) {
                    margin-bottom: 30px;
                    margin-top: 60px;
                }
            }

            input[type="radio"]:checked + .label-button{
                background: map-get($colors-giftcard-creation, border-active);
                border: 1px solid map-get($colors-giftcard-creation, border-active);

                color: map-get($colors-base, light-text);
                @include text-l-semibold();
            }

            .label-button{
                position: relative;
                transform: none;
                padding: 28px;
                cursor: pointer;
                @include text-l();
                color: map-get($colors-base, dark-text);
                border-radius: 8px;
                border: 1px solid map-get($colors-giftcard-creation, border);
                transition: all 250ms ease;
                &:hover{
                    transition: all 250ms ease;
                    border:1px solid map-get($colors-giftcard-creation, border-active);
                    box-shadow: inset 0 0 0 1px map-get($colors-giftcard-creation, border-active);
                }
            }

            .cards-design{
                display:flex;
                flex-wrap: wrap;
                gap: 8px;
                margin-left: -3.5px;

                @media only screen and (max-width: $xs-max) {
                    gap: 6px;
                    margin-left: -3px;
                    width: calc(100% + 9px);
                }

                input{
                    display: none;
                }

                input[type="radio"]:checked + label{
                    border:3.5px solid map-get($colors-giftcard-creation, border-active);

                    @media only screen and (max-width: $xs-max) {
                        border:2px solid map-get($colors-giftcard-creation, border-active);
                    }
                }

                .design{
                    overflow: hidden;
                    border-radius: 10px;
                    width: 145px;
                    aspect-ratio: 145/97;
                    cursor: pointer;
                    border: 3.5px solid transparent;
                    padding: 3px;

                    //override label from generic form styling inside the projet
                    position: relative;
                    transform: none;

                    &.disabled{
                        pointer-events: none;
                    }

                    &:hover{
                        border: 3.5px solid map-get($colors-giftcard-creation, border);
                    }

                    @media only screen and (max-width: $xs-max) {
                        width: calc(25% - 5px);
                        box-sizing: border-box;
                        border:2px solid transparent;
                        padding: 2px;
                        border-radius: 12px;

                        &:hover{
                            border:2px solid map-get($colors-giftcard-creation, border);
                        }
                    }

                    img{
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 7.3px;
                        pointer-events: none;
                    }

                }
            }


            .advice{
                p.label{
                    font-size: rem(18);
                    margin-bottom: 2px;
                }
                p{
                    font-size: rem(18);
                    margin-bottom: 40px;
                }
            }

            .amount{
                display:flex;
                gap: 8px;

                label{
                    width: 25%;
                    text-align: center;
                }

                @media only screen and (max-width: $sm-max) {
                    flex-wrap: wrap;
                    label {
                        width: calc(50% - 4px);
                        box-sizing: border-box;
                    }
                }


            }
            .count{
                height:1px;
                margin-top: 5px;
                display:flex;
                justify-content: flex-end;
            }

            .custom-amount{
                display:flex;
                align-items: center;
                gap: 8px;
                margin-top: 30px;

                .input-wrapper{
                    width: 25%;
                    position: relative;
                    margin:0;

                    @media only screen and (max-width: $sm-max) {
                        width: 50%;
                    }

                    &:after{
                        content: '$';
                        position: absolute;
                        top: 50%;
                        right:0;
                        transform: translateY(-50%);
                    }
                }
            }

            .recipient, .language{
                margin-bottom: 40px;

                .values {
                    display: flex;
                    gap: 8px;
                    margin-bottom: 30px;

                    .label-button {
                        width: 50%;
                        text-align: center;
                    }

                    @media only screen and (max-width: 540px) {
                        flex-wrap: wrap;

                        .label-button{
                            width: 100%;
                        }
                    }
                }
            }

            .submit{
                margin-top: 60px;

                @media only screen and (max-width: $sm-max) {
                    margin-top: 60px;
                }
                @media only screen and (max-width: $xs-max) {
                    margin-top: 30px;
                }
            }
        }

        aside{
            @include col-start(11);
            @include col(6);
            position: relative;

            &.sticky{
                position: sticky;
                top:267px;

                @media only screen and (max-width: $sm-max) {
                    top:237px;
                }
            }

            &.mobile-only{
                display: none;
            }

            @media only screen and (max-width: $xs-max) {
                &.mobile-only{
                    display: block!important;
                    margin-bottom: 8px;
                }
                &.desktop-only{
                    display: none!important;
                }
            }

            .card{
                width: 100%;
                aspect-ratio: 484/322;


                img{
                    position: absolute;
                    top:0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                    border: 1px solid map-get($colors-giftcard-creation, border);

                    &:nth-child(1){
                        z-index:2;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding: 125px 0 150px;

        .go-back{
            top: 125px;
        }
    }

    @media only screen and (max-width: 400px) {
        padding: 145px 0 150px;

        .go-back{
            top: 145px;
        }
    }
}
