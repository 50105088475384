@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

form {
  &.error{
    .error-message{
      display: block;
    }
    .loader-wrapper{
      display:none;
    }
  }

  &.error-card{
    .error-card-message{
      display: block;
    }
    .loader-wrapper{
      display:none;
    }
  }

  &.error-php{
    .error-php-message{
      display: block;
    }
    .loader-wrapper{
      display:none;
    }
  }

  &.success {
    .success-message{
      display: block;
    }
    .loader-wrapper{
      display:none;
    }
  }

  &.loading{
    .loader-wrapper{
      opacity:1;
    }
  }

  .loader-wrapper{
    margin:20px auto;
    transition: opacity 0.3s ease;
    opacity:0;
    width: 30px;
    height: 30px;
    transform-origin: center;
    animation: spin 4.5s linear infinite;

    .loader {
      border: 4px solid  map_get($colors-form, loading);
      border-top: 4px solid  map_get($colors-form, loading-spin);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      box-sizing: border-box;
      transform-origin: center;
      animation: spin 1.5s cubic-bezier(0.725, 0, 0.230, 1) infinite;
    }
  }

  .error-message, .error-php-message, .error-card-message, .success-message{
    display:none;
    margin: 20px auto;
  }
}
