@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.dark, .light .dark{
    .title-separator{
        .lines{
            &::before, &::after {
                background: map-get($colors-title-separator, border-light);
            }
        }
    }
}

.light, .dark .light{
    .title-separator{
        .lines{
            &::before, &::after {
                background: map-get($colors-title-separator, border-dark);
            }
        }
    }
}

.title-separator{
    display: flex;
    margin-bottom: 150px;

    .title{
        flex-shrink: 0;
        margin-bottom: 0;
        display:flex;
        max-width: 85%;

        &+.lines{
            padding-left: 13px;
        }
    }

    .lines{
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::before, &::after{
            content: '';
            display: block;
            height: 4px;
        }

        &::before{
            margin-bottom: 5px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin-bottom: 70px;
        .title{
            &+.lines{
                padding-left: 10px;
            }
        }

        .lines{
            &::before, &::after{
                height: 2px;
            }

            &::before{
                margin-bottom: 3px;
            }
        }
    }
}
