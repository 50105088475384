@import "../../../config";
@import "../../../mixins";
@import "../../../functions";

.notification-header{
    position: fixed;
    z-index:99;
    bottom:40px;
    left:40px;
    right:40px;
    padding: 20px 150px 20px 30px;
    border-radius: 60px;
    background: map-get($colors-notification, background);

    p{
        @include text-m-menu();
        display:inline;
        margin:0;
    }

    .close{
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    @media only screen and (max-width: $xs-max) {
        justify-content: flex-start;
        padding: 12px 105px 12px 20px;
        left: 16px;
        right: 16px;
        bottom: 16px;

        .close{
            right: 20px;
        }

        p{
            margin:0;
            @include text-s();
        }
    }
}
