@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-list-images {
        overflow: hidden;
        --column-list: var(--column-amount);

        .title-wrapper {
            @include col-start(1);
            @include col(16);
            @include grid(var(--column-list), var(--gutter-width));

            h2,
            span {
                @include col-start(2);
            }
        }

        .list-image-wrapper {
            .text-content{
                h4{
                    text-transform: unset;
                    @include text-xl-semibold();
                }
            }
            .image-content {
                position: relative;
                display: flex;
                align-items: center;
                z-index: -1;
                overflow: hidden;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        &.list {
            &-small{
                .title-wrapper {
                    margin-bottom: 100px;
                    h2 {
                        @include col(9);
                    }
                    p {
                        @include col-start(11);
                        @include col(5);
                        margin: 0;
                    }
                }
                .list-image-wrapper {
                    @include grid(var(--column-list), var(--gutter-width));
                    .list-image-content{
                        @include grid(var(--column-list), var(--gutter-width));

                        &:nth-child(4n + 1) {

                            .text-content {
                                @include col-start(2);
                                @include col(5);
                                margin-top: 40px;
                            }

                            .image-content {
                                @include col-start(1);
                                @include col(6);
                                border-bottom-right-radius: 50px;
                                order:-1;

                                img {
                                    //max-height: 390px;
                                    aspect-ratio: 485/390;
                                }
                            }
                        }

                        &:nth-child(4n + 2) {
                            margin-top: -450px;

                            .text-content {
                                @include col-start(10);
                                @include col(5);
                                margin-top: 40px;
                            }

                            svg{
                                position: absolute;
                                width: 100vw;
                                margin-top: 240px;
                                left: calc(-1 * var(--gutter-width) * 2);
                                align-self: center;
                                z-index: -2;
                            }

                            .image-content {
                                @include col-start(10);
                                @include col(7);

                                border-bottom-left-radius: 50px;
                                order: -1;

                                img {
                                    //max-height: 650px;
                                    aspect-ratio: 570/650;
                                }
                            }
                        }

                        &:nth-child(4n + 3) {
                            margin-top: -190px;
                            .text-content {
                                @include col-start(3);
                                @include col(5);
                                margin-top: 40px;
                            }

                            .image-content {
                                @include col-start(3);
                                @include col(6);
                                border-bottom-right-radius: 50px;
                                order: -1;

                                img {
                                    //max-height: 390px;
                                    aspect-ratio: 485/390;
                                }
                            }
                        }

                        &:nth-child(4n + 4) {
                            margin-top: -300px;
                            .text-content {
                                @include col-start(10);
                                @include col(5);
                                margin-top: 40px;
                            }

                            .image-content {
                                @include col-start(10);
                                @include col(7);

                                border-bottom-left-radius: 50px;
                                order: -1;

                                img {
                                    //max-height: 650px;
                                    aspect-ratio: 570/650;
                                }
                            }
                        }

                        &:nth-child(1n + 5) {
                            margin-top: -300px;
                        }
                    }
                }
            }

            &-large {
                .title-wrapper {
                    h2 {
                        @include col(6);
                        z-index: 2;
                    }

                    span {
                        @include col-start(10);
                        @include col(6);
                    }

                    p {
                        @include col-start(11);
                        @include col(5);
                        margin: 0;
                    }
                }

                .list-image-wrapper {
                    @include grid(var(--column-list), var(--gutter-width));

                    .list-image-content {
                        @include grid(var(--column-list), var(--gutter-width));

                        .image-content{
                            &:before{
                                content: '';
                                position: absolute;
                                inset: 0;
                                background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
                            }
                        }
                        &:not(:last-child){
                            margin-bottom: 150px;
                        }
                        &:first-child{
                            margin-top: -60px;
                        }

                        &:nth-child(4n + 1) {

                            .text-content {
                                @include col-start(2);
                                @include col(4);
                                align-self: center;
                                margin-top: 100px;
                            }

                            .image-content {
                                @include col-start(7);
                                @include col(10);
                                left: calc(var(--gutter-width) * 2);
                                border-bottom-left-radius: 70px;


                                img {
                                    //max-height: 825px;
                                    aspect-ratio: 870/825;
                                }
                            }
                        }

                        &:nth-child(4n + 2) {
                            .text-content {
                                @include col-start(12);
                                @include col(4);
                                align-self: center;
                                margin-top: -80px;
                            }

                            .image-content {
                                @include col-start(1);
                                @include col(9);
                                left: calc(-1 * var(--gutter-width) * 2);
                                border-bottom-right-radius: 70px;
                                order: -1;

                                img {
                                    //max-height: 750px;
                                    aspect-ratio: 750/785;
                                }
                            }
                        }

                        &:nth-child(4n + 3) {
                            .text-content {
                                @include col-start(2);
                                @include col(4);
                                align-self: center;
                                margin-top: -80px;
                            }

                            .image-content {
                                @include col-start(7);
                                @include col(10);
                                left: calc(var(--gutter-width) * 2);
                                border-bottom-left-radius: 70px;

                                img {
                                    //max-height: 750px;
                                    aspect-ratio: 850/750;
                                }
                            }
                        }

                        &:nth-child(4n + 4) {
                            .text-content {
                                @include col-start(12);
                                @include col(4);
                                align-self: center;
                                margin-top: -80px;
                            }

                            .image-content {
                                @include col-start(1);
                                @include col(9);
                                left: calc(-1 * var(--gutter-width) * 2);
                                border-bottom-right-radius: 70px;
                                order: -1;

                                img {
                                    //max-height: 750px;
                                    aspect-ratio: 750/785;
                                }
                            }

                        }
                    }

                }
            }
        }
        @media only screen and (max-width: $sm-max) {

            &.list{
                &-small{
                    .title-wrapper {
                        margin-bottom: 60px;
                        h2 {
                            @include col-start(1);
                            @include col(12);
                        }
                        p {
                            @include col-start(1);
                            @include col(16);
                        }
                    }

                    .list-image-wrapper {
                        .list-image-content {
                            .image-content{
                                &:before{
                                    content: '';
                                    position: absolute;
                                    inset: 0;
                                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 20%, rgba(0, 0, 0, 0) 100%);
                                    z-index: 1;
                                }
                            }

                            &:nth-child(4n + 1) {
                                margin-bottom: 65px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(1);
                                    @include col(13);
                                }

                                .image-content {
                                    @include col-start(1);
                                    @include col(16);
                                    left: calc(-1 * var(--gutter-width) * 2);
                                }
                            }

                            &:nth-child(4n + 2) {
                                margin-top: 0;
                                margin-bottom: 65px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(3);
                                    @include col(12);
                                }

                                .image-content {
                                    @include col-start(2);
                                    @include col(15);
                                    left: calc(var(--gutter-width) * 2);
                                }
                                svg{
                                    display: none;
                                }
                            }

                            &:nth-child(4n + 3) {
                                margin-top: 0;
                                margin-bottom: 65px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(1);
                                    @include col(13);
                                }

                                .image-content {
                                    @include col-start(1);
                                    @include col(16);
                                    left: calc(-1 * var(--gutter-width) * 2);
                                }
                            }

                            &:nth-child(4n + 4) {
                                margin-top: 0;
                                margin-bottom: 65px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(3);
                                    @include col(12);
                                }

                                .image-content {
                                    @include col-start(2);
                                    @include col(15);
                                    left: calc(var(--gutter-width) * 2);
                                }
                            }

                            &:nth-child(1n + 5) {
                                margin-top: 0;
                                margin-bottom: 65px;
                            }

                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                &-large{
                    .title-wrapper {
                        margin-bottom: 130px;
                        h2 {
                            @include col-start(1);
                            @include col(12);
                        }
                        span, p {
                            @include col-start(1);
                            @include col(16);
                        }
                    }
                    .list-image-wrapper {
                        .list-image-content {

                            .image-content{
                                &:before{
                                    content: '';
                                    position: absolute;
                                    inset: 0;
                                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0) 100%);
                                    z-index: 1;
                                }
                            }

                            &:last-child{
                                margin-bottom: 0;
                            }
                            &:nth-child(4n + 1) {
                                margin-bottom: 80px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(1);
                                    @include col(13);

                                }

                                .image-content {
                                    order:-1;
                                    @include col-start(2);
                                    @include col(15);
                                    left: calc(var(--gutter-width) * 2);
                                }
                            }

                            &:nth-child(4n + 2) {
                                margin-bottom: 80px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(3);
                                    @include col(12);
                                }

                                .image-content {
                                    order:-1;
                                    @include col-start(1);
                                    @include col(16);
                                    left: calc(-1 * var(--gutter-width) * 2);
                                }
                            }

                            &:nth-child(4n + 3) {
                                margin-bottom: 80px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(1);
                                    @include col(13);
                                }

                                .image-content {
                                    order:-1;
                                    @include col-start(2);
                                    @include col(15);
                                    left: calc(var(--gutter-width) * 2);
                                }
                            }

                            &:nth-child(4n + 4) {
                                margin-bottom: 80px;
                                .text-content {
                                    margin-top: -15px;
                                    @include col-start(3);
                                    @include col(12);
                                }

                                .image-content {
                                    @include col-start(1);
                                    @include col(16);
                                    left: calc(-1 * var(--gutter-width) * 2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
