@use "config" as *;
@use "mixins" as *;
@use "functions" as *;

button{
    background: none;
    border:none;
    outline: none;
    margin: 0;
    cursor: pointer;
    text-decoration: none;

    &:disabled{
        pointer-events: none;
    }
}

.store-buttons{
    height: 44px;
    width: 146px;
    position: relative;
    display:inline-block;

    svg{
        height: 100%;
        width: 100%;
    }
}

.primary-cta{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include shentox-bold();
    font-size: rem(14);
    line-height: rem(14);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    //padding: 14px 19px 13.5px 19px;
    padding: 14px 19px;
    border-radius: 100px;
    border: 1px solid transparent;
    color: map-get($colors-ui-buttons, primary-color)!important;
    background-color: map-get($colors-ui-buttons, primary-bg);
    text-decoration: none;

    column-gap: 8px;

    transition: color $defaultTransitionSpeed ease-in-out, background-color $defaultTransitionSpeed ease-in-out, border $defaultTransitionSpeed ease-in-out;

    &:hover{
        text-decoration: none;
        background-color: map-get($colors-ui-buttons, primary-hover-bg);
    }

    &:active{
        background-color: map-get($colors-ui-buttons, primary-active-bg);
        border: 1px solid map-get($colors-ui-buttons, border);
    }

    .icon-external{
        margin-top: -3px;
    }
}

.secondary-cta{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include shentox-bold();
    font-size: rem(14);
    line-height: rem(14);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 14px 19px;
    border-radius: 100px;
    border: 1px solid map-get($colors-ui-buttons, border);
    color: map-get($colors-ui-buttons, secondary-color);
    background-color: transparent;
    text-decoration: none;

    transition: all $defaultTransitionSpeed ease-in-out;

    &:hover{
        text-decoration: none;
        color: map-get($colors-ui-buttons, secondary-hover-color);
        background-color: map-get($colors-ui-buttons, secondary-hover-bg);
        border: 1px solid transparent;
    }

    &:active{
        color: map-get($colors-ui-buttons, secondary-hover-color);
        background-color: map-get($colors-ui-buttons, secondary-active-bg);
        border: 1px solid transparent;
    }
}

.tertiary-cta{
    position: relative;
    display:inline-flex;
    align-items: flex-end;
    cursor: pointer;
    @include shentox-bold();
    font-size: rem(14);
    line-height: rem(14);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 0 0 3px;
    color: map-get($colors-ui-buttons, tertiary-color);
    text-decoration: none;

    transition: all $defaultTransitionSpeed ease-in-out;

    &:after{
        content: ' ';
        background-color: map-get($colors-ui-buttons, tertiary-color);
        height:1px;
        width: 100%;
        position: absolute;
        bottom:0;
        right:0;
        transform-origin: right;

        transition: all $defaultTransitionSpeed ease-in-out;
    }

    &:before{
        content: ' ';
        background-color: map-get($colors-ui-buttons, tertiary-color);
        height:1px;
        width: 100%;
        position: absolute;
        bottom:0;
        left:0;
        transform: scaleX(0);
        transform-origin: left;

        transition: all $defaultTransitionSpeed ease-in-out;
    }

    & + svg {
        position: relative;
        top: 4px;
        margin-left: 5px;
    }

    svg{
        .stroke{
            stroke: map-get($colors-ui-buttons, tertiary-color);
        }
        .fill{
            fill: map-get($colors-ui-buttons, tertiary-color);
        }
    }

    &:hover{
        text-decoration: none;
        color: map-get($colors-ui-buttons, tertiary-hover-color);

        &:before{
            background-color: map-get($colors-ui-buttons, tertiary-hover-color);
            animation: grow-line 0.3s 0.26s ease-out forwards;
        }

        &:after {
            background-color: map-get($colors-ui-buttons, tertiary-hover-color);
            animation: shrink-line 0.3s 0s ease-in forwards;
        }

        svg{
            .stroke{
                stroke: map-get($colors-ui-buttons, tertiary-hover-color);
            }
            .fill{
                fill: map-get($colors-ui-buttons, tertiary-hover-color);
            }
        }
    }

    &:active{
        color: map-get($colors-ui-buttons, tertiary-active-color);

        &:before, &:after {
            background-color: map-get($colors-ui-buttons, tertiary-active-color);
        }

        svg{
            .stroke{
                stroke: map-get($colors-ui-buttons, tertiary-active-color);
            }
            .fill{
                fill: map-get($colors-ui-buttons, tertiary-active-color);
            }
        }
    }

    &.alt{
        color: map-get($colors-ui-buttons, tertiary-color-alt);

        &:before{
            background-color: map-get($colors-ui-buttons, tertiary-color-alt);
        }

        &:after {
            background-color: map-get($colors-ui-buttons, tertiary-color-alt);
        }

        svg{
            .stroke{
                stroke: map-get($colors-ui-buttons, tertiary-color-alt);
            }
            .fill{
                fill: map-get($colors-ui-buttons, tertiary-color-alt);
            }
        }


        &:hover{
            color: map-get($colors-ui-buttons, tertiary-hover-color-alt);

            &:before{
                background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
            }

            &:after {
                background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
            }

            svg{
                .stroke{
                    stroke: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                }
                .fill{
                    fill: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                }
            }

        }

        &:active{
            color: map-get($colors-ui-buttons, tertiary-active-color-alt);

            &:before{
                background-color: map-get($colors-ui-buttons, tertiary-active-color-alt);
            }

            &:after {
                background-color: map-get($colors-ui-buttons, tertiary-active-color-alt);
            }

            svg{
                .stroke{
                    stroke: map-get($colors-ui-buttons, tertiary-active-color-alt);
                }
                .fill{
                    fill: map-get($colors-ui-buttons, tertiary-active-color-alt);
                }
            }
        }
    }

    &.small{
        font-size: rem(12);
        line-height: rem(12);
    }
}
// Target tertiary-cta parent
span:has(.tertiary-cta[target=_blank]){
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.underline-cta {
    position: relative;
    text-decoration: none;

    &:after{
        content: ' ';
        background-color: map-get($colors-ui-buttons, tertiary-color);
        height:1px;
        width: 100%;
        position: absolute;
        bottom:0;
        right:0;
        transform-origin: right;

        transition: all $defaultTransitionSpeed ease-in-out;
    }

    &:before{
        content: ' ';
        background-color: map-get($colors-ui-buttons, tertiary-color);
        height:1px;
        width: 100%;
        position: absolute;
        bottom:0;
        left:0;
        transform: scaleX(0);
        transform-origin: left;

        transition: all $defaultTransitionSpeed ease-in-out;
    }

    &:hover{
        text-decoration: none;

        &:before{
            background-color: map-get($colors-ui-buttons, tertiary-hover-color);
            animation: grow-line 0.3s 0.26s ease-out forwards;
        }

        &:after {
            background-color: map-get($colors-ui-buttons, tertiary-hover-color);
            animation: shrink-line 0.3s 0s ease-in forwards;
        }
    }

    &:active{
        &:before, &:after {
            background-color: map-get($colors-ui-buttons, tertiary-active-color);
        }
    }

    &.hover-only {
        &:before{
            animation-delay: 0s;
        }
        &:after{
            background-color: transparent;
        }

        &:hover{
            &:after{
                background-color: transparent;
            }
        }
    }
}

.arrow-controls{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border:1px solid map-get($colors-ui-buttons, arrows);
    background-color: transparent;

    transition: border 0.25s ease, background-color 0.25s ease;

    &.previous{
        svg{
            transform: rotate(180deg);
        }
    }

    svg{
        *{
            stroke: map-get($colors-ui-buttons, arrows);
            transition: stroke 0.25s ease;
        }
    }

    &:hover {
        background-color: map-get($colors-ui-buttons, arrows-hover-bg);

        svg {
            * {
                stroke: map-get($colors-ui-buttons, arrows-hover);
            }
        }
    }

    &:active{
        background-color: map-get($colors-ui-buttons, arrows-active-bg);
        border-color: map-get($colors-ui-buttons, arrows-active-bg);
    }

    &:disabled{
        opacity:0.5;
    }
}

//used to force a light with black text section
*.light, .dark .light{
    .secondary-cta{
        border: 1px solid map-get($colors-ui-buttons, border-alt);
        color: map-get($colors-ui-buttons, secondary-color-alt);
        background-color: transparent;

        &:hover{
            color: map-get($colors-ui-buttons, secondary-hover-color-alt);
            background-color: map-get($colors-ui-buttons, secondary-hover-bg-alt);
            border: 1px solid map-get($colors-ui-buttons, secondary-hover-bg-alt);
        }

        &:active{
            color: map-get($colors-ui-buttons, secondary-hover-color-alt);
            background-color: map-get($colors-ui-buttons, secondary-active-bg-alt);
            border: 1px solid map-get($colors-ui-buttons, secondary-hover-bg-alt);
        }
    }
    .tertiary-cta{
        color: map-get($colors-ui-buttons, tertiary-color-white-bg);

        &:after{
            background-color: map-get($colors-ui-buttons, tertiary-color-white-bg);
        }

        &:before{
            background-color: map-get($colors-ui-buttons, tertiary-color-white-bg);
        }

        &:hover{
            color: map-get($colors-ui-buttons, tertiary-hover-color-white-bg);

            &:before, &:after {
                background-color: map-get($colors-ui-buttons, tertiary-hover-color-white-bg);
            }
        }

        &:active{
            color: map-get($colors-ui-buttons, tertiary-active-color-white-bg);

            &:before, &:after {
                background-color: map-get($colors-ui-buttons, tertiary-active-color-white-bg);
            }
        }

        &.alt{
            color: map-get($colors-ui-buttons, tertiary-color-alt);

            &:before{
                background-color: map-get($colors-ui-buttons, tertiary-color-alt);
            }

            &:after {
                background-color: map-get($colors-ui-buttons, tertiary-color-alt);
            }

            &:hover{
                color: map-get($colors-ui-buttons, tertiary-hover-color-alt);


                &:before{
                    background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                }

                &:after {
                    background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                }

            }

            &:active{
                color: map-get($colors-ui-buttons, tertiary-active-color-alt);

                &:before{
                    background-color: map-get($colors-ui-buttons, tertiary-active-color-alt);
                }

                &:after {
                    background-color: map-get($colors-ui-buttons, tertiary-active-color-alt);
                }
            }
        }
    }
    .underline-cta{
        &:after{
            background-color: map-get($colors-ui-buttons, tertiary-color-white-bg);
        }

        &:before{
            background-color: map-get($colors-ui-buttons, tertiary-color-white-bg);
        }

        &:hover{
            &:before, &:after {
                background-color: map-get($colors-ui-buttons, tertiary-hover-color-white-bg);
            }
        }

        &.hover-only{
            &:after{
                background-color: transparent;
            }

            &:hover{
                &:after{
                    background-color: transparent;
                }
            }
        }
    }

    .arrow-controls{
        border:1px solid map-get($colors-ui-buttons, border-alt);
        background-color: transparent;

        svg{
            *{
                stroke: map-get($colors-ui-buttons, secondary-color-alt);
            }
        }

        &:hover {
            background-color: map-get($colors-ui-buttons, secondary-hover-bg-alt);
            border-color: map-get($colors-ui-buttons, secondary-hover-bg-alt);

            svg {
                * {
                    stroke: map-get($colors-ui-buttons, secondary-hover-color-alt);
                }
            }
        }

        &:active{
            background-color: map-get($colors-ui-buttons, secondary-active-bg-alt);
            border-color: map-get($colors-ui-buttons, secondary-active-bg-alt);
        }
    }
}

//used to force a dark with white text section
*.dark, .light .dark, .transparent{
    .secondary-cta{
        border: 1px solid map-get($colors-ui-buttons, border);
        color: map-get($colors-ui-buttons, secondary-color);
        background-color: transparent;

        &:hover{
            background-color: map-get($colors-ui-buttons, secondary-hover-bg);
            color: map-get($colors-ui-buttons, secondary-hover-color);
            border: 1px solid transparent;
        }

        &:active{
            background-color: map-get($colors-ui-buttons, secondary-active-bg);
            color: map-get($colors-ui-buttons, secondary-hover-color);
            border: 1px solid transparent;
        }
    }

    .tertiary-cta{
        color: map-get($colors-ui-buttons, tertiary-color);

        &:after{
            background-color: map-get($colors-ui-buttons, tertiary-color);
        }

        &:before{
            background-color: map-get($colors-ui-buttons, tertiary-color);
        }

        &:hover{
            color: map-get($colors-ui-buttons, tertiary-hover-color);

            &:before{
                background-color: map-get($colors-ui-buttons, tertiary-hover-color);
            }

            &:after {
                background-color: map-get($colors-ui-buttons, tertiary-hover-color);
            }
        }

        &:active{
            color: map-get($colors-ui-buttons, tertiary-active-color);

            &:before, &:after {
                background-color: map-get($colors-ui-buttons, tertiary-active-color);
            }
        }

        &.alt{
            color: map-get($colors-ui-buttons, tertiary-color-alt);

            &:before{
                background-color: map-get($colors-ui-buttons, tertiary-color-alt);
            }

            &:after {
                background-color: map-get($colors-ui-buttons, tertiary-color-alt);
            }

            &:hover{
                color: map-get($colors-ui-buttons, tertiary-hover-color-alt);


                &:before{
                    background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                }

                &:after {
                    background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                }

            }

            &:active{
                color: map-get($colors-ui-buttons, tertiary-active-color-alt);

                &:before{
                    background-color: map-get($colors-ui-buttons, tertiary-active-color-alt);
                }

                &:after {
                    background-color: map-get($colors-ui-buttons, tertiary-active-color-alt);
                }
            }
        }
    }

    .arrow-controls{
        border:1px solid map-get($colors-ui-buttons, arrows);
        background-color: transparent;

        svg{
            *{
                stroke: map-get($colors-ui-buttons, arrows);
            }
        }

        &:hover {
            background-color: map-get($colors-ui-buttons, arrows-hover-bg);

            svg {
                * {
                    stroke: map-get($colors-ui-buttons, arrows-hover);
                }
            }
        }

        &:active{
            background-color: map-get($colors-ui-buttons, arrows-active-bg);
            border-color: map-get($colors-ui-buttons, arrows-active-bg);
        }
    }
}

*.transparent{
    .secondary-cta{
        background-color: transparent;
    }
}

@keyframes grow-line{
    0%{
        transform: scaleX(0);
    }
    100%{
        transform: scaleX(1);
    }
}

@keyframes shrink-line {
    0%{
        transform: scaleX(1);
    }
    100%{
        transform: scaleX(0);
    }
}
