@mixin ciutadella-semibold($font-weight: normal){
    font-family: 'Ciutadella-SemiBold', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin ciutadella-regular($font-weight: normal){
    font-family: 'Ciutadella-Regular', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin shentox-bold($font-weight: normal){
    font-family: 'Shentox-Bold', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}
