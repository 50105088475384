@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.dark, .light .dark{
    .accordion {
        border-top: 1px solid map-get($colors-accordion, border-light);
    }
}

.light, .dark .light{
    .accordion {
        border-top: 1px solid map-get($colors-accordion, border);
    }
}

.accordion {
    @include grid(var(--column-amount), var(--gutter-width));
    border-top: 1px solid map-get($colors-accordion, border);
    padding: 48px 0;

    &:hover {
        cursor: pointer;

        .accordion-trigger {
            svg {
                transform: scale(80%);
            }
        }
    }

    &.always_open{
        cursor: default;

        &:hover{
            cursor: default;

            .accordion-trigger {
                svg {
                    transform: scale(150%);
                }
            }
        }

        .accordion-trigger {
            cursor: default;

            svg {
                margin-left: 10px;
                transform: scale(150%);
            }
        }
    }

    &.active, &.is-opening {
        .accordion-trigger {
            svg {
                .stoke-vertical {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .centered-content {
        .accordion-content-wrapper {
            --column-accordion-wrapper: 14;
            @include grid(var(--column-accordion-wrapper), var(--gutter-width));
            @include col(var(--column-accordion-wrapper));
            @include col-start(2);
            align-items: flex-start;
        }

        .accordion-trigger {
            @include col(2);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;

            svg {
                transition: scale 250ms ease-in-out;

                .stoke-vertical, .stoke-horizontal {
                    transition: rotate 250ms ease-in-out;
                    transform-origin: center center;
                }
            }
        }

        .accordion-title, .accordion-content {
            @include col(10);
            @include col-start(5);
        }

        .accordion-title {
            @include text-xl-semibold();
        }

        .accordion-content {
            @include text-m();
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s ease;

            > *:first-child {
                padding-top: 20px;
            }
        }

        p {
            margin: 0;
        }
    }


    @media only screen and (max-width: $xs-max) {
        padding: 40px 0;

        &.always_open{
            .accordion-trigger {
                display:none;
            }

            .centered-content{
                .accordion-title {
                    @include col(16);
                }

                .accordion-content {
                    @include col(16);
                }
            }
        }

        .centered-content {
            .accordion-content-wrapper {
                --column-accordion-wrapper: 16;
                @include col-start(auto);
            }

            .accordion-trigger {
                @include col(2);
                @include col-start(15);
                grid-row-start: 1;
                justify-content: flex-end;
            }

            .accordion-title {
                //@include text-l-semibold();
                @include col(13);
                @include col-start(1);
                line-height: 1.4;
            }

            .accordion-content {
                @include col(var(--column-accordion-wrapper));
                @include col-start(1);
            }
        }
    }
}
