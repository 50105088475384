@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.membership{
    margin: 150px 0;

    .wrapper{
        @include col(14);
        @include col-start(2);
        @include grid(14, var(--gutter-width));

        position: relative;
        overflow: hidden;
        border-radius: 0 0 50px 0;
        padding: 50px;
        background-color: map-get($colors-membership, background);

        .inner-wrapper{
            position: relative;
            z-index:2;
            @include col(6);
            @include col-start(8);

            p{
                margin:20px 0 35px;
            }
        }

        .membership-bg{
            position: absolute;
            z-index:1;
            left:0;
            bottom:0;
            width: 66%;

            span{
                display: flex;
                align-items: flex-end;
                max-width: 100%;
                position: relative;
                top: 100px;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin: 100px 0;
    }

    @media only screen and (max-width: $xs-max) {
        .wrapper {
            @include col(16);
            @include col-start(1);
            border-radius: 0 0 30px 0;
            padding: 50px 0 100px 0;

            .inner-wrapper{

                @include col(14);
                @include col-start(2);
                padding-right: 20px;

                p{
                    margin:10px 0 30px;
                }
                .store-buttons{
                    height: 40px;
                    width: 134px;
                }
            }

            .membership-bg{
                right:0;
                left:auto;
                bottom: 60px;
                svg{
                    transform: rotateY(180deg);
                }
                width: 85%;

            }
        }
    }
}
