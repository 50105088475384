@import "../../config";
@import "../../mixins";
@import "../../functions";

.overlay.menutype{

    &.active {

        .backdrop{
            opacity: 0.75;
        }
    }
    .backdrop{
        background: $white;
    }
    .wrapper{
        box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.1);

        .img-wrapper{
            overflow: hidden;
            position: relative;
            padding-bottom: 56%;
            left:0;
            max-height: 450px;
            min-width: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tag{
            color: map-get($colors-base, light-text);
            position: absolute;
            z-index:2;
            top: 10px;
            left: 10px;
        }

        .content{

            .legal{
                @include text-xs();
                margin-top:40px;
                padding: 40px 0 20px;
                border-top: 1px solid map-get($colors-overlay, border);

                p{
                    margin-top:0;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        &.selector{
            .wrapper{
                .close{
                    top: 73px;
                    svg{
                        path{
                            stroke-width: 2px;
                            stroke: map-get($colors-restaurant-menu, dark);
                        }
                    }
                }
            }
            .content{
                margin-top: 40px;
                h5{
                    font-size: rem(20);
                    text-transform: unset;
                    padding-block: 15px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        width: 100vw;
                        background: map-get($colors-restaurant-menu, border-light);
                        height: 1px;
                        left: calc(-1 * var(--gutter-width) * 2);
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 100vw;
                        background: map-get($colors-restaurant-menu, border-light);
                        height: 1px;
                        left: calc(-1 * var(--gutter-width) * 2);
                    }
                }
                ul{
                    list-style: none;
                    font-size: rem(18);
                    color: map-get($colors-base, text);
                    width: 100%;
                    li{
                        margin-block: 12px;
                    }
                }
            }
        }
        .wrapper {
            .img-wrapper{
                height: 300px;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .wrapper {
            .tag{
                top: 20px;
                left: 20px;
            }

            .content{
                .legal{
                    margin-top: 30px;
                    padding-top: 30px;
                }
            }
        }
    }
}
