@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.product-single {
    padding: 95px 0 150px;

    >.centered-content{
        margin-bottom: 70px;
    }

    .title-ctn {
        margin-top: 60px;
        @include col-start(2);
        @include col(14);
        margin-bottom:0;

        h1{
            margin-bottom:0;
        }
    }

    .pagebuilder-component-gallery,
    .pagebuilder-component-slider {
        .centered-content{
            margin:0;
            width: 100%;
        }
    }

    .pagebuilder-component-gallery {
        margin-top: 80px;
        margin-bottom: 80px;

        @media only screen and (max-width: $xs-max){
            display: none;
        }
    }

    .pagebuilder-component-slider {
        margin-top: 40px;
        margin-bottom: 30px;

        @media only screen and (min-width: $sm-min){
            display: none;
        }
    }

    .description{
        @include col-start(2);
        @include col(9);
        margin-bottom: 20px;

        p{
            margin:0 0 20px;
            @include text-xl();

            &:last-child{
                margin-bottom:0;
            }
        }

        .additional-infos{
            p{
                @include text-l();
            }
        }
    }

    .infos{
        @include col-start(2);
        @include col(9);

        .product-tag{
            margin-bottom: 10px;
            display:flex;
            gap: 10px;
            align-items: flex-start;

            &:last-child{
                margin-bottom:0;
            }

            img{
                width: 23px;
                height: auto;
            }

            span{
                @include text-l();

                &.mention{
                    display: block;
                    @include text-s();
                }
            }
        }

        .partner{
            margin-bottom: 30px;
            @include text-l();

            p{
                margin:0;
            }

            img{
                margin: 0 20px 20px 0;
                max-width: 150px;
                max-height: 90px;
            }
        }

        .pricing{
            margin-bottom: 30px;
            display:flex;
            flex-direction: column;
            align-items: flex-end;

            &:last-child{
                margin-bottom: 0;
            }

            .like-h3{
                margin-bottom:0;
            }

            .price-variation{
                display:flex;
                justify-content: flex-end;
                gap: 30px;
                align-items: end;

                .variant-title{
                    @include text-l();
                    margin: 10px 0;
                    line-height: 1.1rem;
                }

                .like-h4{
                    margin: 10px 0;
                    min-width: 92px;
                    text-align: right;
                }
            }
        }

        .extra{
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            h5{
                margin:0 0 20px;
            }

            .item{
                padding:5px 0;
                border-bottom: 1px solid map-get($colors-menu-item, border);
                display:flex;
                justify-content: space-between;
                @include text-l();
            }
        }

        .beer-legend, .wine-legend{
            p{
                @include text-m-menu();
            }
        }

        .pairings{
            margin-top: 100px;
            display:flex;
            flex-direction: column;
            gap: 30px;

            .title-separator{
                margin-bottom: 20px;

                .title{
                    font-size: rem(28);
                    @include shentox-bold();
                }
            }

            .pairing-item{
                position: relative;
                @include grid(9, var(--gutter-width));
                @include col(9);
                pointer-events: none; // Temporarily disabled

                @media only screen and (min-width: $sm-min) and (max-width: $sm-max){
                    align-items: unset;
                }

                .tile-link{
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                }

                .image{
                    display: block;
                    @include col(3);
                    border-radius: 0 0 15px 0;
                    overflow: hidden;
                    aspect-ratio: 220/160;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit:cover;

                    }
                }

                .infos{
                    @include col(6);
                    @include col-start(4);
                    align-self: center;

                    .title{
                        @include text-xl-semibold();
                    }

                    .mobile{
                        display:none;
                    }

                    p:first-child{
                        margin-top:0;
                    }
                }
            }
        }
    }

    .accordeons-title{
        @include col-start(2);
        @include col(10);
        margin: 70px 0;
    }

    .allergies{
        @include grid(10, var(--gutter-width));

        .column{
            @include col(3);

            p{
                @include text-m-semibold();
                margin:0;
            }
        }

        ul{
            list-style: none;
            margin:0;

            li{
                @include text-m();
            }
        }

        .legal{
            margin-top: 25px;
            @include text-s();
        }
    }

    @media only screen and (max-width: 1200px) {
        padding: 69px 0 100px;
    }
    @media only screen and (max-width: $sm-max){

        .title-ctn {
            margin-top: 50px;
            @include col-start(1);
            @include col(16);
        }

        .pagebuilder-component-gallery{
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .description{
            @include col-start(1);
            @include col(16);

            p{
                margin:0 0 15px;
                @include text-l();
            }

            .additional-infos{
                p{
                    @include text-m();
                }
            }
        }

        .infos{
            @include col-start(1);
            @include col(16);

            .title-separator .like-h5{
                font-size: rem(24);
                line-height: rem(24);
            }

            .extra{
                .item{
                    border-bottom: none;
                }
            }

            .partner{
                margin-top: 20px;
            }

            .pairings{
                margin-top: 60px;
                gap: 20px;

                .title-separator{
                    margin-bottom: 10px;
                }

                .pairing-item{
                    .infos{
                        padding-left: 12px;

                        .title{
                            margin-bottom: 10px;
                            @include text-l-semibold();
                        }
                    }
                }
            }

            .pricing {
                .price-variation {
                    .variant-title {
                        line-height: 1rem;
                    }
                }
            }
        }

        .promotions-scrolling-list{
            .wrapper{
                @include col-start(1);
                @include col(16);
            }
        }

        .allergies{
            @include grid(13, var(--gutter-width));

            .column{
                @include col(13);
                margin-bottom: 10px;
            }

            ul{
                list-style: inside;

                li{
                    span{
                        position: relative;
                        left: -8px;
                        // this mediaquery detects if user is on safari 10+ - li::marker acts differently so we reset the left property
                        @media (hover:none) and (pointer: coarse){
                            left: 0px !important;
                        }
                        @media not all and (min-resolution:.001dpcm) {
                          left: 0px !important;
                        }
                    }
                }
            }
        }


        .accordeons-title{
            @include col-start(1);
            @include col(16);
            margin: 40px 0;
        }
    }
    @media only screen and (max-width: $xs-max){

        >.centered-content{
            margin-bottom: 60px;
        }

        .infos{
            .pairings{
                .pairing-item{
                    position: relative;

                    .image{
                        aspect-ratio: 124/124;
                        max-height: 124px;
                    }
                    .infos{
                        align-self: flex-start;

                        p{
                            display:none;
                            margin-bottom:0;

                            &:first-child{
                                margin-top: 3px;
                            }
                        }

                        div.mobile{
                            display:block;
                            p{
                                display:block;
                            }
                        }
                    }
                }
            }
        }
    }
}
