@import "../../config";
@import "../../mixins";
@import "../../functions";

.table-of-content{
    margin: 100px 0;
    .centered-content{
        .wrapper{
            border-radius: 0 0 30px 0;
            box-sizing: border-box;
            background: map-get($colors-legal, table-content);
            padding: 40px 40px 32px;
            @include col(14);
            @include col-start(2);

            ol{
                column-count: 2;
                column-gap: 40px;
                list-style-position: inside;
                padding:0;
                margin: 30px 0 0;

                li{
                    margin: 0 0 8px;
                    @include text-m-menu();

                    &:last-child{
                        margin-bottom:0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        overflow: hidden;
        margin: 0 0 50px;

        .centered-content{
            .wrapper {
                @include col(16);
                @include col-start(1);
                border-radius: 0 0 0 30px;
                padding: 40px 30px;

                &:after{
                    content: ' ';
                    position: absolute;
                    background: map-get($colors-legal, table-content);
                    left: 100%;
                    top:0;
                    height: 100%;
                    width: 25%;
                }

                ol{
                    column-count: 1;
                }
            }
        }
    }
}
