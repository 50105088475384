@import "../../config";
@import "../../mixins";
@import "../../functions";


#overlay-giftcard{
  opacity: 0;

  &.overlay{
    &.active{
      opacity: 1;
    }
  }
}