@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.category.checkbox {
  cursor: pointer;
  padding: 0 !important;

  .custom-tag-checkbox {
    cursor: inherit;

    input[type=checkbox] {
      cursor: inherit;
      display: none;
    }

    .tag {
      position: relative;
      background-color: transparent;
      border: 1px solid map-get($colors-ui, tag-border-invert);
      text-transform: uppercase;
      transition: all $defaultTransitionSpeed ease-in-out;
      display: inline-flex;
      align-items: center;

      &.large {
        line-height: 1 !important;
      }

      &:hover {
        text-decoration: none;
        color: map-get($colors-ui-buttons, tag-background-invert);
        background-color: map-get($colors-ui, tag-background);
        border: 1px solid transparent;
      }

      &:active, &:focus {
        background-color: map-get($colors-ui, tag-background-hover);
      }

      .icon-close-tag {
        position: relative;
        width: 19px;
        transition: all $defaultTransitionSpeed ease-in-out;
        height: 17px;
        opacity: 0;
        top: -3px;
        margin-left: -20px;

        svg {
          width: inherit;
          position: relative;
          height: auto;
          top: 1px;
          transition: inherit;
        }

        @media only screen and (max-width: $sm-max) {
          top: -4px;
          width: 17px;
          height: auto;

          svg {
            top: 2.3px;
          }

        }
      }
    }
  }

  &:has(input[type="checkbox"]:checked) {
    .custom-tag-checkbox {
      .tag {
        border: 1px solid transparent;
        background-color: map-get($colors-ui, tag-background);
        padding-right: 19px;

        @media (hover: hover) and (min-width: $sm-min) {
          &:hover {
            background-color: map-get($colors-ui, tag-background-hover);

            .icon-close-tag {
              opacity: 1;
              margin-left: 5px;

              transition: all $defaultTransitionSpeed ease-in-out;

              svg {
                transition: inherit;
                width: inherit;
              }
            }
          }
        }


        @media only screen and (max-width: $sm-max) {
          border: 1px solid transparent;
          background-color: map-get($colors-ui, tag-background);

          .icon-close-tag {
            opacity: 1;
            margin-left: 5px;
            width: 17px;
            height: auto;

            svg {
              width: inherit;
            }
          }
        }
      }
    }
  }
}

