@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-infos-rows {
  --column-list: var(--column-amount);

  .small-grid {
    --column-list: 14;
    @include col-start(2);
  }

  .sub-grid {
    @include col(var(--column-list));
    @include grid(var(--column-list), var(--gutter-width));
  }

  .infos-rows-heading {
    margin-bottom: 135px;

    h2 {
      @include col(8);
      margin-bottom: 0;
    }

    .heading-description {
      @include col-start(10);
      @include col(5);
      p{
        margin: 0;
      }
    }
  }

  .infos-rows-list {
    .info-row {
      @include grid(var(--column-list), var(--gutter-width));
      padding-top: 35px;
      margin-bottom: 70px;
      border-top: 1px solid map-get($colors-infos-rows, border);

      .row-title {
        @include col(5);
        @include text-xl-semibold();
      }

      .row-icon {
        @include col-start(7);
        @include col(2);
      }

      .row-description {
        @include col(6);
        @include text-m();

        a.tertiary-cta {
          margin: 20px 0 0;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  @media only screen and (max-width: $sm-max) {
    --column-list: var(--column-amount);

    .small-grid{
      --column-list: var(--column-amount);
      @include col-start(1);
    }

    .infos-rows-heading {
      margin-bottom: 100px;

      .heading-description {
        @include col(7);
      }
    }

    .infos-rows-list {
      .info-row {
        .row-title {
          @include col(4);
        }

        .row-icon {
          @include col(2);
          @include col-start(7);
        }

        .row-description {
          @include col(8);
        }
      }
    }
  }

  @media only screen and (max-width: $xs-max) {
    .infos-rows-heading {
      margin-bottom: 50px;
      grid-row-gap: 20px;

      h2 {
        @include col(15);
        margin: 0;
      }

      .heading-description {
        @include col(16);
        @include col-start(auto);
      }
    }

    .infos-rows-list {
      .info-row {

        grid-row-gap: 20px;

        .row-title {
          @include col(12);
          @include col-start(4);
        }

        .row-icon {
          @include col(2);
          @include col-start(1);
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .row-description {
          @include col(12);
          @include col-start(4);
        }
      }
    }
  }

  @media only screen and (max-width: 485px) {
    .infos-rows-list {
      .info-row {
        .row-title {
          @include col(12);
          @include col-start(5);
        }

        .row-icon {
          @include col(4);
        }

        .row-description {
          @include col(12);
          @include col-start(5);
        }
      }
    }
  }
}
