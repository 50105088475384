@use "config" as *;
@use "mixins" as *;
@use "functions" as *;

//
// Root styles
// -----------------------------------------------------------------------------

html {
    --column-amount: 16;
    --gutter-width: #{$gutter-width-lg};
    --font-size: 18px;

    scroll-padding-top: 115px;

    scroll-behavior: smooth;
    font-size: var(--font-size);

    //Retire le smooth scroll lors des transitions de page avec barba JS pour pas qu'onv oit la page scrolltop lentement pendant la transition.
    &.in-transition{
        scroll-behavior: auto;
    }

    //classe utilisé lors d'ouverture d'overlays pour éviter le scroll sur le body et dans l'overlay en même temps.
    &.no-scroll{
        overflow: hidden;
    }

    &.scroll-padding{
        scroll-padding-top: 215px;
    }

    @media only screen and (max-width: $md-max) {
        --column-amount: 16;
        --gutter-width: #{$gutter-width-md};
    }

    @media only screen and (max-width: $sm-max) {
        --column-amount: 16;
        --gutter-width: #{$gutter-width-sm};
        scroll-padding-top: 145px;
    }

    @media only screen and (max-width: $xs-max) {
        --column-amount: 16;
        --gutter-width: #{$gutter-width-xs};
        &.scroll-padding{
            scroll-padding-top: 200px;
        }
    }
}

//used to force a light with black text section
*.light{
    background-color: $white;
    color: $cinder;
}

//used to force a dark with white text section
*.dark{
    background-color: $cinder-bg;
    color: $white;
}

//default page setup are black with white text. Can be reversed with 'light' class on body (per page setup)
body {
    --text-color: #{$white};
    --background-color: #{$cinder-bg};

    overscroll-behavior-y: none;
    overscroll-behavior-x: none;


    &.light{
        --text-color: #{$cinder};
        --background-color: #{$white};
    }

    &.no-scroll{
        overflow: hidden;
    }

    #site-ctn{
        background-color: map-get($colors-base, background);
        color: map-get($colors-base, text);
        margin-bottom: var(--footerHeight);
    }

    @include text-m();

    .disabled-product{
        opacity: 0.2;
        pointer-events: none;
    }

    @media only screen and (max-width: $sm-max) {
        #site-ctn{
            margin-bottom: 0;
        }
    }
}

body.preload *{
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
}

#site-ctn{
    position: relative;
    width: 100%;
    z-index:2;
    overflow: clip;
    border-radius: 0 0 50px 50px;
    //only to trigger the last margin of the inner component
    padding-bottom:0.1px;

    >*:last-child{
        &[class*="pagebuilder-component"]{
            border-radius: 0 0 50px 50px;
            overflow: hidden;
        }

        &:not(.overlay){
            border-radius: 0 0 50px 50px;
            position: relative;
            top: 1px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        border-radius: 0 0 30px 30px;

        >*:last-child{
            &[class*="pagebuilder-component"]{
                border-radius: 0 0 30px 30px;
            }
            &:not(.overlay){
                border-radius: 0 0 30px 30px;
                position: relative;
                top: 1px;
            }
            border-radius: 0 0 30px 30px;
        }
    }
}

#page-transition-element {
    position: fixed;
    top: 100vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #{$fire-engine-red};
    z-index: 2;
}

//
// Heading styles
// -----------------------------------------------------------------------------

h1, .like-h1, .like-h4.like-h1 {
    @include shentox-bold();
    font-size: rem(95);
    line-height: rem(95);
    text-transform: uppercase;
    margin:0 0 20px;

    &.bigger{
        font-size: 9vw;
        line-height: 9vw;
    }

    @media only screen and (max-width: $sm-max) {
        font-size: rem(45);
        line-height: rem(45);
    }
}

h2, .like-h2 {
    @include shentox-bold();
    font-size: rem(70);
    line-height: rem(70);
    text-transform: uppercase;
    margin:0 0 20px;

    @media only screen and (max-width: $sm-max) {
        font-size: rem(34);
        line-height: rem(37.4);
    }
}

h3, .like-h3 {
    @include shentox-bold();
    font-size: rem(50);
    line-height: rem(54);
    text-transform: uppercase;
    margin:0 0 20px;

    @media only screen and (max-width: $sm-max) {
        font-size: rem(34);
        line-height: rem(37.4);
    }
}

h4, .like-h4 {
    @include shentox-bold();
    font-size: rem(28);
    line-height: rem(28);
    text-transform: uppercase;
    margin:0 0 20px;

    @media only screen and (max-width: $sm-max) {
        font-size: rem(24);
        line-height: rem(24);
    }
}

//@note Design didnt plan a visually distinction between h5 and h6
h5, .like-h5,
h6, .like-h6 {
    @include ciutadella-semibold();
    font-size: rem(22);
    line-height: rem(26);
    text-transform: uppercase;
    margin:0 0 20px;

    @media only screen and (max-width: $sm-max) {
        font-size: rem(18);
        line-height: rem(26);
    }
}

.grecaptcha-badge{
    opacity:0;
    pointer-events: none;
}

//
// Animation
// -----------------------------------------------------------------------------
[data-aos="custom-fade-in"] {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
    transition-property: transform, opacity;
    transition-duration: 1s;

    &.aos-animate {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
}

[data-aos="custom-menu-animation"] {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
    transition-property: transform, opacity;
    transition-duration: 1s;

    &.aos-animate {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
}

//
// Text styles
// -----------------------------------------------------------------------------

p {}

//
// Link styles
// -----------------------------------------------------------------------------

.transparent, .dark{
    a{
        color: $white;

        &:hover, &:focus, &:active, &:visited {
            &:not(.primary-cta):not(.secondary-cta):not(.tertiary-cta.alt, a.inverse){
                color: $white;
            }
        }
    }
}



.light{
    a{
        color: $cinder;

        &:hover, &:focus, &:active, &:visited {
            &:not(.primary-cta):not(.return-cta, .tertiary-cta.alt, .underline-cta){
                color: $cinder;
            }
        }
    }
    footer {
        a{
            color: $white;

            &:hover, &:focus, &:active, &:visited {
                &:not(.primary-cta):not(.return-cta){
                    color: $white;
                }
            }
        }
    }
}


a {
    color: var(--text-color);

    &:hover{
        text-decoration: underline;
    }

    &:hover, &:focus, &:active, &:visited {
        &:not(.primary-cta){
            color: var(--text-color);
        }

    }
}

//
// List styles
// -----------------------------------------------------------------------------

ul {}

ol {}

li {}

//
// Form styles
// -----------------------------------------------------------------------------

input {}
