@import "../../../config";
@import "../../../mixins";
@import "../../../functions";

section.sports-tiles {
  .search-content {
    @include col-start(2);
    @include col(14);

    .like-h3 {
      margin-block: 100px 25px;
    }
  }

  @media only screen and (max-width: $sm-max) {
    .centered-content {
      width: 100%;
      left: unset;
      padding-right: unset;
    }
  }

  .tiles-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

    overflow: hidden;

    @include col-start(2);
    @include col(14);

    @media only screen and (max-width: $sm-max) {
      @include col-start(1);
      @include col(var(--column-amount));
      display: flex;
      gap: 20px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      padding-inline: 16px;
      padding-bottom: 10px;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $cinder-bg;
        border-radius: 10px;
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        height: 5px;
        background-color: transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px $cinder-bg;
        background-color: $white;
      }

      .tile {
        flex: 0 0 calc(50% - 22px);
        scroll-snap-align: center;
      }
    }

    @media only screen and (max-width: $xs-max) {
      gap: 10px;
      padding-bottom: 0;

      &::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;
      scrollbar-color: transparent transparent;
      -ms-overflow-style: none;

      .tile {
        flex: 0 0 calc(100% - 16px);
      }
    }
  }
}
