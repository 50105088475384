@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

.section-faq{
    margin: 150px 0 0;
    padding: 150px 0 100px;

    >.centered-content{
        align-items: center;
    }

    .centered-content{
        .section-faq-title{
            @include col(10);
            @include col-start(2);
            margin-bottom: 0;
        }

        .section-faq-description{
            @include col(4);
            @include col-start(12);
            @include text-m();

            p{
                margin: 0;
            }
        }

        a{
            text-decoration: underline;
        }
    }

    .additionnal-information-content{
        align-items: flex-start;

        .title{
            @include col(8);
            @include col-start(2);
            magin-top: 9px;
        }

        .content{
            @include col(6);
            @include col-start(10);
            @include text-m();

            p{
                margin-top: 0;
            }
        }

        .tertiary-cta{
            position: relative;

            .icon-blank{
                position: absolute;
                right: -10px;
                top: 50%;
                transform: translate(100%, -50%);
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        margin: 100px 0 0;
        padding: 100px 0;

        .centered-content{
            .section-faq-title{
                @include col(16);
                @include col-start(auto);
                margin-bottom: 20px;
            }

            .section-faq-description{
                @include col(16);
                @include col-start(auto);
            }
        }

        .additionnal-information-content{
            .title{
                @include col(16);
                @include col-start(auto);
                margin-bottom: 30px;
            }

            .content{
                @include col(16);
                @include col-start(auto);
            }
        }
    }
}
