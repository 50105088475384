@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
  &-text-description {

    .title {
      @include col(14);
      @include col-start(2);
    }

    .right-content {
      @include col(7);
      @include col-start(10);

      p {
        margin-bottom: 30px;
      }

      .icon-wrapper {
        width: 120px;
        object-fit: cover;
        line-height: 0;
      }

      span {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        svg {
          margin-left: 10px;
          margin-top: -4px;
        }
      }
    }

    @media only screen and (max-width: $sm-max) {

      .title{
        @include col-start(1);
        @include col(15);
      }

      .right-content {
        @include col-start(1);
        @include col(15);
        p{
          margin-bottom: 40px;
        }
        span{
          margin-bottom: 40px;
        }
      }
    }
  }

}