@import "../../config";
@import "../../mixins";
@import "../../functions";

.overlay.order {
    h5{
        button{
            font-size: rem(14);
            margin-left: 30px;
            display: inline-block;
            position: relative;
            top: -3px;
        }
    }

    .title{
        margin-top: 40px;
        @include text-l-semibold();
    }

    .close{
        svg{
            *{
                stroke: $cinder;
            }
        }
    }

    .wrapper{
        padding: 50px 40px;

        .order-buttons-wrapper{
            display:flex;
            gap: 13px;
            margin-top: 13px;
        }

        .order-button{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 90px;
            border-radius: 10px;
            background-color: map-get($colors-overlay, button-order);
            transition: background-color 0.4s ease;

            &:hover{
                background-color: map-get($colors-overlay, button-order-small);
            }

            &.small{
                background-color: map-get($colors-overlay, button-order-small);

                &:hover{
                    background-color: map-get($colors-overlay, button-order);
                }

                svg, img{
                    max-width: 90%;
                    max-height: 60px;
                }
            }

            svg, img{
                margin:0 auto;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .wrapper {
            padding: 50px 20px 100px;
        }
    }

    @media only screen and (max-width: 450px) {
        .wrapper {
            .order-buttons-wrapper {

                .order-button{
                    min-height: 70px;

                    &.small{
                        img{
                            max-width: 90%;
                        }
                    }
                }
            }
        }
    }
}
