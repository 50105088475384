//
// COLORS
// Use http://www.color-blindness.com/color-name-hue/ for color naming
// -----------------------------------------------------------------------------

$white: #fff;
$white-smoke: #F5F5F5;
$gainsboro: #e4e4e4;
$gainsboro-25percent: #f8f8f8;
$grey-de: #dedede;
$raven: #6a717e;
$big-stone: #333f48;
$grey: #242c32;
$cinder: #21262a;
$cinder-bg: #1a1f22;
$dark-blue: #00253A;
$bright-blue: #003B5C;
$fire-engine-red: #da291c;
$sangria: #9d1d06;

//
// MAP-GET() ARRAYS
// -----------------------------------------------------------------------------

$colors-base: (
        text: var(--text-color),
        background: var(--background-color),
        light-text: $white,
        dark-text: $cinder,
        colored-bg: $sangria,
        colored-bg-dark: $cinder-bg,
);

$colors-overlay: (
        background: $white,
        backdrop: $cinder,
        border: $gainsboro,
        button-order: $fire-engine-red,
        button-order-small: $sangria,
        hightlighted-background: $white-smoke
);

$colors-notification: (
        background: $fire-engine-red
);

$colors-header: (
        border: $gainsboro
);

$colors-mobile-menu: (
        background: $fire-engine-red,
        border: rgba(255, 255, 255, 0.25),
        light: $white
);

$colors-ui: (
        tag-background: $fire-engine-red,
        tag-background-hover: $sangria,
        tag-background-invert: $white,
        tag-text-invert: $cinder,
        border-dark: $raven
);

$colors-legal: (
        table-content: $gainsboro,
        links: $fire-engine-red
);

$colors-ui-buttons: (
        primary-bg: $fire-engine-red,
        primary-hover-bg: $sangria,
        primary-active-bg: $cinder,
        primary-color: $white,
        border: $white,
        border-alt: $cinder,
        secondary-bg: $cinder,
        secondary-bg-alt: $white,
        secondary-hover-bg: $white,
        secondary-hover-bg-alt: $gainsboro,
        secondary-active-bg: $gainsboro,
        secondary-active-bg-alt: $gainsboro,
        secondary-color: $white,
        secondary-color-alt: $cinder,
        secondary-hover-color: $cinder,
        tertiary-color: $white,
        tertiary-color-white-bg: $cinder,
        tertiary-color-alt: $fire-engine-red,
        tertiary-hover-color: $gainsboro,
        tertiary-hover-color-white-bg: $raven,
        tertiary-hover-color-alt: $sangria,
        tertiary-active-color: $gainsboro,
        tertiary-active-color-white-bg: $raven,
        tertiary-active-color-alt: $sangria,
        arrows: $white,
        arrows-hover: $cinder,
        arrows-hover-bg: $white,
        arrows-active-bg: $gainsboro,
        arrows-disabled: $raven
);

$colors-form: (
        background: $cinder-bg,
        background-hover: $white,
        label: $raven,
        label-active: $white,
        label-hover: $raven,
        border: $raven,
        border-active: $white,
        border-hover: $white,
        error: $fire-engine-red,
        loading: $gainsboro,
        loading-spin: $fire-engine-red,
);

$colors-light-form: (
        label-active: $cinder,
        border-active: $cinder,
);

$colors-giftcard-promotion: (
        background: $sangria
);

$colors-giftcard-creation: (
        border: $gainsboro,
        border-active: $dark-blue
);

$colors-giftcard-check-balance: (
        balance: $fire-engine-red
);

$colors-giftcard-payment: (
        promo-text: $sangria
);

$colors-promo-list: (
        membership-background: $fire-engine-red
);

$colors-nutrition-values: (
        border: $gainsboro
);

$colors-restaurant-menu: (
        dark: $cinder,
        dark-bg: $cinder-bg,
        light: $white,
        border-light: $gainsboro,
        border-dark: $raven,
);

$colors-reservation: (
        ui: $cinder
);

$colors-restaurant: (
        border: $gainsboro,
        border-active: $fire-engine-red,
        text: $cinder-bg
);

$colors-menu-item: (
        border: $gainsboro
);

$colors-restaurant-selector: (
        border: $cinder,
        border-light: $raven,
        border-white: $white,
        autocomplete-background: $white,
        autocomplete-background-hover: $gainsboro-25percent
);

$colors-sports-selector: (
        bg-hover: $fire-engine-red,
        bg-active: $sangria,
        border: $white
);

$colors-membership: (
        background: $cinder
);

$colors-scrolling-container: (
        border: $gainsboro
);

$colors-footer: (
        border: $white,
        background: $fire-engine-red,
        lines: $sangria
);

$colors-infos-rows: (
        border: $raven,
);

$colors-title-separator: (
        border-light: $white,
        border-dark: $cinder,
);

$colors-infos-column: (
        border: $white
);

$colors-accordion: (
        border: $cinder,
        border-light: $white
);

$color-tables: (
        border: $grey-de,
        heading: $cinder-bg,
        heading-text: $white
);

$color-banner-image: (
        background: $fire-engine-red
);

$color-product: (
        image-backgroud: $grey
);

$colors-cart: (
        card-bg: $gainsboro-25percent
);

$colors-tiles: (
        red-bg: $sangria,
        blue-bg: $dark-blue,
        blue-icon-hover: $bright-blue,
        gray-bg: $big-stone,
        gray-icon-hover: $raven
);