@import "../../config";
@import "../../mixins";
@import "../../functions";

.overlay.reservation{

    .close{
        svg{
            *{
                stroke: map-get($colors-reservation, ui);
            }
        }
    }

    .content{
        min-height: 100vh;
        height: 100vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .title-wrapper{
        margin: 20px 0 30px;
        display:flex;
        align-items: center;
        gap: 20px;

        h4{
            margin:0;
        }

        .logo{
            width: 70px;
            height: 70px;

            span{
                width: 100%;
            }

            svg{
                *{
                    fill: map-get($colors-reservation, ui);
                }
            }
        }
    }

    .iframe-wrapper{
        height: 100%;
        flex-grow:1;

        iframe{
            border:none;
        }
    }

    @media only screen and (max-width: $sm-max) {

    }

    @media only screen and (max-width: $xs-max) {

    }
}
