@import "../../config";
@import "../../mixins";
@import "../../functions";

.restaurant-listing{
    overflow: hidden;
}
.restaurant-selector-wrapper {
    @include grid(16, var(--gutter-width));


    &.in-popup {
        @include grid(6, var(--gutter-width));
        //@include grid(4, var(--gutter-width));

        .restaurant-selector {
            //@include grid(4, var(--gutter-width));
            //@include col(4);
            //@include col-start(1);

            @include grid(6, var(--gutter-width));
            @include col(7);
            @include col-start(1);

            .restaurant-list {
                @include col(7);

                .restaurant {
                    @include grid(6, var(--gutter-width));

                    //@include grid(4, var(--gutter-width));
                    flex-direction: column;
                    margin-bottom: 10px;
                    padding-bottom: 20px;
                    padding-top: 20px;

                    > *:nth-child(1) {
                        @include col(1);

                        .icon-arrow {
                            //width: 32px;
                            //height: 32px;
                            width: 100%;
                            //transition: margin-left 250ms ease;
                            max-width: 52px;
                            @media only screen and (max-width: $xs-max){
                                max-width: 38px;
                            }
                        }
                    }

                    > *:nth-child(2) {
                        @include col(5);
                        @include col-start(2);

                        //@include col(3);
                        //@include col-start(2);

                        .restaurant-title {
                            margin-bottom: 3px;
                        }

                        p.address {
                            position: relative;
                            padding-right: 70px;
                        }

                        .distance {
                            position: absolute;
                            top: 0;
                            right: 15px;

                            display: block;
                        }
                    }

                    > *:nth-child(3) {
                        //@include col(3);
                        //@include col-start(2);
                        @include col(5);
                        @include col-start(2);
                        margin-top: -4px;

                        span {
                            @include text-s();

                            span {
                                margin-right: 5px;
                                position: relative;
                                top: 2px;
                            }
                        }
                    }

                    > *:nth-child(4) {
                        display: none;
                    }

                    > *:nth-child(5) {
                        margin-top: 15px;
                        //@include col(3);
                        //@include col-start(2);
                        @include col(5);
                        @include col-start(2);
                        justify-content: flex-start;
                    }

                    .restaurant-separator{
                        width: 100%;
                        left: unset;
                    }
                }
            }

            .search {
                //@include col(4);
                //@include col-start(1);
                @include col(7);
                @include col-start(1);
            }
        }
    }
}

.restaurant-selector, {
    @include grid(14, var(--gutter-width));
    @include col(14);
    @include col-start(2);
    margin-bottom: 100px;
    min-height: 100vh;

    &.show-autocomplete {
        .search {
            .autocompleteResults {
                display: block;
            }
        }
    }

    .search {
        @include col(14);
        @include col-start(1);

        input {
            @include ciutadella-regular();
            border-bottom: 1px solid map-get($colors-restaurant-selector, border-light);
            padding: 14px 1px;
            color: map-get($colors-base, dark-text);
        }

        .autocompleteResults {
            display: none;
            width: 100%;
            position: absolute;
            top: 100%;
            background: map-get($colors-restaurant-selector, autocomplete-background);
            border: 1px solid map-get($colors-restaurant-selector, autocomplete-background-hover);
            margin: 0;
            list-style: none;
            box-sizing: border-box;

            li {
                padding: 5px 30px 3px;

                &:hover {
                    cursor: pointer;
                    background: map-get($colors-restaurant-selector, autocomplete-background-hover);
                }
            }
        }
    }

    .error-message {
        text-align: center;
    }

    .error-php-message {
        text-align: center;
    }

    .load-more {
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;

        .btn-reveal {
            left: -20px;

            span {
                top: 33%;
            }

            svg {
                * {
                    stroke: map_get($colors-restaurant-selector, border);
                }
            }
        }
    }

    .user-location {
        margin-top: 35px;

        .search-by-location {
            @include text-l-semibold();
            margin-bottom: 15px;
        }

        .search-field {
            position: relative;

            input {
                padding-right: 40px;
            }

            .icon-search {
                position: absolute;
                cursor: pointer;
                top: 50%;
                right: 0px;
                margin: 0;
                padding: 10px;
                transform: translateY(-50%);

                &:hover {
                    svg {
                        transform: rotate(3deg) scale(1.3);
                    }
                }

                svg {
                    transition: all 0.2s ease;
                }
            }
        }

        span {
            display: block;
            margin: 10px auto;
            text-align: center;
            @include text-m-semibold();
        }

        input, button {
            width: 100%;
            box-sizing: border-box;
        }

        button {
            text-align: center;
            display: inline;
        }
    }

    .restaurant-list {
        .current{
            margin-bottom: 60px;
        }

        .restaurant {
            @include grid(15, var(--gutter-width));
            position: relative;
            margin-bottom: 20px;
            padding: 40px 0 30px;
            flex-wrap: nowrap;
            align-items: center;

            &:hover {
                .tertiary-cta {
                    &:before {
                        background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                        animation: grow-line 0.3s 0.26s ease-out forwards;
                    }

                    &:after {
                        background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
                        animation: shrink-line 0.3s 0s ease-in forwards;
                    }
                }

                .icon-arrow {
                    margin-left: 10px;
                    transition: margin-left ease 250ms;
                }
            }

            .restaurant-overlay {
                position: absolute;
                inset: 0;
                z-index: 2;
                margin-top: 20px;
            }

            .restaurant-separator {
                height: 0;
                border-bottom: 1px solid map_get($colors-restaurant-selector, border);
                width: 150vw;
                position: absolute;
                left: -25vw;
                bottom: 0;
            }

            .icon-arrow {
                width: 45px;
                height: 45px;
                transition: margin-left ease 250ms;

                svg {
                    * {
                        stroke: black;
                    }
                }
            }

            p {
                margin: 0;
            }

            > *:nth-child(1) {
                @include col(1);

            }

            > *:nth-child(2) {
                @include col(3);
                @include col-start(3);

                display: flex;
                flex-direction: column;

                .distance {
                    display: none;
                }
            }

            > *:nth-child(3) {
                @include col(5);
                @include col-start(6);
                align-self: flex-end;
                white-space: nowrap;

                span {
                    margin-right: 10px;
                    display: inline-block;

                    svg {
                        margin-right: 8px;
                    }
                }
            }

            > *:nth-child(4) {
                @include col(2);
                @include col-start(11);
                align-self: flex-end;
            }

            > *:nth-child(5) {
                @include col(3);
                @include col-start(13);
                justify-content: flex-end;
                align-self: flex-end;
                display: flex;

                margin-bottom: 8px;
            }

            .restaurant-title {
                @include text-xl-semibold();
            }
        }

    }

    @media only screen and (max-width: $sm-max) {
        @include grid(16, var(--gutter-width));
        @include col(16);
        @include col-start(1);


        .restaurant-list {
            overflow: hidden;
            .restaurant {
                @include grid(16, var(--gutter-width));
                flex-direction: column;
                margin-bottom: 10px;
                padding-bottom: 20px;
                padding-top: 20px;

                .restaurant-overlay {
                    margin-top: -10px;
                }

                > *:nth-child(1) {
                    @include col(2);

                    .icon-arrow {
                        width: 32px;
                        height: 32px;
                    }
                }

                > *:nth-child(2) {
                    @include col(13);
                    @include col-start(4);

                    .restaurant-title {
                        margin-bottom: 3px;
                    }

                    p.address {
                        position: relative;
                        padding-right: 70px;
                    }

                    .distance {
                        position: absolute;
                        top: 0;
                        right: 15px;

                        display: block;
                    }
                }

                > *:nth-child(3) {
                    @include col(13);
                    @include col-start(4);
                    margin-top: -4px;
                    white-space: unset;

                    span {
                        @include text-s();

                        span {
                            margin-right: 5px;
                            position: relative;
                            top: 2px;
                        }
                    }
                }

                > *:nth-child(4) {
                    display: none;
                }

                > *:nth-child(5) {
                    margin-top: 25px;
                    @include col(13);
                    @include col-start(4);
                }
            }
        }

        .search {
            @include col(16);
            @include col-start(1);
        }
    }

    @media only screen and (max-width: $xs-max) {
        .restaurant-list {

            .restaurant {
                > *:nth-child(3) {
                    span{
                        margin-right: 4px;
                        span{
                            top: 1px;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
