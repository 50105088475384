@import "../../config";
@import "../../mixins";
@import "../../functions";

#search {
  margin-bottom: 70px;

  .sports-selector {
    @include col-start(1);
    min-height: unset;
    margin-bottom: unset;

    .search {
      @include col(6);
      @media only screen and (max-width: $sm-max) {
        @include col(var(--column-amount));
      }


        .user-location {
        .search-by-location {
          @include text-l();
          font-weight: 600;
          margin-bottom: 0;
        }

        .search-field {
          position: relative;

          &:hover {
            svg {
              transform: rotate(3deg) scale(1.3);
            }
          }

          input {
            padding-left: 0;
            padding-right: 40px;
            padding-block: 8px;
            color: var(--text-color);
            @include text-m();
            line-height: 1;
          }

          .icon-search {
            position: absolute;
            cursor: pointer;
            top: 50%;
            right: 0px;
            margin: 0;

            transform: translateY(-50%);

            padding: 10px 0 10px 10px;

            &:hover {
              svg {
                transform: rotate(3deg) scale(1.3);
              }
            }

            svg {
              transition: all 0.2s ease;

              path {
                stroke: currentColor;
              }

            }
          }
        }
      }

    }
  }

  .like-h3 {
    margin-block: 100px 0;
  }
}


.sports-selector {
  @include grid(14, var(--gutter-width));
  @include col(14);
  @include col-start(2);

  @media only screen and (max-width: $sm-max) {
    @include grid(var(--column-amount), var(--gutter-width));
    @include col(var(--column-amount));
    @include col-start(1);

  }

  .sports-list {
    &:not(.no-margin) {
      margin-top: 30px;
    }

    .sport {
      @include grid(15, var(--gutter-width));
      position: relative;
      margin-top: -1px; /* to hide the border when hovering */
      padding: 30px 0;
      flex-wrap: nowrap;
      align-items: flex-start;
      pointer-events: auto;
      cursor: pointer;
      transition: all ease 250ms;
      overflow-y: clip;

      // Exclude animation for Safari by checking for -webkit-backdrop-filter support
        @supports (-webkit-appearance: none) and (stroke-color: transparent) and (-webkit-backdrop-filter: none) {
          &[data-aos="custom-fade-in"] {
            opacity: 1 !important;
            transform: none !important;
            transition: none !important;
            &.aos-animate{
              transform: unset;
            }
          }
        }


      > * {
        pointer-events: none;
      }

      &.active {
        &:before {
          background-color: map-get($colors-sports-selector, bg-active);
          transition: background-color ease 250ms;
        }

        .sport-separator {
          border-bottom-color: map-get($colors-sports-selector, bg-active);
          transition: border-bottom-color ease 250ms;
        }
      }

      &:before {
        width: 150vw;
        position: absolute;
        left: -25vw;
        bottom: 0;
        height: 100%;
        background-color: transparent;
        z-index: -1;
        content: '';
        transition: background-color ease 250ms;
      }


      &:hover {
        transition: all ease 250ms;


        .tertiary-cta {
          color: map-get($colors-ui-buttons, tertiary-color);

          &:before {
            background-color: map-get($colors-ui-buttons, tertiary-color);
            animation: grow-line 0.3s 0.26s ease-out forwards;
          }

          &:after {
            background-color: map-get($colors-ui-buttons, tertiary-color);
            animation: shrink-line 0.3s 0s ease-in forwards;
          }

          .sport-logo {
            opacity: 1;
          }
        }

        .icon-arrow {
          position: relative;
          translate: 10px 0;
          transition: translate ease 250ms;
          @media only screen and (max-width: $xs-max) {
            translate: unset;
          }
        }

        &.active {
          > div {
            z-index: 2;
          }

          .sport-logo {
            z-index: 1;
          }

          &:before {
            background-color: map_get($colors-sports-selector, bg-hover);
            transition: background-color ease 250ms;
          }
        }

        &:before {
          background-color: map_get($colors-sports-selector, bg-hover);
          transition: background-color ease 250ms;
        }

        .sport-logo {
          opacity: 1;
          transition: all ease 250ms;
          transform: translate(0px, 0px);
        }

        .sport-separator {
          border-bottom-color: map_get($colors-sports-selector, bg-hover);
          transition: border-bottom ease 250ms;
        }
      }

      .sport-overlay {
        position: absolute;
        inset: 0;
        opacity: 1;
        z-index: 2;
        pointer-events: auto;
      }

      .sport-logo {
        opacity: 0;
        position: absolute;
        z-index: -1;
        right: 0;
        width: 37%;
        margin-left: auto;
        transition: all ease 250ms;
        top: 10px;


        scale: 0.67;
        transform: translate(-50px, 30px);
        transform-origin: top;

        .svg {
          background-color: map-get($colors-ui-buttons, tertiary-hover-color-alt);
        }
        @media only screen and (max-width: $sm-max){
          width: 43%;
          scale: 0.56;
        }

        @media only screen and (max-width: $xs-max){
          top: 60px;
          scale: 0.75;
          right: 96px;
          width: 32%;
        }
      }

      .sport-separator {
        height: 0;
        border-bottom: 1px solid map_get($colors-sports-selector, border);
        width: 150vw;
        position: absolute;
        left: -25vw;
        bottom: 0;
        z-index: -2;
        transition: border-bottom ease 250ms;
      }

      .icon-arrow {
        width: 45px;
        height: 45px;
        transition: translate ease 250ms;

        svg {
          * {
            stroke: currentColor;
          }
        }
      }

      p {
        margin: 0;
      }

      @media only screen and (max-width: $sm-max) {
        @include grid(var(--column-amount), var(--gutter-width));
      }
      @media only screen and (max-width: $xs-max) {
      }

      > *:nth-child(1) {
        @include col-start(1);
        @include col(1);
        @media only screen and (max-width: $sm-max) {
          @include col(2);
          @include col-start(1);
        }
        @media only screen and (max-width: $xs-max) {
          @include col(3);
          @include col-start(1);
        }
      }

      > *:nth-child(2) {
        @include col(3);
        @include col-start(3);

        display: flex;
        flex-direction: column;

        span.date {
          @include text-xl-semibold();
        }
        @media only screen and (max-width: $sm-max) {
          @include col(4);
          @include col-start(3);
        }

        @media only screen and (max-width: $xs-max) {
          @include col(12);
          @include col-start(4);
        }

      }

      > *:nth-child(3) {
        @include col(7);
        @include col-start(6);

        span {
          margin-right: 10px;
          display: inline-block;

          svg {
            margin-right: 8px;
          }
        }

        .limitations {
          opacity: 0.75;
          font-style: italic;


        }
        @media only screen and (max-width: $sm-max) {
          @include col(6);
          @include col-start(7);

          .limitations{
            text-wrap: balance;
            line-height: 1.2;
          }
        }

        @media only screen and (max-width: $xs-max) {
          @include col(12);
          @include col-start(4);
          white-space: unset;
          .limitations{
            margin-top: 10px;
          }
        }
      }

      > *:nth-child(4) {
        @include col(2);
        @include col-start(14);
        justify-content: flex-end;
        align-self: flex-start;
        display: flex;

        margin-top: 2px;

        .tertiary-cta {
          white-space: nowrap;
        }

        @media only screen and (max-width: $sm-max) {
          @include col-start(15);
        }
          @media only screen and (max-width: $xs-max) {
          @include col(12);
          @include col-start(4);
          margin-top: 30px;
          justify-content: flex-start;
        }
      }

      .sport-title {
        @include text-xl-semibold();
      }

    }

  }

  .see-more-events{
    margin-top: 50px;
    justify-self: flex-end;
  }
}
