@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.block-cta-hero{
    position: relative;
    height:1px;

    .wrapper{
        @include col(5);
        @include col-start(10);
        position: absolute;
        top: 50px;
        background:map-get($colors-promo-list, membership-background);
        border-radius: 0 0 30px 0;
        padding: 40px;
        z-index: 5;
    }


    .title{
        margin-top:0;
        @include text-xl-semibold();

        br{
            display:none;
        }
    }

    .description{
        margin-bottom: 25px;
    }

    .buttons{
        display:flex;
        gap: 10px;
        a{
            max-height: 44px;
            height: auto;
            max-width:50%;
        }
    }

    @media only screen and (max-width: $xs-max) {
        height: auto;
        margin-bottom: -60px;

        .wrapper {
            position:relative;
            right:0;
            top: -30px;
            border-radius: 0 0 0 30px;
            padding: 50px 30px;
            margin-right: -16px;
            @include col(15);
            @include col-start(2);
        }
    }
}
