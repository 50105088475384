@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.accordions{
  margin: 70px 0;

  @media only screen and (max-width: $xs-max) {
    margin: 40px 0;
  }
}