@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

// this rule is for the on-site menu
.onsite{
    .menu-type{
        padding-bottom: 250px;
        @media only screen and (max-width: $sm-max){
            padding-bottom: 150px !important;
        }
    }
}

.menu-type {
    padding: 95px 0 150px;

    &.with-legal{
        padding-bottom:0;
    }

    .return-to-menu {
        position: relative;
        padding-block: 30px;

        .return-cta {

            &:hover {
                svg {
                    path {
                        stroke: map-get($colors-ui-buttons, primary-hover-bg);
                        transition: stroke ease 250ms;
                    }
                }
            }

            svg {
                position: relative;
                top: 1.5px;
                margin-right: 8px;

                path {
                    stroke: map-get($colors-ui-buttons, primary-bg);
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100vw;
            background: map-get($colors-restaurant-menu, border-light);
            height: 1px;
            left: calc(-1 * var(--gutter-width) * 2);
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100vw;
            background: map-get($colors-restaurant-menu, border-light);;
            height: 1px;
            left: calc(-1 * var(--gutter-width) * 2);
        }
    }

    .title-ctn {
        margin-top: 60px;
        @include col-start(2);
        @include col(10);

        h2{
            margin:0;
        }
    }

    .menu-ctn {
        @include col-start(2);
        @include col(14);
        margin-top: 90px;
        @include grid(14, var(--gutter-width));

        aside {
            @include col-start(1);
            @include col(4);
            position: sticky;
            top: 220px;

            .icon {
                display: none;
            }

            ul {
                margin: 0;
                list-style: none;
                display: flex;
                flex-direction: column;


                li {
                    a {
                        color: var(--text-color);
                    }

                    &.active {
                        a {
                            @include ciutadella-semibold();
                        }
                    }
                }
            }
        }

        .menu-content {
            @include col-start(5);
            @include col(10);

            .banner-wrapper {
                margin-bottom: 40px;

                h3 {
                    margin-top: 25px;
                    margin-bottom: 15px;
                }

                .image-banner {
                    position: relative;
                    width: 100%;

                    &.mobile{
                        display:none;
                        height: auto;
                    }

                    .tag {
                        position: absolute;
                        z-index: 2;
                        top: 10px;
                        left: 10px;
                        color: map-get($colors-base, light-text);
                    }

                    img {
                        width: inherit;
                        height: inherit;
                        object-fit: cover;
                        border-bottom-right-radius: 20px;
                    }

                    @media only screen and (max-width: $xs-max) {
                        &.desktop{
                            display:none;
                        }
                        &.mobile{
                            display:block;
                        }
                    }
                }
            }

            .categories {
                .category{
                    margin-top: 100px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .item-wrapper {
                    display: flex;
                    position: relative;
                    padding-block: 40px;
                    @include grid(10, var(--gutter-width));

                    .grid-mobile-layout {
                        display: none;
                    }

                    &:first-child{
                        padding-top:0;

                        .tile-link{
                            top:0;
                        }
                        &:before{
                            display:none;
                        }
                    }
                    &:last-child{
                        padding-bottom:0;

                        .tile-link{
                            bottom:0;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba(106, 113, 126, 0.25);
                    }

                    &.item-disabled {
                        pointer-events: none;

                        .image-wrapper, .item-details {
                            opacity: 0.2;
                        }
                    }

                    .image-wrapper {
                        position: relative;
                        overflow: hidden;
                        border-bottom-right-radius: 20px;
                        @include col(4);

                        .tag {
                            position: absolute;
                            z-index: 2;
                            top: 10px;
                            left: 10px;
                            color: map-get($colors-base, light-text);
                            text-transform: capitalize;

                            &.popular{
                                width: 28px;
                                height: 28px;
                                padding:0;
                                display:flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        img {
                            display: block;
                            width: 100%;
                            aspect-ratio: 310/225;
                            object-fit: cover;
                            transition: transform 0.7s ease;
                        }
                    }

                    .item-details {
                        @include col(6);
                        @include col-start(5);
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        .item-title {
                            @include text-xl-semibold();
                            margin-bottom: 10px;
                            align-self: flex-start;
                        }

                        .description {
                            margin-block: 0 15px;
                            line-height: 1.2;

                            *:first-child{
                                margin-top:0;
                            }

                            *:last-child{
                                margin-bottom:0;
                            }

                            &.mobile{
                                display: none;
                            }

                        }

                        .item-tags {
                            display: flex;
                            gap: 5px;
                            margin-bottom: 20px;

                            .icon-wrapper {
                                width: 20px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img {
                                    object-fit: cover;
                                    width: inherit;
                                }
                            }
                        }
                    }

                    .see-product {
                        align-self: flex-end;
                        margin-top: auto;
                    }

                    .price-ctn {
                        display: flex;
                        flex-direction: column;

                        .price-row {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 3px;

                            span {
                                &:last-child {
                                    @include ciutadella-semibold();
                                }
                            }
                        }

                        .price {
                            margin-left: auto;
                            @include ciutadella-semibold();
                        }
                    }

                    .tile-link{
                        position: absolute;
                        top:40px;
                        left:0;
                        right:0;
                        bottom: 40px;
                    }

                    &:hover {
                        .image-wrapper img {
                            transform: scale(1.1);
                        }
                    }
                }

                .title-separator {
                    margin-bottom: 50px;
                }
            }
        }

    }
    .legal-wrapper{
        @include grid(16, var(--gutter-width));

        .legal{
            margin-top: 70px;
            @include col(14);
            @include col-start(2);

            p{
                margin: 0;
                font-size: rem(16);
            }
        }
    }
    @media only screen and (max-width: 1200px) {
        padding: 69px 0 100px;
    }
    @media only screen and (max-width: $sm-max) {

        .title-ctn {
            @include col-start(1);
            @include col(10);
        }
        .menu-ctn {
            margin-top: 25px;
            @include col-start(1);
            @include col(16);

            aside {
                @include col-start(1);
                @include col(16);
                top: 129px;
                background-color: white;
                padding-block: 20px;
                z-index: 5;
                margin-left: calc(-1 * var(--gutter-width) * 2);
                width: calc(100% + var(--gutter-width) * 4);
                margin-bottom: 0px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 100vw;
                    background: map-get($colors-restaurant-menu, border-light);
                    height: 1px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100vw;
                    background: map-get($colors-restaurant-menu, border-light);
                    height: 1px;
                }

                nav {
                    display: flex;
                    flex-wrap: nowrap;
                    position: relative;
                    transition: all ease 300ms;
                    //remove overflow-x because we could scroll a bit in the element vertically. Might cause some bug so leaving a trace if we need a fix.
                    overflow: hidden;
                    width: 100vw;
                    background: map-get($colors-base, background);


                    .icon {
                        position: relative;
                        display: flex;
                        flex: 0 0 fit-content;
                        background-color: map-get($colors-base, background);;
                        z-index: 2;
                        height: 25px;
                        padding-left: 15px;
                        cursor: pointer;


                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            box-shadow: 16px 0 5px white;
                            width: 20px;
                            height: 30px;
                            z-index: 1;
                        }

                        svg {
                            margin-top: 4px;
                        }
                    }

                    ul {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        //overflow-x: scroll;
                        -webkit-overflow-scrolling: touch;
                        -ms-overflow-style: -ms-autohiding-scrollbar;
                        scrollbar-width: none;
                        margin-left: 10px;
                        //transition: all ease 500ms;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        li {
                            position: relative;
                            margin-right: 15px;
                            &:first-child {
                                margin-left: 10px;
                            }
                            &:last-child{
                                margin-right: 5px;
                                padding-right: 20px;
                            }
                            a{
                                position: relative;
                                &:hover{
                                    text-decoration: none;
                                }
                            }

                            &.active{
                                a{
                                    &:after{
                                        content: '';
                                        position: absolute;
                                        bottom: -1px;
                                        left:0;
                                        width: 100%;
                                        height: 1px;
                                        background-color:  map-get($colors-base, colored-bg);
                                    }
                                }

                            }

                            white-space: nowrap;
                            flex: 0 0 auto;

                        }
                    }
                }
            }

            .menu-content {
                @include col-start(1);
                @include col(16);
                margin-top:  5px;

                .categories {

                    .title-separator{
                        margin-bottom: 30px;
                    }

                    .category{
                        margin-top: 0px;
                        padding-top: 50px;
                    }

                    .item-wrapper {
                        @include grid(16, var(--gutter-width));

                        .image-wrapper {
                            @include col-start(1);
                            @include col(6);
                            width: 100%;
                            min-width: 125px;

                            img {
                                aspect-ratio: 1/1;
                            }
                        }

                        .item-details {
                            @include col-start(8);
                            @include col(9);

                            .item-title {
                                @include text-l-semibold();
                            }


                        }

                    }
                }
            }
        }

        .legal-wrapper{

            .legal{
                @include col(16);
                @include col-start(1);
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .title-ctn {
            @include col(14);
        }

        .menu-ctn {
            .menu-content {
                .categories {

                    .item-wrapper {
                        padding-block: 20px;
                        .item-details {
                            .description {
                                display:none;

                                &.mobile{
                                    display: block;
                                }
                            }
                        }

                        .tile-link{
                            top:20px;
                            bottom: 20px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .menu-ctn {
            .menu-content {
                .categories {
                    .item-wrapper {
                        &.item-special-display{
                            .item-details {
                                .price-ctn {
                                    display: none;
                                }
                            }

                            .grid-mobile-layout {
                                display: flex;
                                flex-direction: column;
                                margin-top: 10px;
                                @include col-start(1);
                                @include col(16);
                            }
                        }
                    }
                }
            }
        }
    }
}
