@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.share-content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  //top: 20px;


  @media screen and (max-width: $sm-max) {
    position: unset;
    row-gap: 6px;
    top: unset;
    order: 0;
    margin-block: -20px 50px;
  }

  .share-buttons {
    position: relative;
    display: flex;
    order: 2;

    span {
      position: relative;
      display: grid;
      place-items: center;
      width: 36px !important;
      aspect-ratio: 1;
      height: auto;
      border-radius: 50%;
      border: 1px solid  map-get($colors-ui-buttons, secondary-bg);
      background-color: map-get($colors-ui-buttons, secondary-bg);
      transition: all 250ms $ease-in-out;


       svg{
        path{
          fill: map-get($colors-ui-buttons, secondary-hover-bg);
        }
      }

      &.icon-share {
        svg {
          width: 20px;
          path{
            fill: none;
            stroke: map-get($colors-ui-buttons, secondary-active-bg);
            stroke-width: 2px;
          }
        }
      }


      &:hover {
        background-color: map-get($colors-ui-buttons, secondary-active-bg);
        border-color: map-get($colors-ui-buttons, secondary-active-bg);
        transition: all 250ms $ease-in-out;

        svg{
          path{
            fill: map-get($colors-ui-buttons, secondary-bg);
          }
        }
        &.icon-share {
          svg {
            path{
              fill: none;
              stroke: map-get($colors-ui-buttons, secondary-bg);
            }
          }
        }
      }

    }

    .a2a_kit {
      column-gap: 8px;
      display: flex;
      @media screen and (max-width: $xs-max) {
        column-gap: 5px;

      }
    }

    .a2a_svg {
      svg {
        path {
          fill: map-get($colors-ui-buttons, secondary-bg);
        }
      }
    }

  }

}
