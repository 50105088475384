@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

header {
    position: fixed;
    top:0;
    width: 100%;
    //width: 100vw;
    z-index:99;
    padding: 23px 0;
    transition: background-color $defaultTransitionSpeed ease, border-bottom-color $defaultTransitionSpeed ease;

    border-bottom: 1px solid transparent;

    //disable most of the nav for menu on site via QR code.
    &.onsite{

        .main-nav{
            pointer-events: none;
            opacity:0;
        }

        .secondary-nav{
            li:not(:first-child){
                pointer-events: none;
                opacity:0;
            }
        }

    }

    a{
        color: $white;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transform: translateY(-100%);
        transition: transform 0.5s $ease-out-cubic, background-color 0.2s linear;
    }

    &.dark{
        border-bottom-color: transparent;
        background-color: transparent;

        &::before {
            background-color: $cinder-bg;
            transform: translateY(0);
        }

        *{
            color: $white;
            transition: all $defaultTransitionSpeed ease;
        }

        .logo {

            svg {
                *{
                    fill: $white;
                    transition: all $defaultTransitionSpeed ease;
                }
            }
        }

        .icon-burger{
            svg{
                *{
                    stroke: $white;
                }
            }
        }
    }

    &.light{
        background-color: transparent;
        *{
            color: $cinder;
            transition: all $defaultTransitionSpeed ease;
        }

        &::before {
            background-color: $white;
            transform: translateY(0);
            border-bottom: 1px solid map-get($colors-header, border);
        }

        .logo {

            &.special-logo{
                .for-light-background{
                    display: block;
                }

                .for-dark-background{
                    display:none;
                }
            }

            svg {
                *{
                    fill: $cinder;
                    transition: all $defaultTransitionSpeed ease;
                }
            }
        }

        .icon-burger{
            svg{
                *{
                    stroke: $cinder;
                }
            }
        }
    }

    &.transparent{
        background: rgba(0,0,0,0);
        border-bottom-color: transparent;

        .logo {
            &.special-logo{
                .for-light-background{
                    display: none;
                }

                .for-dark-background{
                    display:block;
                }
            }
            svg {
                *{
                    fill: $white;
                }
            }
        }
    }

    .logo {
        height: 50px;
        margin-right: 10px;

        &.crest{
            span.icon-logo-crest {
                width: 53px;
            }
        }

        &.special-logo{
            position: relative;
            width: 130px;
            top:-7px;
            margin-right: 0px;
        }

        svg {
            *{
                fill: var(--text-color);
            }
        }
    }

    ul{
        list-style: none;
        margin:0;

        li{
            @include shentox-bold();
            font-size: rem(14);
            line-height: 1;
            text-transform: uppercase;
            text-align: center;

            .boutique{
                position: relative;
                span.icon-external{
                    position: absolute;
                    right: -21px;
                    top:0px;
                    width: 15px;

                    @media only screen and (max-width: 1600px) {
                        top:0px;
                    }
                }
            }

        }
    }

    .centered-content{
        >div{
            display:flex;
            justify-content: space-between;
            align-items: center;
        }

        .main-nav{
            display:flex;
            gap: 30px;
            align-items: center;

            &.mobile{
                display:none;
            }
        }

        .secondary-nav{
            display:flex;
            gap: 14px;
            flex-direction: row-reverse;
            align-items: center;
        }

        .mobile-menu{
            display:none;
        }
    }
    @media only screen and (min-width: 1440px) {
        ul{
            li{
                font-size:1vw;
            }
        }
    }


    @media only screen and (max-width: 1200px) {
        padding: 8px 0;

        &:not(.onsite) {
            .logo {
                width: 50px;

                svg{
                    path{
                        display: none;

                        &:nth-child(1), &:nth-child(2){
                            display: block;
                        }
                    }
                }

                &.special-logo {
                    top: 2px;
                }
            }
        }

        &.onsite{
            .logo{
                width: 50px;

                svg{
                    path{
                        display: none;

                        &:nth-child(1), &:nth-child(2){
                            display: block;
                        }
                    }
                }
            }

            .centered-content{
                .secondary-nav{
                    display:block;
                }

                .mobile-menu{
                    display:none;
                }
            }
        }

        .logo{
            height: 54px;
            width: 54px !important;

            &.special-logo{
                top:1px;
                height: 55px;
                width: 101px!important;
            }
        }



        .centered-content{
            .main-nav{
                display:none;

                &.mobile{
                    display:flex;
                    gap: 20px;
                }
            }

            .secondary-nav{
                display:none;
            }

            .desktop-only{
                display:none;
            }

            .mobile-menu{
                display:flex;
                align-items: center;
                padding: 0;

                .icon-burger:hover{
                    svg{
                        *{
                            transition: stroke-width 0.3s ease;
                            stroke-width: 2px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content{
            .main-nav{
                &.mobile{
                    gap: 10px;

                    li{
                        a{
                            letter-spacing: 0.4px;
                        }
                    }
                }
            }
        }
    }
}
