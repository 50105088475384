@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;


.blog-tile {
  flex-direction: column;

  .tile-inner {
    padding: 0;
    border: 1px solid map-get($colors-ui, border-dark);

    .tags-container{
      display: flex;
      column-gap: 5px;
      position: absolute;
      z-index: 2;
      top: 20px;
      left: 20px;
    }

    .tag{
      position: relative;
      top: unset;
      left: unset;
      color: map-get($colors-ui, tag-text-invert);
      background-color: map-get($colors-ui, tag-background-invert);
    }
    .image-wrapper {
      aspect-ratio: 366/310;

      img{
        object-position: unset;

      }

      @media only screen and (max-width: $xs-max){
        aspect-ratio: 341/260;
      }
    }
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    p {
      margin: 0;
      line-height: 1.2;
    }

    .title{
      @include text-xl-semibold();
    }

    .date{
      @include text-xs();
    }
  }

}