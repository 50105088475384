@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;


// GLOBAL PAYMENT INPUTS

#card-number, #card-holder-name, #card-cvv, #card-expiration, #cvv-expiry-error{
  iframe{
    height: 65px !important;
    @media screen and (max-width: $xs-max){
      height: 85px !important;
    }
  }
}

// EN


.light-form{
  input:not([type='submit']), textarea {
    color: map_get($colors-base, dark-text);

    &:hover, &:focus {
      border-color: map-get($colors-base, dark-text);
      color: map-get($colors-base, dark-text);
    }
  }
}

.input-wrapper {
  position: relative;

  label {
    cursor: text;
  }
  iframe{
    width: 100%;
  }
}

input:not([type='submit']), textarea {
  border-radius: 0;
  width: 100%;
  @include ciutadella-semibold();
  font-size: rem(18);
  line-height: rem(30.6);
  color: map_get($colors-form, label-active);
  box-sizing: border-box;

  &:hover, &:focus {
    border-color: map-get($colors-form, border-hover);
    color: currentColor;
  }

  &.error {
    border-color: map-get($colors-form, error);

    & ~ .error-msg {
      display: block;
      color: map-get($colors-form, error);
    }
  }
}

textarea {
  display: block;
  padding: 14px 23px 14px 16px;
  border: 1px solid map-get($colors-form, border);
  background: transparent;
  min-height: 166px;
  max-width: 100%;
  transition: border 200ms ease-in-out;

  &:focus, &.has-value {
    & + label {
      opacity: 0;
    }
  }

  & + label {
    top: 15px;
    left: 17px;
    transform: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=checkbox]{
  display:none;

  &:checked{
    + label{
      &:after{
        width: 14px;
        height: 14px;
        left: 3px;
        top: 3px;
      }
    }
  }

  + label{
    color: map-get($colors-base, text);
    cursor: pointer;
    position: relative;
    padding-left: 28px;

    &:before{
      position: absolute;
      top:0;
      left:0;
      content: ' ';
      display: block;
      width: 18px;
      height: 18px;
      border:1px solid black;
    }

    &:after{
      position: absolute;
      top:9px;
      left:9px;
      content: ' ';
      display: block;
      width: 0px;
      height: 0px;
      background-color: black;

      transition: all 0.1s ease-out;
    }
  }

}

input[type=text], input[type=email], input[type=phone], input[type=password], input[type=number]{
  border: 0 solid #6a717e;
  border-bottom-width: 1px;
  background: transparent;
  transition: border 200ms ease-in-out;

  &:focus, &.has-value {
    & + label {
      top: -10px;
      font-size: rem(14);
      line-height: rem(16.8);
      color: map_get($colors-form, label-active);
    }
  }

  & + label {
    padding: 0;
  }

}

@media only screen and (max-width: $xs-max) {
  input:not([type='submit']), textarea {
    font-size: rem(16);
    line-height: rem(25.6);
  }

  textarea {
    padding: 10px 16px;

    & + label {
      top: 11px;
    }
  }

  input[type=text], input[type=email], input[type=phone], input[type=password] {
    &:focus, &.has-value {
      & + label {
        top: -10px;
        line-height: rem(16);
      }
    }
  }
}