@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-infos-columns {
  --column-list: var(--column-amount);

  .small-grid{
    --column-list: 14;
    @include col-start(2);
  }

  .sub-grid{
    @include col(var(--column-list));
    @include grid(var(--column-list), var(--gutter-width));
  }

  h2 {
    @include col(var(--column-list));
    @include text-xl-semibold();
    text-transform: none;
    margin-bottom: 40px;
  }

  h3 {
    @include text-l-semibold();
    text-transform: none;
  }

  .columns-wrapper{
    // We need to define new gutters width since we define new grid of 3 so we need to combined all gutters
    // that would exist in the 16 col version. Thats the reason that at the end we add 3 time the size of gutter width.
    @include grid(3, calc(calc-col(1,var(--column-list),var(--gutter-width)) + (var(--gutter-width)*3)));

    .column-item{
      @include col(1);
    }
  }

  .column-item {
    position: relative;
    @include text-m();
    padding: 20px 0;
    margin-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 31px;
      height: 1px;
      background: map-get($colors-infos-column, border);
    }

    .column-title{
      margin-bottom: -2px;
    }

    .column-description{
      p{
        line-height: 1.5rem;
        margin: 0;
      }
    }
  }

  .wrapper-actions{
    display: flex;
    justify-content: center;
  }

  .desktop-only{
    display: block;
  }

  .mobile-only{
    display: none;
  }

  .hide{
    display: none;
  }

  @media only screen and (max-width: $sm-max) {
    --column-list: var(--column-amount);

    .small-grid{
      --column-list: var(--column-amount);
      @include col-start(1);
    }

    .columns-wrapper {
      // We need to define new gutters width since we define new grid of 3 so we need to combined all gutters
      // that would exist in the 16 col version. Thats the reason that at the end we add 3 time the size of gutter width.
      @include grid(3, calc(var(--gutter-width) * 4));
    }
  }

  @media only screen and (max-width: $xs-max) {
    .columns-wrapper {
      @include grid(var(--column-amount), var(--gutter-width));
      margin-bottom: 18px;

      .column-item{
        @include col(var(--column-amount));
      }
    }

    h2{
      margin-bottom: 30px;
    }

    .column-item{
      margin-bottom: 0;

      .column-title{
        margin-bottom: 0;
      }
      .column-description{
        p{
          line-height: 1.4rem;
        }
      }
    }

    .desktop-only{
      display: none;
    }

    .mobile-only{
      display: flex;
    }
  }
}

