@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.aliment-qc{
    background: map-get($colors-base, colored-bg-dark);
    color:  map-get($colors-base, light-text);
    padding: 50px 0;

    &.full-width{
        .wrapper{
            @include col(14);
            @include col-start(2);
        }
    }

    &.in-overlay{
        .centered-content{
            display:flex;

            .wrapper{
                background-color: transparent;
                box-shadow: none;
                color:  map-get($colors-base, light-text);
                align-items: flex-start;
                gap: 38px;
                @include col(16);
                @include col-start(1);

                img{
                    width: 130px;
                    min-width: 130px;
                }

                p{
                    line-height: rem(21.6px);
                }
            }
        }
    }

    .centered-content{

        .wrapper{
            display:flex;
            align-items: center;
            gap: 50px;

            .content{
                padding:0;
                >*:first-child{
                    margin-top:0;
                }
                >*:last-child{
                    margin-bottom:0;
                }
            }

            p{
                max-width: 500px;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content{

            .wrapper{
                gap: 20px;

                img{
                    width: 145px;
                    min-width: 145px;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        &.full-width{
            .wrapper{
                @include col(16);
                @include col-start(1);

                p{
                    font-size: rem(14px);
                    line-height: rem(16px);
                }
            }
        }
    }
}
