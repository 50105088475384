@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.section[class^=pagebuilder-component]{
  &.pagebuilder-component-fullscreen-image{
    margin: 0;
  }
}

.pagebuilder-component-fullscreen-image{
  overflow: hidden;
  width: 100%;
  max-height: 885px;
  aspect-ratio: 1440/857;
  margin: 0;

  .rellax{
    object-fit: cover;
    height: 125%;
    width: 100%;
  }
}

