@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.tile {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  pointer-events: auto;
  cursor: pointer;
  clip-path: inset(0 0 round 10px);
  display: flex;

  &:hover,
  &:active {
    text-decoration: none;

    .tile-inner {
      img {
        transform: scale(1.1);
      }

      svg {
        opacity: 1;
        transform: translateX(-10px);
      }
    }
  }

  &.tile-hover {
    .tile-inner {
      img {
        transform: none;
        object-position: top center;
      }

      .image-hover-desktop {
        opacity: 0;
      }
    }

    &:hover,
    &:active {
      img {
        opacity: 0;
      }
      .image-hover-desktop {
        opacity: 1;
      }
    }
  }

  &.product-tile {
    .tile-inner {
      padding: 0;
      min-height: 445px;

      .images-wrapper {
        position: relative;
        overflow: hidden;
        min-height: 200px;
        flex: 1 1 auto;

        img {
          object-position: center;
          background-color: map-get($color-product, image-backgroud);
        }
      }

      .texts {
        padding: 27px 28px 26px;

        .title {
          font-size: rem(26);
          line-height: rem(31.2);
          text-transform: none;
        }

        .description {
          font-size: rem(18);
          line-height: rem(30.6);
        }
      }
    }
  }

  .tile-inner {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding: 70px 24px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    border-radius: 10px;
    overflow: hidden;

    //prevent selecting element while draging tiles for swipe event
    * {
      pointer-events: none;
    }

    .tag {
      position: absolute;
      z-index: 2;
      top: 20px;
      left: 20px;
      line-height: 1;
      color: map-get($colors-base, light-text);
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top right;
      opacity: 1;
      background-color: map-get($colors-base, dark-text);
      transition: all 0.7s ease;

      &.image-hover-mobile {
        display: none;
      }
    }

    .title {
      text-transform: uppercase;
      @include shentox-bold();
      font-size: rem(40);
      line-height: rem(42);
      color: map-get($colors-base, light-text);

      position: relative;
      z-index: 2;
      margin: 0 0 10px;
    }

    .description {
      @include text-m-menu();
      margin: 0;
      position: relative;
      z-index: 2;
      color: map-get($colors-base, light-text);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      overflow: hidden;
    }

    svg {
      position: absolute;
      top: 20px;
      right: 10px;
      width: 45px;
      height: 45px;
      opacity: 0;
      transform: translateX(-20px);
      transition: transform 0.2s ease, opacity 0.2s ease;
    }
  }

  @media only screen and (max-width: $xs-max) {
    min-height: 160px;

    &.list-type {
      justify-content: center;

      &:after {
        content: " ";
        width: 80%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.5) 60%
        ) !important;
      }

      .tile-inner {
        display: block;
        padding: 0;
        min-height: 160px;

        //fix for safari
        height: 1px;

        .tag {
          top: 10px;
          left: 10px;
          padding: 5px 10px;
        }

        .texts {
          height: 100%;
          box-sizing: border-box;
          padding: 15px 15px 15px 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        svg {
          display: none;
          z-index: 3;
        }

        .description {
          position: relative;
          z-index: 3;
          @include text-xs();
        }

        .title {
          position: relative;
          z-index: 3;
          font-size: rem(20);
          line-height: rem(22);
          @include shentox-bold();

          margin: 0 0 10px;
        }

        .image-hover-desktop {
          display: none;
        }

        .image-hover-mobile {
          display: block;
          //height: auto;
          object-position: center left;
        }
      }
    }

    .tile-inner {
      min-height: 0;
      height: 100%;

      svg {
        top: 10px;
        opacity: 1;
        transform: scale(0.6);
      }
    }
  }
}
