@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.check-balance-form{
    padding: 0;
    margin: 200px 0;

    .wrapper{
        @include grid(14, var(--gutter-width));
        @include col(14);
        @include col-start(2);

        .content{
            @include col(5);
            @include col-start(1);
        }

        form{
            @include col(8);
            @include col-start(7);

            justify-content: flex-start;

            [type=submit]{
                margin-left:0;
            }

            .input-wrapper{
                margin-bottom: 30px;
            }

            .amount{
                @include text-xl-semibold();

                .value{
                    color: map-get($colors-giftcard-check-balance, balance);
                }
            }
        }
    }
    
    @media only screen and (max-width: 1300px) {
        .wrapper {

            .content {
                @include col(6);
                @include col-start(1);
            }

            form {
                @include col(7);
                @include col-start(8);
            }
        }
    }

    @media only screen and (max-width: $sm-max) {

        .wrapper {
            @include grid(16, var(--gutter-width));
            @include col(16);
            @include col-start(1);

            .content {
                @include col(8);
                @include col-start(1);
            }

            form {
                @include col(7);
                @include col-start(10);
            }
        }
    }
    @media only screen and (max-width: $sm-max) {
        margin: 160px 0 80px;
    }

    @media only screen and (max-width: $xs-max) {
        .wrapper {


            .content {
                @include col(16);
                @include col-start(1);
                max-width: 420px;
            }

            form {
                @include col(16);
                @include col-start(1);
            }
        }
    }
}
