@import "../../config";
@import "../../mixins";
@import "../../functions";

.overlay.menu-navigation-overlay{
    right: auto;
    left: -600px;
    transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;

    &.active {
        left: 0;

        .backdrop{
            opacity: 0.75;
        }
    }

    &.title-is-sticky {
        .sticky-title{
            left:0;
        }
        .wrapper {
            .close {
                right: auto;
            }
        }
    }

    .backdrop{
        background: $white;
    }
    .wrapper{
        float:left;
        width: 600px;
        box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.1);


        h3{
            @include text-xl-semibold();
            text-transform: none;
            padding: 25px 40px 25px 80px;
            margin:0;
            border-bottom: 1px solid map-get($colors-overlay, border);
        }

        .close{
            left: 20px;
            right: auto;
        }

        .menu-type-list{
            padding: 0 30px;

            .menu-item{
                gap: 20px;
                align-items: flex-start;

                h4{
                    padding-left: 40px;
                    margin:0;
                }

                .icon-arrow{
                    position: relative;
                    right: -5px;
                    flex: 0 0 auto;
                }

                &:first-child{
                    &:before{
                        display:none;
                    }

                    h4{
                        padding-left: 0px;
                    }
                }
            }
        }
    }

    .sticky-title{
        width: 600px;
        left: -100vw;
        right: auto;
        padding: 25px 40px 25px 80px;
    }

    svg{
        *{
            stroke: map-get($colors-base, dark-text);
        }
    }


    @media only screen and (max-width: $sm-max) {
        .wrapper{
            width: 600px;
            .sticky-title {
                width: 600px;
            }
            .menu-type-list{
                padding: 0 30px;

                .menu-item{
                    padding-top: 25px;
                    padding-bottom: 25px;

                    .icon-arrow{
                        width: 35px;
                    }

                    h4{
                        padding-left: 30px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        left: -100vw;

        .wrapper{
            width: 100%;

            h3{
                padding: 20px 70px 20px 20px
            }

            .close{
                left: auto;
                right: 10px;
                top: 10px;
            }

            .sticky-title {
                width: 100%;
            }

            .menu-type-list{
                padding: 0 20px;
                .menu-item{
                    .icon-arrow{
                        width: 24px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
