@import "../../../../config";
@import "../../../../mixins";
@import "../../../../functions";

.hero.no-image{
    position: relative;

    &.compact{
        padding-bottom: 100px;
        .centered-content{
            padding-bottom:0;
            h1 {
                @include col(12);
                @include col-start(2);
            }
        }

        .content-after{
            p{
                margin-top:0;
            }
        }
    }

    &.loose{
        padding-bottom: 0;
        .centered-content{
            padding-top: 250px;
            padding-bottom: 0;
            h1 {
                @include col(15);
                @include col-start(2);
                @media only screen and (max-width: $sm-max){
                    @include col(var(--column-amount));
                    @include col-start(1);
                }
            }
        }
        @media only screen and (max-width: $xs-max){
            &:last-child{
                padding-bottom: 50px;
            }
            .centered-content{
                padding-top: 100px;
            }

        }

        .content-after{
            p{
                margin-top:0;
            }
        }
    }

    &.blog-hero{
        .centered-content{
            h1{
                padding-bottom: 35px;
            }
        }
    }

    .centered-content{
        padding-top: 200px;
        padding-bottom: 50px;

        h1 {
            @include col(8);
            @include col-start(2);
            margin:0;
        }

        h5, p, div{
            @include col(8);
            @include col-start(2);

            &.suptitle{
                margin-bottom: 15px;
            }

            &.subtitle{
                margin-top: 15px;

                p{
                    margin-top:0;
                }
            }
        }
    }

    .content-after{
        position: relative;
        width: $container-lg;
        margin: 0 auto;
        //opacity:0;
        //top: 30px;

        @include grid(var(--column-amount), var(--gutter-width));

        //padding-top: 100px;
        padding-top: 40px;

        div{
            @include col(7);
            @include col-start(2);
        }
    }

    .section.dark, .section.light{
        position: absolute;
        top: 0%;
        height: 100%;
        width: 100%;
        width: 100%;
        opacity:0;
        pointer-events: none;
    }


    @media only screen and (max-width: $sm-max) {
        &.compact{
            .centered-content{
                h1 {
                    @include col-start(1);
                }
            }
        }
        .centered-content {
            h1, h5, p {
                @include col-start(1);
            }
        }

        .content-after {
            width: $container-sm;

            div{
                @include col-start(1);
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            padding-top: 160px;
        }

        .centered-content{
            padding-top: 120px;
            padding-bottom: 32px;

            h1 {
                @include col(16);
                @include col-start(1);
                margin:0;
            }

            h5, p, div {
                @include col(16);
                @include col-start(1);
            }
        }

        .content-after{
            width: $container-sm;
            padding-top: 30px;

            div{
                @include col(16);
            }
        }
    }
}
