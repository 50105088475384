@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
  &-sports-schedule {

    > img {
      aspect-ratio: 1440/686;
      object-fit: cover;
      width: 100%;

      @media only screen and (max-width: $xs-max) {
        aspect-ratio: 375/345;
      }

    }

    .centered-content {
      .sports-selector {
        @include col(14);
        margin-top: 0;
      }

      .text-wrapper {
        @include col-start(2);
        @include col(9);

        h2 {
          margin-bottom: 0;
          text-wrap: balance;
        }
      }

      @media only screen and (max-width: $sm-max) {
        .sports-selector {
          @include col(var(--column-amount))

        }
        .text-wrapper {
          @include col-start(1);
          @include col(var(--column-amount));

          h2 {
            text-wrap: balance;
          }
        }

        .results {
          .sport {
            .formatted-date {
              display: block;
            }
          }
        }
      }
    }


  }
}