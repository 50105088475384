@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.menu-type-list {
  margin-bottom: 80px;

  .title-ctn {
    margin-top: 160px;
    @include col-start(2);
    @include col(10);
    .like-h4 {
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      gap: 10px 25px;
    }
  }

  .menu-list-wrapper {
    @include col-start(2);
    @include col(14);

    .menu-item {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-block: 40px 36px;
      transition: all ease 250ms;
      clip-path: inset(0 -100vmax);

      .like-h2 {
        padding-right: 20px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        background: map-get($colors-restaurant-menu, border-light);
        height: 1px;
      }

      &:hover {
        .icon-arrow {
          svg {
            transition: all ease 250ms;
            margin-left: 10px;
          }
        }
      }

      &:last-child {
        margin-bottom: 2px;
      }

      .like-h2 {
        margin-bottom: 0;
      }

      .icon-arrow {
        width: 46px;
        svg {
          transition: all ease 250ms;
          margin-left: -10px;
          path {
            stroke: map-get($colors-base, text);
          }
        }
      }

      a {
        position: absolute;
        z-index: 1;
        inset: 0;
      }
    }
  }

  .menu-info-links {
    @include col-start(2);
    @include col(14);
    display: flex;
    gap: 30px;
    margin-top: 30px;
  }

  @media only screen and (max-width: $sm-max) {
    margin-bottom: 60px;

    .title-ctn {
      margin-top: 130px;
      @include col-start(1);
      @include col(16);
    }
  }
  @media only screen and (max-width: $xs-max) {
    .title-ctn {
      margin-top: 120px;
    }

    .menu-list-wrapper {
      @include col-start(1);
      @include col(16);
      margin-right: unset;
    }

    .menu-info-links {
      @include col-start(1);
      @include col(16);
      gap: 20px;
    }
  }

  @media only screen and (max-width: 374px) {
    .menu-info-links {
      width: min-content;
    }
  }
}
