@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.light{
    [class*="pagebuilder-component"]{
        &.bg-gray{
            margin: 0 0;
            padding: 150px 0;

            &+.bg-gray{
                padding: 0 0 150px 0;
            }

            &:last-of-type:not(:last-child){
                margin-bottom: 150px;
            }
        }

        @media only screen and (max-width: $sm-max) {
            margin: 100px 0;

            &.bg-gray{
                margin: 0 0;
                padding: 100px 0;

                &+.bg-gray{
                    padding: 0 0 100px 0;
                }

                &:last-of-type:not(:last-child){
                    margin-bottom: 100px;
                }
            }
        }
    }
}

[class*="pagebuilder-component"]{
    margin: 200px 0;

    &.bg-red{
        background: map-get($colors-base, colored-bg);
    }

    &.bg-red{
        margin: 0 0;
        padding: 200px 0;

        &+.bg-red{
            padding: 0 0 200px 0;
        }

        &:last-of-type:not(:last-child){
            margin-bottom: 200px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin: 100px 0;

        &.bg-red{
            margin: 0 0;
            padding: 100px 0;

            &+.bg-red{
                padding: 0 0 100px 0;
            }

            &:last-of-type:not(:last-child){
                margin-bottom: 100px;
            }
        }
    }
}
