@import "../../config";
@import "../../mixins";
@import "../../functions";

.overlay.promo{

    &.in-menu{
        .wrapper {
            .img-wrapper {
                overflow: hidden;
                position: relative;
                left: 0;
                max-height: none;
                aspect-ratio: auto;
                min-width: 100%;
            }
        }
    }
    .wrapper{
        .img-wrapper{
            overflow: hidden;
            position: relative;
            left:0;
            max-height: 450px;
            aspect-ratio: 500/290;
            min-width: 100%;

            img{
                width: 100%;
                height: 100%;
                object-position: 50% 25%;
                object-fit: cover;
            }
        }

        .tag{
            color: map-get($colors-base, light-text);
            position: absolute;
            z-index:2;
            top: 10px;
            left: 10px;
            line-height: 1;
        }

        .content{
            .availability{
                @include text-m-semibold();

                span{
                    display:inline-block;

                    &:first-child{
                        &::first-letter {
                            text-transform: uppercase
                        }
                    }
                }
            }

            .exclusivity-title{
                @include text-l-semibold();
                margin:40px 0 5px;
            }

            .exclusivity-description{
                @include text-s();
                margin:0 0 20px;
            }

            .legal{
                @include text-xs();
                margin-top:40px;
                padding: 40px 0 20px;
                border-top: 1px solid map-get($colors-overlay, border);

                p{
                    margin-top:0;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        &.in-menu{
            .wrapper {
                .img-wrapper {
                    height: auto;
                }
            }
        }

        .wrapper {
            .img-wrapper{
                height: 300px;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .wrapper {
            .tag{
                top: 20px;
                left: 20px;
            }

            .content{
                .legal{
                    margin-top: 30px;
                    padding-top: 30px;
                }
            }
        }
    }
}
