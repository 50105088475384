@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.legal-content{
    .wysiwyg-wrapper{
        @include grid(16, var(--gutter-width));

        .title-separator{
            margin-bottom: 50px;
        }

        h3{
            @include col(5);
            @include col-start(2);
            font-size: rem(34);
            line-height: rem(37.4);
        }

        .wysiwyg-content{
            @include col(9);
            @include col-start(7);
            margin-bottom: 100px;

            >*:first-child{
                margin-top:0;
            }

            >*:last-child{
                margin-bottom:0;
            }

            figure.table{
                overflow-x: auto;
            }

            table{
                width: 100%;
            }

            table, th, td {
                border: 1px solid map-get($color-tables, border);
                border-collapse: collapse;
            }

            //if we need to reproduce the design presented
            //th{
            //    display:none;
            //
            //    &:first-child{
            //        display:block;
            //    }
            //}
            //
            //tr {
            //    display: flex;
            //    flex-direction: column;
            //}

            th{
                vertical-align: top;
                padding: 20px;
                background:  map-get($color-tables, heading);
                color:  map-get($color-tables, heading-text);
                @include text-m-semibold();
            }

            td{
                vertical-align: top;
                padding: 20px;
                @include text-m();

                strong{
                    @include text-m-semibold();
                }

                &:first-child {
                    min-width: 160px;
                }
            }

            h2,h3,h4,h5,h6{
                margin-top: 60px;
            }

            ul, ol{
                list-style: none; /* Remove default bullets */
                margin-left: 20px;

                li::before {
                    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                    color: map-get($colors-legal, links);
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }

            figure{
                margin:0 0 60px;
            }

            a{
                color: map-get($colors-legal, links);
                text-decoration: underline;
                @include text-m-semibold();

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .wysiwyg-wrapper{

            .title-separator{
                margin-bottom: 40px;
            }

            h3{
                @include col(16);
                @include col-start(1);
            }

            .wysiwyg-content {
                @include col(16);
                @include col-start(1);
                margin-bottom: 60px;

                ul, ol{
                    margin-left: 15px;
                }

                td:first-child {
                    min-width: auto;
                }
            }
        }
    }
}

