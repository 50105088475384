@import "../../../../config";
@import "../../../../mixins";
@import "../../../../functions";

.hero.homepage{
    position: relative;

    .scroll-height{
        position: absolute;
        pointer-events: none;
        opacity:0.001;
        height: 150vh;
        width: 100%;
    }

    .static-ctn{
        position: fixed;
        width: 100%;

        &.scroll{
            position: absolute;

            + .centered-content{
                pointer-events: auto;
            }
        }

        .static-content{
            position: absolute;
            display:flex;
            flex-direction: column;
            justify-content: center;
            top: 50vh;
            transform: translateY(-50%);
            z-index:3;
            width: 100%;
            pointer-events: none;

            a{
                pointer-events: auto;
                position: relative;
                margin:0 auto;
            }
        }
        h1{
            pointer-events: none;
            width: 100%;
            padding: 0 5vw;
            box-sizing: border-box;

            span{
                display:block;
                text-align: right;
            }

            div{
                clip-path: inset(0px 0px 0px 0px);
            }
        }
        .main-image{
            height: 100vh;
            width: 100%;
            position: relative;
            overflow: hidden;

            img, video{
                aspect-ratio: 1440/720;
                min-height: 100%;
                min-width: 100vw;
                width: 100%;
                object-fit: cover;

                &.mobile-video{
                    display:none;
                }

            }

            &:before{
                content: ' ';
                pointer-events: none;
                position: absolute;
                z-index:2;
                top:0;
                width: 100vw;
                height: 100vh;
                box-shadow: 0px 250px 250px -100px rgb(0 0 0 / 35%) inset;
            }
            &:after{
                content: ' ';
                pointer-events: none;
                position: absolute;
                top:0;
                z-index:2;
                width: 100vw;
                height: 100vh;
                box-shadow: 0px -250px 250px 100px rgb(0 0 0 / 35%) inset;
            }
        }
    }

    .centered-content{
        position: relative;
        padding-top: 150vh;
        z-index:3;
        pointer-events: none;

        .wrapper{
            position: relative;
            @include col(10);
            @include col-start(2);
            @include grid(10, var(--gutter-width));

            .secondary-title{
                position: absolute;
                bottom: calc(100% + 80px);
                @include col(10);
                @include col-start(1);
                @include grid(10, var(--gutter-width));

                .subtitle{
                    @include col(7);
                    @include col-start(1);

                    clip-path: inset(0 0 100% 0);
                    top: rem(36);
                    margin:0;
                    padding-left:4px;
                }

                h2{
                    @include col(8);
                    @include col-start(1);
                    font-size: 4.3vw;
                    line-height: 4.3vw;
                    margin-bottom:0;

                    div{
                        clip-path: inset(0 0 100% 0);
                        top: rem(45);
                    }
                }
            }
        }

        .description{
            @include col(7);
            @include col-start(1);
            padding-top: 80px;
            margin-bottom: 150px;

            p{
                margin-top:0;
            }
        }

        img{
            position: absolute;
            top: -680px;
            right: 40px;
            width: calc(100vw / (1440/500) );
            aspect-ratio: 500/600;
            object-fit: cover;
            max-width: 570px;
        }

        .section.no-bg{
            position: absolute;
            top: 0%;
            height: 45%;
            width: 100%;
            opacity:0;
            pointer-events: none;
        }

        .section.dark{
            position: absolute;
            top: 60%;
            height: 40%;
            width: 100%;
            opacity:0;
            pointer-events: none;
        }
    }

    @media only screen and (min-width: 2000px) {

        .centered-content{

            .wrapper{

                .secondary-title{

                    h2{
                        font-size: 5vw;
                        line-height: 5vw;
                    }
                }
            }

            img{
                top: -900px;
                max-width: 820px;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {

        .centered-content{

            .subtitle{
                @include col(8);
                @include col-start(2);
            }

            h2{
                @include col(12);
                @include col-start(2);
            }

            .description{
                @include col(8);
                @include col-start(2);
            }

            img{
                right: 0px;
            }
        }
    }
    @media only screen and (max-width: $xs-max) {
        height: auto;
        min-height: 0;
        margin-bottom: 95px;

        .static-ctn{
            top: 0!important;
            position: relative;
            height: 100vh;
            min-height: 600px;
            overflow: hidden;
            display:flex;
            align-items: center;

            &.scroll{
                position: relative;
            }

            .static-content{
                position: relative;
                top: unset;
                transform: unset;
                width: 100%;
                pointer-events: none;
                h1{
                    font-size: 12.05vw;
                    line-height: 12.05vw;
                }
            }
            .main-image{
                position: absolute;
                top:0;
                height: 100vh;
                min-height: 600px;
                width: 100vw;

                video{
                    &.desktop-video{
                        display:none;
                    }

                    &.mobile-video{
                        display:block;
                    }
                }
            }
        }

        .centered-content{
            position: relative;
            left:0;
            transform: none;
            bottom: auto;
            padding-top: 60px;
            pointer-events: all;

            .wrapper{
                @include col(16);
                @include col-start(1);
                @include grid(16, var(--gutter-width));

                .secondary-title{
                    @include col(16);
                    @include col-start(1);
                    @include grid(16, var(--gutter-width));
                    position: relative;
                    bottom: auto;

                    .subtitle{
                        @include col(16);
                        @include col-start(1);

                        clip-path: inset(0 0 100% 0);
                        top: rem(15);
                        margin:0;
                        padding-left:1px;
                    }

                    h2{
                        @include col(16);
                        @include col-start(1);
                        font-size: rem(38);
                        line-height: 1;

                        div{
                            clip-path: inset(0 0 100% 0);
                            top: rem(10);
                        }
                    }
                }
            }

            .description{
                position: relative;
                top: 0;
                @include col(16);
                @include col-start(1);
                margin-bottom:0;
                padding-top: 30px;
            }

            img{
                display:none;
                position: absolute;
                bottom: calc(100% + 250px);
                top: auto;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 70px);
                max-width: 300px;
                aspect-ratio: 300/345;
            }

            .section.no-bg{
                top: -60%;
            }

            .section.dark{
                top: -15%;
            }
        }

    }
}
