@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

footer {
    position: fixed;
    bottom:0;
    width: 100%;
    //width: 100vw;
    z-index:1;
    background: map-get($colors-footer, background);
    padding-top: 200px;

    @media only screen and (max-width: $sm-max) {
        width: 100%;
    }

    &.onsite{
        display: none;
    }

    *{
        color: map-get($colors-base, light-text);
    }

    a{
        &::before {
            background-color: map-get($colors-base, light-text) !important;
        }

        &:hover, &:focus, &:active, &:visited {
            color: map-get($colors-base, light-text);

            &::before {
                background-color: map-get($colors-base, light-text) !important;
            }
        }
    }

    .centered-content{
        position: relative;
        z-index:2;
    }
    .col-wrapper{
        padding-top: 150px;
        padding-bottom: 145px;
        display: flex;
        @include col-start(1);
        @include col(16);
        @include grid(16, var(--gutter-width));

        >div{
            &:nth-child(1){
                @include col(3);
                @include col-start(1);
                position: relative;
                top:-10px;
            }
            &:nth-child(2){
                @include col(3);
                @include col-start(4);
            }
            &:nth-child(3){
                @include col(4);
                @include col-start(7);

                .socials{
                    display:flex;
                    gap:30px;
                    margin-top: 30px;

                    a{
                        width: 23px;
                    }
                }
            }
            &:nth-child(4){
                @include col(4);
                @include col-start(12);

                p{
                    line-height: rem(20);
                }

                ul{
                    display:flex;
                    gap: 10px;
                    margin: 12px 0 0;
                    float:left;

                    li{
                        width: 50%;
                    }
                }
            }

            ul{
                list-style: none;
                @include shentox-bold();
                text-transform: uppercase;
                font-size: rem(14);
                line-height: rem(16);

                li{
                    margin: 0 0 20px;
                    letter-spacing: 0.1em;
                }

                .store-buttons{
                    width: 100%;
                }
            }

            .logo-grandio-mobile {
                display: none;
            }
        }
    }

    .extras{
        @include text-xs();
        display: flex;
        list-style: none;
        gap: 10px;
        margin: 0 0 50px;
        padding: 25px 0 0;
        border-top: 1px solid map-get($colors-footer, border);

        .logo-grandio {
            @include property-calc-col(width, 3);
            margin-right: calc(var(--gutter-width) - 10px);
        }

        li:last-child{
            margin-left: auto
        }
    }

    @media only screen and (max-width: $md-max) {
        .col-wrapper{
            >div{
                &:nth-child(1){
                    @include col(3);
                    @include col-start(1);
                    position: relative;
                    top:-10px;
                }
                &:nth-child(2){
                    @include col(4);
                    @include col-start(4);
                }
                &:nth-child(3){
                    @include col(4);
                    @include col-start(8);
                }
                &:nth-child(4){
                    @include col(4);
                    @include col-start(13);

                    ul{
                        flex-direction: column;

                        li{
                            width: 100%;
                            margin:0;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: $md-min) and (max-width: $md-max){
        .col-wrapper{
            >div{
                ul{
                    .store-buttons{
                        width: 60%;
                        height: unset;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        position: relative;
        padding-top:0;

        &:before{
            content: ' ';
            position: absolute;
            top: -300px;
            background: map-get($colors-footer, background);
            width: 100%;
            height: 300px;
        }

        .col-wrapper{
            padding-top: 65px;
            padding-bottom: 0;
            @include col-start(1);
            @include col(var(--column-ammount));
            @include grid(var(--column-ammount), var(--gutter-width));
            flex-direction: column;

            >div{
                &:nth-child(1){
                    @include col-start(1);
                    @include col(var(--column-ammount));
                    position: relative;
                    top:0px;
                    height:90px;

                    svg{
                        width: 90px;
                        height: 90px;
                    }
                }
                &:nth-child(2){
                    @include col-start(1);
                    @include col(var(--column-ammount));
                }
                &:nth-child(3){
                    @include col-start(1);
                    @include col(var(--column-ammount));

                    .socials{
                        gap:20px;
                        margin: 30px 0 35px;
                    }
                }
                &:nth-child(4){
                    @include col-start(1);
                    @include col(var(--column-ammount));
                    border-top:1px solid map-get($colors-base, light-text);
                    padding-top: 10px;

                    ul{
                        gap: 10px;
                        margin: 30px 0 0;

                        li{
                            max-width: 145px;
                        }
                    }
                }

                h4{
                    margin:30px 0 0px;
                }

                ul{
                    margin:10px 0 0;

                    li{
                        margin: 0 0 10px;

                        &:last-child{
                            margin:0;
                        }
                    }
                }

                .logo-grandio-mobile {
                    display: block;
                    margin-bottom: 15px;
                }
            }
        }

        .extras{
            border-top: none;
            margin-top:0;
            flex-wrap: wrap;

            .logo-grandio {
                display: none;
            }

            li:last-child{
                margin-left: 0;
            }
        }
    }
}
