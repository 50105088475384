@import "../../config";
@import "../../mixins";
@import "../../functions";

.menu-navigation{

    .return-cta{
        &:hover{
            svg{
                path{
                    stroke: map-get($colors-ui-buttons, primary-hover-bg);
                    transition: stroke ease 250ms;
                }
            }
        }

        button, a{
            position: relative;
            left: 30px;

            &::before,
            &::after {
                display: none;
            }
        }
        .icon-burger-mobile{
            position: absolute;
            top: -1px;
            left:-30px;
            path{
                stroke: map-get($colors-ui-buttons, primary-bg);
            }
        }
    }

    @media only screen and (max-width: $md-max){
    }
    @media only screen and (max-width: $sm-max){
    }
}
