@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.grocery-single {
    .description{
        p{
            margin:0;
            @include text-xl();
        }

        h5{
            margin-top: 40px;
            @include text-xl-semibold();
            text-transform: none;
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: $sm-max){
        .description{
            p{
                margin:0;
                @include text-l();
            }

            h5{
                margin-top: 30px;
                @include text-l-semibold();
            }
        }
    }
}
