@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;


.blog-single{
  .go-back-section{
    @include col-start(2);
    @include col(14);
  }
}