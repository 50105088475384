@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.giftcard-tiles {
    margin: 80px 0;

    .wrapper{
        @include grid(16, var(--gutter-width));
    }

    .image-content{
        @include col-start(2);
        @include col(7);

        img{
            border-radius: 10px;
            width: 100%;

            aspect-ratio: 580/386;
            object-fit: cover;
        }
    }

    .text-wrapper {
        align-self: center;
        @include col-start(10);
        @include col(6);
        @include grid(var(--column-list), var(--gutter-width));

        .text-content {

            p {
                max-width: 500px;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .image-content{
            @include col-start(1);
            @include col(7);
        }

        .text-wrapper {
            align-self: center;
            @include col-start(9);
            @include col(8);
        }
    }

    @media only screen and (max-width: $xs-max) {
        .image-content{
            @include col-start(1);
            @include col(16);
        }

        .text-wrapper {
            margin-top: 10px;
            align-self: center;
            @include col-start(1);
            @include col(16);
        }

        .primary-cta{
            margin-top: 5px;
            width: 100%;
            box-sizing: border-box;
        }
    }
}
