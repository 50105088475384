@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.grocery-scrolling-list {
    margin: 150px 0;
    overflow: hidden;

    &.homepage {
        margin-top: 0;
    }

    .wrapper {
        @include col(14);
        @include col-start(2);

        .grocery-category {
            margin-bottom: 120px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        .scrolling-list {
            margin-top: 40px;
            position: relative;

            .list {
                width: max-content;
                display: flex;
                align-items: stretch;
                gap: 45px;

                a {
                    &.tile {
                        color: map-get($colors-base, text);
                    }
                }

                .tile {
                    width: var(--tileSize);
                    min-width: 300px;
                    max-width: 500px;
                    float: left;
                    border: 1px solid map-get($colors-scrolling-container, border);
                    aspect-ratio: 1/1.02;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(
                            0deg,
                            rgb(0 0 0 / 50%) 0%,
                            rgba(0, 0, 0, 0) 50%
                        );
                    }

                    // @media only screen and (min-width: $sm-min) {
                    //     &::before {
                    //         padding-bottom: 102%; /* Minimum aspect ratio */
                    //         content: '';
                    //         float: left;
                    //     }
                    //     &::after {
                    //         display: table;
                    //         clear: both;
                    //     }
                    // }
                }
            }

            .controls {
                margin-top: 75px;
                width: 100%;
                display: inline-flex;
                gap: 10px;
                justify-content: flex-end;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin: 100px 0;

        .centered-content {
            width: 100%;
        }

        .wrapper {
            @include col(16);
            @include col-start(1);

            .grocery-category {
                margin-bottom: 100px;

                .title-separator,
                .like-h3 {
                    margin-left: auto;
                    margin-right: auto;
                    width: calc(100% - 8px * 4);
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }

            .scrolling-list {
                margin-top: 40px;
                padding-bottom: 8px;
                overflow-x: scroll;
                scroll-snap-type: x mandatory;

                .list {
                    gap: 10px;
                    padding-left: 16px;
                    padding-right: 16px;
                    touch-action: auto !important;
                    .tile {
                        scroll-snap-align: center;

                        // Padding hack pour que ça s'affiche bien aussi sur Safari (au lieu de aspect-ratio)
                        &::before {
                            float: left;
                            padding-top: 100%; // Aspect ratio 1:1
                            content: '';
                        }

                        &::after {
                            display: block;
                            content: '';
                            clear: both;
                        }
                    }
                }

                .controls {
                    display: none;
                }
            }
        }
    }
}
