@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component-gallery{
    overflow: visible!important;

    .controls{
        position: absolute;
        left: calc(100% * 1145/1360);
        height: 100%;
        width: 160px;

        .slides-count{
            @include text-m-semibold();
            position: absolute;
            width: 100%;
            top: calc(100% * 50/744);
        }

        .buttons{
            position: absolute;
            top: calc(100% *540/744);

            button{
                margin-right: 10px;
            }
        }
    }

    .icon-play{
        pointer-events: none;
        position: absolute;
        z-index:3;
        opacity: 0;
        transform: scale(0.7);
        transition: opacity 0.25s ease, transform 0.3s ease;

        top: 50%;
        left: 50%;
        translate: -50% -50%;

        &.active{
            transform: scale(1);
            opacity: 1;
        }

        @media only screen and (max-width: $xs-max){
            transition: opacity 0.4s ease, transform 0.2s ease;
            translate: -50% -50%;
            scale: 0.9;

            &.active{
                transition: opacity 0.2s ease, transform 0.2s ease;
            }
        }
    }

    .images-ctn-wrapper{
        &.small{
            position: absolute;
            z-index:2;
            //sizes are calculated based on the design, the images are a ratio based on their size versus the container size
            top: calc(100%* 115/744);
            right:0;
            aspect-ratio: 420/380;
            width: calc(100% * 420/1360);
        }
    }

    .images-ctn{
        position: relative;
        overflow: hidden;

        &.large{
            //sizes are calculated based on the design, the images are a ratio based on their size versus the container size
            aspect-ratio: 1054/744;
            width: calc(100% * 1054/1360);
            border-radius: 0 0 50px 0;
        }

        &.small{
            position: absolute;
            z-index:2;
            top:0;
            right:0;
            aspect-ratio: 420/380;
            width: 100%;
        }

        &.single{
            width: 100%;
            margin-bottom:0;
        }

        img{
            position: absolute;
            top:0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            animation: hide 0.3s cubic-bezier(0.005, 0.765, 0.505, 1.000) both;

            &.active{
                animation: show 0.25s 0.35s cubic-bezier(0.605, 0.040, 0.650, 1.000) both;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {

        .centered-content{
            flex-direction: column;
            margin-right:0;
        }

        .controls {
            order:2;
            position: relative;
            display:flex;
            justify-content: flex-end;
            align-items: center;
            left:-10px;
            justify-self: flex-end;
            width: 100%;
            margin-right: var(--gutter-width);

            .slides-count {
                position: relative;
                width: auto;
                top: 0;
                flex-grow:1;
                text-align: center;
            }

            .buttons {
                position: relative;
                top: 0;
                width: auto;
                button{
                    width: 56px;
                    height: 56px;
                    top: calc(100% * 100/744);
                }
            }
        }

        .images-ctn{
            position: relative;
            border-radius: 0 0 0 50px;
            margin-bottom:30px;

            &.large{
                aspect-ratio: 1054/744;
                width: 100%;
                border-radius: 0 0 0 50px;
            }

            &.small{
                display:none;
            }

            img{
                position: absolute;
                top:0;
                object-fit: cover;
                min-width: 100%;
                min-height: 100%;
                animation: hide 0.3s cubic-bezier(0.005, 0.765, 0.505, 1.000) both;

                &.active{
                    animation: show 0.25s 0.35s cubic-bezier(0.605, 0.040, 0.650, 1.000) both;
                }
            }
        }
    }
}

@keyframes show {
    0%{
        clip-path: inset(0 100% 0 0);
    }
    100%{
        clip-path: inset(0 0 0 0);
    }
}
@keyframes hide {
    0%{
        clip-path: inset(0 0 0 0);
    }
    100%{
        clip-path: inset(0 0 0 100%);
    }
}
