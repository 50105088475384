@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.menu-pdf{

    .centered-content{
        .accordeons-title{
            margin-top:20px;
        }

    }

    .links{
        margin-top: 30px;
        @include col(8);
        @include col-start(2);

        a{
            display:inline-block;
            margin-right: 20px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        .links {
            margin-top:0;
            @include col(12);
            @include col-start(1);

            a{
                margin-bottom: 15px;
            }
        }
    }
}
