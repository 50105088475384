@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-text-image {
        --column-list: var(--column-amount);
        overflow: hidden;
        overflow: hidden;

        .title-separator {
            margin-bottom: 70px;
        }

        .text-wrapper {
            @include col-start(1);
            @include col(16);
            @include grid(var(--column-list), var(--gutter-width));

            .text-content {
                @include col-start(2);

                p {
                    max-width: 60%;
                }
            }
        }

        .image-content {
            position: relative;
            display: flex;
            align-items: center;
            z-index: -1;
            max-height: 800px;
            overflow: hidden;

            &:before{
                content: '';
                z-index:1;
                position: absolute;
                inset: 0;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
            }

            img {
                width: 100%;
                aspect-ratio: 865/680;
                object-fit: cover;
            }
        }

        &.align-left {
            .text-wrapper, .image-content {
                grid-row: 1 / 2;
            }

            .text-wrapper {
                margin-top: 65px;

                .text-content {
                    @include col-start(10);
                    @include col(7);
                }
            }

            .image-content {
                @include col-start(1);
                @include col(9);
                left: calc(-1 * var(--gutter-width) * 2);
                border-bottom-right-radius: 70px;
            }
        }

        &.align-right {
            .text-wrapper, .image-content {
                grid-row: 1 / 2;
            }

            .text-wrapper {
                margin-top: 65px;

                &:first-child {
                    margin-top: 161px;
                }

                .text-content {
                    @include col(5);

                    p {
                        max-width: none;
                    }
                }
            }

            .image-content {
                @include col-start(7);
                @include col(10);
                left: calc(var(--gutter-width) * 2);
                border-bottom-left-radius: 70px;

            }
        }

        @media only screen and (max-width: $sm-max) {
            .title-separator {
                margin-bottom: 40px;
            }
            .text-wrapper {
                margin-bottom: unset;

                .text-content {
                    p {
                        max-width: unset;
                    }
                }
            }
            .image-content{
                &:before{
                    content: '';
                    position: absolute;
                    inset: 0;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 35%, rgba(0, 0, 0, 0) 100%);
                }
            }
            &.align-left {
                .text-wrapper, .image-content {
                    grid-row: unset;
                }

                .text-wrapper {
                    margin-top: -85px !important;
                    order: 2;

                    .text-content {
                        @include col-start(2);
                        @include col(13);
                    }
                }
                .image-content {
                    left: calc(-1 * var(--gutter-width) * 2);
                    @include col-start(1);
                    @include col(15);
                }
            }
            &.align-right {
                .text-wrapper, .image-content {
                    grid-row: unset;
                }

                .text-wrapper {
                    margin-top: -85px !important;
                    order: 2;

                    .text-content {
                        @include col(14);
                    }
                }

                .image-content {
                    @include col-start(2);
                    @include col(15);
                }
            }
        }
    }
}
