@use "../../../../config" as *;
@use "../../../../mixins" as *;
@use "../../../../functions" as *;

.category.radio {
  cursor: pointer;
  padding: 0 !important;

  .custom-tag-radio {
    cursor: inherit;
    padding: 8px 0;

    input[type=radio] {
      cursor: inherit;
      //display: none;
      width: 1px;
      height: 1px;
      //visibility: hidden;
      //z-index: -2;
      pointer-events: none;
      opacity: 0;
      position: absolute;
    }

    .tag {
      @include text-s-semibold();
      background-color: unset;
      border: 1px solid transparent;
      transition: all $defaultTransitionSpeed ease-in-out;
      display: inline-block;
      padding: 10px 20px 9px 20px;

      white-space: nowrap;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    &:not(.disabled) {
      .tag {
        &:hover {
          text-decoration: none;
          color: map-get($colors-ui-buttons, secondary-hover-color);
          background-color: map-get($colors-ui-buttons, secondary-hover-bg);
          border: 1px solid transparent;
        }
      }
    }

    &.disabled {
      color: map-get($colors-ui-buttons, arrows-disabled);

      &:hover {
        cursor: default;
      }
    }
  }

  &:has(input[type="radio"]:checked) {
    .custom-tag-radio {
      &:not(.disabled){
        .tag {
          border: 1px solid transparent;
          background-color: map-get($colors-ui, tag-background);

          &:hover {
            color: map-get($colors-ui-buttons, primary-color);
          }
        }
      }


      &.disabled{
        .tag{
          background-color: unset;
          &:hover{
            background-color: inherit;
          }
        }
      }
    }
  }
}

