// @font-face definitions

@font-face {
    font-family: "Ciutadella-SemiBold";
    src: url("../fonts/Ciutadella-SemiBold.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Ciutadella-Regular";
    src: url("../fonts/Ciutadella-Regular.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Shentox-Bold";
    src: url("../fonts/shentox-bold.woff2") format("woff2");
    font-display: swap;
}
